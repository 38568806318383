import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
const middleware : any = [logger];

import counterReducer from 'store/reducers';
import { userLoginReducer } from 'store/reducers/UserReducer';
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    userLogin: userLoginReducer,
  },
  middleware,
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
