import { Button, Grid, Popover, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { To, useNavigate } from 'react-router-dom';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { getAllListData } from 'services/CommonServices';
import Loader from 'components/Loader';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import _ from 'lodash';

const statickParam: any = {
  sortBy: '',
  order: '',
};

const Companies = () => {
  const navigate = useNavigate();
  const { EMPLOYEES, CLASSIFICATION } = AdminPrivateRoutes;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [param, setParam] = React.useState<{
    sortBy: string;
    order: 'asc' | 'desc';
  }>(statickParam);
  const [loader, setLoader] = React.useState<boolean>(false);
  const [list, setList] = React.useState<any>([]);
  const [cItem, setCItem] = React.useState<any>({});

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, item: any) => {
    setAnchorEl(event.currentTarget);
    setCItem(item);
  };

  const gotoNavigate = (link: To, paramData?: any) => {
    if (paramData) {
      navigate(link, paramData);
    } else {
      navigate(link);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    setCItem({});
  };

  const getData = () => {
    setLoader(true);
    const endPoint = `${MASTER_ENDPOINT.CompanyList}`;
    getAllListData(endPoint)
      .then((response: any) => {
        setList(response?.data?.pagedData);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const changeParam = (keyName: string) => {
    setLoader(true);
    setParam((pre: any) => {
      return {
        ...pre,
        sortBy: keyName,
        order: param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      };
    });
    setList([
      ..._.orderBy(
        list,
        [
          (item: any) =>
            item[keyName]
              ? typeof item[keyName] === 'number'
                ? item[keyName]
                : item[keyName]?.toLowerCase()
              : '',
        ],
        param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      ),
    ]);
    setTimeout(() => setLoader(false), 500);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const tableData = React.useMemo(() => {
    if (list.length) {
      return list.map((row: any, index: number) => (
        <TableRow key={index} sx={{ ' &:last-child th': { border: 0 } }}>
          <TableCell>
            <Typography
              component={'div'}
              className='company-name'>{`${row?.companyName}`}</Typography>
          </TableCell>
          <TableCell>
            {row.city ? `${row?.city}, ` : ''}
            {row?.state ? row?.state : ''}
          </TableCell>
          <TableCell>{row?.noOfEmployees}</TableCell>
          <TableCell>
            <Typography className={row?.companyStatus === 1 ? 'active-badge' : ''}>
              {row?.companyStatus === 1 ? 'Active' : 'Inactive'}
            </Typography>
          </TableCell>
          <TableCell align='right'>
            <Button aria-describedby={id} onClick={(e) => handleClick(e, row)}>
              <MoreVertIcon />
            </Button>
          </TableCell>
        </TableRow>
      ));
    }
    return (
      <TableRow>
        <TableCell colSpan={5} align='center'>
          <Typography component={'div'} className='d-flex-ja norecord'>
            No Records Found
          </Typography>
        </TableCell>
      </TableRow>
    );
  }, [list]);

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <TableContainer component={Paper} className='companie-table'>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '25%' }}>
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='th-title'>Name</Typography>
                    <Typography className='arrow-box' onClick={() => changeParam('companyName')}>
                      <ArrowDropDownIcon
                        className='down'
                        style={{
                          fill:
                            param.sortBy === 'companyName' && param.order === 'desc' ? 'black' : '',
                        }}
                      />
                      <ArrowDropUpIcon
                        className='up'
                        style={{
                          fill:
                            param.sortBy === 'companyName' && param.order === 'asc' ? 'black' : '',
                        }}
                      />
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell style={{ width: '15%' }}>
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='th-title'>Location</Typography>
                    <Typography className='arrow-box' onClick={() => changeParam('city')}>
                      <ArrowDropDownIcon
                        className='down'
                        style={{
                          fill: param.sortBy === 'city' && param.order === 'desc' ? 'black' : '',
                        }}
                      />
                      <ArrowDropUpIcon
                        className='up'
                        style={{
                          fill: param.sortBy === 'city' && param.order === 'asc' ? 'black' : '',
                        }}
                      />
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell style={{ width: '40%' }}>
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='th-title'>Employees</Typography>
                    <Typography className='arrow-box' onClick={() => changeParam('noOfEmployees')}>
                      <ArrowDropDownIcon
                        className='down'
                        style={{
                          fill:
                            param.sortBy === 'noOfEmployees' && param.order === 'desc'
                              ? 'black'
                              : '',
                        }}
                      />
                      <ArrowDropUpIcon
                        className='up'
                        style={{
                          fill:
                            param.sortBy === 'noOfEmployees' && param.order === 'asc'
                              ? 'black'
                              : '',
                        }}
                      />
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell style={{ width: '25%' }}>
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='th-title'>Status</Typography>
                    <Typography className='arrow-box' onClick={() => changeParam('companyStatus')}>
                      <ArrowDropDownIcon
                        className='down'
                        style={{
                          fill:
                            param.sortBy === 'companyStatus' && param.order === 'desc'
                              ? 'black'
                              : '',
                        }}
                      />
                      <ArrowDropUpIcon
                        className='up'
                        style={{
                          fill:
                            param.sortBy === 'companyStatus' && param.order === 'asc'
                              ? 'black'
                              : '',
                        }}
                      />
                    </Typography>
                  </Typography>
                </TableCell>

                <TableCell style={{ width: '5%' }} />
              </TableRow>
            </TableHead>
            <TableBody>{loader ? <Loader loading={loader} colsSpan={5} /> : tableData}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Popover
        className='edit-popup'
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Typography className='arrow-top' />
        <Typography
          className='pop-item mb-6'
          onClick={() =>
            gotoNavigate(`${EMPLOYEES}/${cItem.companyId}`, {
              state: { companyName: cItem.companyName },
            })
          }>
          Employees
        </Typography>
        <Typography
          className='pop-item'
          onClick={() =>
            gotoNavigate(`${CLASSIFICATION}/${cItem.companyId}`, {
              state: { companyName: cItem.companyName },
            })
          }>
          Classification Codes
        </Typography>
      </Popover>
    </Grid>
  );
};

export default Companies;
