import React from 'react';
import BackImg from 'assets/png/loginbg.jpg';
import Logo from 'assets/png/LogoDark.svg';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from 'routes/routeConstans';

const PasswordSucess = ({ from }: any) => {
  const navigate: any = useNavigate();

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          component={'div'}
          sx={{ backgroundImage: `url(${BackImg})` }}
          className='login-banner'>
          <Typography
            component={'div'}
            className='login-box'
            sx={{ minHeight: '250px !important' }}
            textAlign={'center'}>
            <Typography className='logo-text d-flex-a'>
              <img src={Logo} alt='simplifiedHR' />
            </Typography>
            <form>
              <Typography className='info-text'>
                Your password has been created successfully.
              </Typography>
              <Typography className='link-text'>
                Please login to SimplifiedHR account to start using the application.
              </Typography>
              <Typography className='border-box' />
            </form>
            <Typography component={'p'} textAlign={'center'}>
              <Typography
                component={'span'}
                className='back-to-login'
                onClick={() => {
                  if (from === 'client') {
                    navigate(PublicRoutes.CLIENTLOGIN);
                  } else {
                    navigate(PublicRoutes.ADMINLOGIN);
                  }
                }}>
                Please Login
              </Typography>
            </Typography>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PasswordSucess;
