import {
  Drawer,
  Grid,
  // InputAdornment, TextField
  Typography,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import CloseIcon from '@mui/icons-material/Close';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { EE_DASHBOARD, MASTER_ENDPOINT } from 'utils/Constant';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import PageLoader from 'components/PageLoader';
import { Network } from 'vis-network';
// import { Search } from '@mui/icons-material';
import { DataSet } from 'vis-data';
import * as R from 'ramda';
import PlusPng from 'assets/png/plus.png';
import moment from 'moment';
import ScreenLoader from 'components/ScreenLoader';
import EmployeeSearchContext from 'context/employeeSearchContext';

// const staticLogo = 'https://freepngimg.com/download/man/22654-6-man.png';
// const staticLogo1 =
//   'https://pngimages.in/uploads/png-compressd/2023/April-2023/student_Image_Transparent.png';
// const staticLogo2 =
//   'https://pngimages.in/uploads/png-compressd/2023/May-2023/businessman_Png_Images.png';
const urlStrat = 'https://ui-avatars.com/api/?size=80&name=';
const urlEnd = '&font-size=0.5&color=FFFFFF&length=1';
// const urlEnd = '&font-size=0.5&color=FFFFFF&background=%23CCCDD2&length=1';

const EmployeeEgage = () => {
  const chartRef: any = useRef<HTMLDivElement>(null);
  const [drawer, setDrawer] = React.useState(false);
  const [nodes, setNodes] = React.useState<any>([]);
  const [edges, setEdges] = React.useState<any>([]);
  const [overallMagicScore, setOverallMagicScore] = React.useState<any>({});
  const [magicScore, setMagicScore] = React.useState<any>([]);
  const [details, setDetails] = React.useState<any>([]);
  const [moreDetails, setMoreDetails] = React.useState<any>([]);
  const [viewMore, setViewMore] = React.useState<any>({});
  const [viewMoreDetails, setViewMoreDetails] = React.useState<any>({});
  const [loader, setLoader] = React.useState<boolean>(true);
  const [loader1, setLoader1] = React.useState<boolean>(true);

  const {selectedEmployeeId} = React.useContext(EmployeeSearchContext);

  const { profileInfo, userInfo: userDetail }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });

   useEffect(() => {
     if(selectedEmployeeId === null || selectedEmployeeId === 0){
      setLoader(true);
      getAllListData(`${EE_DASHBOARD.OrgChart1}/${profileInfo?.clientID}/${userDetail?.Id}`)
        .then((resp: any) => {
          if (resp?.data?.networkGraph) {
            const companyData: any = [
              {
                id: 'rootNode',
                label: profileInfo?.clientName,
                shape: 'circularImage',
                level: 0,
                image: `${urlStrat}${profileInfo?.clientName}${urlEnd}`,
                overallMagicScore: resp?.data?.overallMagicScore,
                magicScore: resp?.data?.magicScore,
              },
            ];
            const edgesData: any = [];
            addNodeData(companyData, edgesData, 1, null, 'rootNode', resp?.data?.networkGraph);
            
            setEdges(new DataSet(edgesData));
            setNodes(new DataSet(companyData));

            setViewMoreDetails({employeeId: 0, image: ''});
            setDetails({});
          }
          setOverallMagicScore(resp?.data?.overallMagicScore ? resp?.data?.overallMagicScore : {});
          setMagicScore(resp?.data?.magicScore ? resp?.data?.magicScore : []);        
        })
        .finally(() => {
          setLoader(false);
        });
      }
      else{
        setLoader(true);
        getAllListData(`${EE_DASHBOARD.GetAllDirectReports}/${profileInfo?.clientID}/${userDetail?.Id}/${selectedEmployeeId}`)
        .then((resp: any) => {
          if (resp?.data?.networkGraph) {
            const nodeData: any = [
              {
                id: 'rootNode',
                label: profileInfo?.clientName,
                shape: 'circularImage',
                level: 0,
                image: `${urlStrat}${profileInfo?.clientName}${urlEnd}`,
                overallMagicScore: resp?.data?.overallMagicScore,
                magicScore: resp?.data?.magicScore,
              },
            ];
            const edgesData: any = [];
            addNodeData(nodeData, edgesData, 1, null, 'rootNode', resp?.data?.networkGraph);
 
            setEdges(new DataSet(edgesData));
            setNodes(new DataSet(nodeData));

            // Set node item to allow for View Details
            if (selectedEmployeeId !== 0) {
            
              const nodeItem = nodeData.find((n:any) => n.employeeId == selectedEmployeeId);
              if(nodeItem){
                setViewMoreDetails({ ...nodeItem });
                const url = `${EE_DASHBOARD.ViewMore}/${userDetail.ClientId}/${userDetail.Id}/${selectedEmployeeId}`;
                setLoader(true);
                
                getAllListData(url)
                  .then((res) => {
                    setDetails(res?.data);
                  })
                  .finally(() => {
                    setLoader(false);
                  });
               }
            }
          }
          setOverallMagicScore(resp?.data?.overallMagicScore ? resp?.data?.overallMagicScore : {});
          setMagicScore(resp?.data?.magicScore ? resp?.data?.magicScore : []);  
        })
        .finally(() => {
          setLoader(false);
        });
      }
           
  }, [selectedEmployeeId]); 
  
  useEffect(() => {
    if (nodes.length && edges.length) {
      const data = {
        nodes,
        edges,
      };

      let employeeId = 0;

      const options = {
        nodes: {
          shadow: true,
          borderWidth: 5,
          color: {
            // border: '#3441d2',
            // background: '#3441d2',
            // highlight: { border: '#3441d2', background: '#3441d2' },
            // hover: { border: '#3441d2', background: '#3441d2' },
          },
          font: {
            size: 15,
            color: '#171A33',
            strokeWidth: 3,
          },
        },
        edges: {
          shadow: true,
          color: '#171A33',
          arrows: {
            to: {
              enabled: true,
              type: 'circle',
              scaleFactor: 0.3,
            },
          },
        },
      };
      const network = new Network(chartRef.current, data, options);
      network.on('oncontext', (event: any) => {
        event.event.preventDefault();
        const nodeId: any = network.getNodeAt(event.pointer.DOM);
        const nodeItem = nodes.get(nodeId);
        if (nodeItem?.level !== 0) {
          if (nodeId) {
            setViewMore({
              nodeId,
              left: event.event.pageX,
              top: event.event.pageY,
            });
            setViewMoreDetails({ ...nodeItem });
          }
        } else {
          setViewMore({});
        }
      });
      network.on('click', (event: any) => {
        setViewMore({});
        const [clickedNode] = event.nodes;
        if (clickedNode) {
          const edgesData = edges.get({
            filter: (item: any) => item.from === clickedNode,
          });

          const nodeItem = nodes.get(clickedNode);
          // Set node item to allow for View Details
          if (nodeItem?.level !== 0 && nodeItem.employeeId !== employeeId) {
            employeeId = nodeItem.employeeId;

            if (nodeItem.id) {
              setViewMoreDetails({ ...nodeItem });
              const url = `${EE_DASHBOARD.ViewMore}/${userDetail.ClientId}/${userDetail.Id}/${nodeItem.employeeId}`;
              setLoader(true);
              
              getAllListData(url)
                .then((res) => {
                  setDetails(res?.data);
                })
                .finally(() => {
                  setLoader(false);
                });
            }
          }

          if (edgesData.length) {
            if(!nodeItem?.overallMagicScore){
              getAllListData(
                `${EE_DASHBOARD.OrgChart2}/${profileInfo?.clientID}/${nodeItem?.employeeId}/${userDetail?.Id}`,
              )
                .then((resp: any) => {
                   setOverallMagicScore(
                      resp?.data?.overallMagicScore ? resp?.data?.overallMagicScore : {},
                    );
                    setMagicScore(resp?.data?.magicScore ? resp?.data?.magicScore : []);
                    // network.focus(clickedNode);
                  })
                .finally(() => {
                  setLoader(false);
                });
            }
            setOverallMagicScore(nodeItem?.overallMagicScore ? nodeItem?.overallMagicScore : {});
            setMagicScore(nodeItem?.magicScore ? nodeItem?.magicScore : []);
            // const connected = network.getConnectedNodes(clickedNode);
            const child: any = nodes.get(edgesData[0].to);
            const hideOrNot: boolean = !child?.hidden;
            const checkNodes = (node: any, hideOrNot: boolean) => {
              if (!node) {
                return;
              }
              if (node && node.length > 0) {
                for (let i = 0; i < node.length; i++) {
                  const child: any = nodes.get(node[i].to);
                  // const hideOrNot: boolean = !child?.hidden;
                  nodes.update({ ...child, hidden: hideOrNot });
                  const edgesFindData = edges.get({
                    filter: (item: any) => item.from === node[i].to,
                  });
                  checkNodes(edgesFindData, hideOrNot);
                }
              }
            };
            checkNodes(edgesData, hideOrNot);
            return;
          }         

          setLoader(true);
          getAllListData(
            `${EE_DASHBOARD.OrgChart2}/${profileInfo?.clientID}/${nodeItem?.employeeId}/${userDetail?.Id}`,
          )
            .then((resp: any) => {
              if (resp?.data?.networkGraph) {
                const nodesData: any = [];
                const edgesData: any = [];
                addNodeData(nodesData, edgesData, 2, nodeItem?.classCodeId, nodeItem.id, resp?.data?.networkGraph);
                
                nodes.add(nodesData);
                edges.add(edgesData);  
                let existingNode = null;
                for(let i = 0; i < nodes.length; i++)
                {
                  const node = nodes[i];
                  if(nodeItem?.id && node?.id?.startsWith(nodeItem?.employeeId?.toStriong())){
                    existingNode = node;
                    break;
                  }
                }
                if(existingNode){                         
                  nodes.update({
                    ...nodeItem,
                    overallMagicScore: resp?.data?.overallMagicScore,
                    magicScore: resp?.data?.magicScore,
                    // image: `${urlStrat}${nodeItem?.label}${urlEnd}&background=%23${getRandomColor()}&color=000000`,
                  });
                }
                setOverallMagicScore(
                  resp?.data?.overallMagicScore ? resp?.data?.overallMagicScore : {},
                );
                setMagicScore(resp?.data?.magicScore ? resp?.data?.magicScore : []);
                // network.focus(clickedNode);
              }
            })
            .finally(() => {
              setLoader(false);
            });
        }
        // }
      });
    }
  }, [nodes, edges]);

  const addNodeData = (nodesData:any, edgesData:any, nodeLevel:number, nodeClassCodeId: any, parentNode: any,  inputNodes:any) => {    
    inputNodes.map((item: any) => {
      const today = new Date();
      const newId = Number(item.employeeId).toString() + today.getSeconds().toString() + today.getMilliseconds().toString() + Math.random().toString() ;
                 
        const colorCode =
          item?.status === 'Good Standing'
            ? '#344A5C'
            : item?.status === 'Needs Attention'
              ? '#C60C68'
              : item?.status === 'On the Move'
                ? '#C3D34F'
                : '#CCCDD2';
        const colorCode1 =
          item?.status === 'Good Standing'
            ? '344A5C'
            : item?.status === 'Needs Attention'
              ? 'C60C68'
              : item?.status === 'On the Move'
                ? 'C3D34F'
                : 'CCCDD2';

          const newNode:any = {
            id: newId,  
            label: item.displayName,
            shape: 'circularImage',
            level: nodeLevel,
            classCodeId: nodeClassCodeId,
            overallMagicScore: item?.overallMagicScore,
            magicScore: item?.magicScore,
            image: item?.userImageId
              ? `${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${item?.userImageId}`
              : `${urlStrat}${item.lName}${urlEnd}&background=%23${colorCode1}`,
            ...item,
            color: {
              background: colorCode,
              border: colorCode,
              highlight: {
                border: colorCode,
                background: colorCode,
              },
              hover: {
                border: colorCode,
                background: colorCode,
              }
            }
          };

          nodesData.push(
            newNode
          );

          edgesData.push({
            id: newId,  
            from: parentNode?.toString(),
            to: newId, 
            arrows: item.employeeCount
              ? {
                  to: {
                    enable: true,
                    type: 'image',
                    imageWidth: 17,
                    imageHeight: 17,
                    expand: true,
                    src: PlusPng,
                  },
                }
              : {},
          });

          if(item !== null && item?.children?.length > 0){
            addNodeData(nodesData, edgesData, 2, nodeClassCodeId, newId, item?.children);
          }         
    })
  };

  const handleToggle = async (toogleBoolean: boolean) => {
    setDrawer(toogleBoolean);
    setViewMore({});
    if (toogleBoolean) {
      const url = `${EE_DASHBOARD.ViewMore}/${userDetail.ClientId}/${userDetail.Id}/${viewMoreDetails.employeeId}`;
      setLoader1(true);
      await getAllListData(url)
        .then((res) => {
          setDetails(res?.data);
        })
        .finally(() => {
          setLoader1(false);
        });
      const UrlM = `${EE_DASHBOARD.ViewMoreMagic}/${userDetail.ClientId}/${userDetail.Id}/${viewMoreDetails.employeeId}`;
      await getAllListData(UrlM).then((res) => {
        setMoreDetails(res?.data);
      });
    }
  };

  const HRmonthLegend = () => {
    return (
      <Typography component={'div'} className='d-flex-ja'>
        <Typography component={'div'} className='d-flex-a hr-text mr-10'>
          <Typography className='s-dot' sx={{ backgroundColor: '#C3D34F' }} />
          Team
        </Typography>
        {/* <Typography component={'div'} className='d-flex-a hr-text mr-10'>
          <Typography className='s-dot' sx={{ backgroundColor: '#3441D2' }} />
          Department
        </Typography> */}
        <Typography component={'div'} className='d-flex-a hr-text mr-10'>
          <Typography className='s-dot' sx={{ backgroundColor: '#C60C68' }} />
          Organization
        </Typography>
        <Typography component={'div'} className='d-flex-a hr-text'>
          <Typography className='s-dot' sx={{ backgroundColor: '#0DA728' }} />
          Employee
        </Typography>
      </Typography>
    );
  };

  const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor='end' fill='#979598' fontSize={12}>
          {moment(payload.value).format('MMM')}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'}>{`${moment(payload[0].payload.yearMonth).format(
            'MMMM YYYY',
          )}`}</Typography>
          <Typography component={'p'} className='view-green'>
            Team: {payload[0].payload?.teamScore}
          </Typography>
          {/* <Typography component={'p'} className='view-dep'>
            Department: {payload[0].payload?.departmentScore}
          </Typography> */}
          <Typography component={'p'} className='view-org'>
            Organization: {payload[0].payload?.organizationScore}
          </Typography>
          <Typography component={'p'} className='view-you'>
            Employee: {payload[0].payload?.individualScore}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  return (
    <Grid container>
      <PageLoader loading={loader} />
      <Grid item md={12} sm={12} xs={12}>
        {/* <Grid item md={3} sm={12} xs={12}>
          <Typography component={'div'} sx={{ padding: '10px 15px' }}>
            <TextField
              type={'search'}
              fullWidth
              variant='standard'
              placeholder={'Search Employee...'}
              className='input-field'
              // onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <Typography component={'div'} className='class-code-list-box'>
              {classCodeContentLis()}
            </Typography>
          </Typography>
        </Grid> */}
        <Typography component={'div'} className='org-container'>
          {viewMore && Object.keys(viewMore).length ? (
            <Typography
              component={'div'}
              className='rmenu'
              sx={{ left: viewMore.left, top: viewMore.top }}>
              <Typography onClick={() => handleToggle(true)}>View More Details</Typography>
            </Typography>
          ) : (
            <Typography />
          )}

          <Typography component={'div'} style={{ height: '100%', width: '100%' }} ref={chartRef} />
        </Typography>
        <Typography component={'div'} className='d-flex-ja flex-column'>
          {/* <Typography className='org-view-more' onClick={handleToggle}>
            View More Details
          </Typography> */}
          <Drawer
            className='employee-drawer'
            anchor='right'
            open={drawer}
            // onClose={handleToggle}
          >
            <Typography className='drawer-close d-flex-ja' onClick={() => handleToggle(false)}>
              <CloseIcon />
            </Typography>
            {loader1 ? (
              <Typography
                component={'div'}
                textAlign={'center'}
                sx={{
                  paddingTop: '100px',
                }}>
                <ScreenLoader loading={loader1} />
              </Typography>
            ) : (
              <>
                {!details?.length ? (
                  <>
                    <Typography component={'div'} className='top-pad-box'>
                      <Grid container>
                        <Grid item md={9} sm={12} xs={12}>
                          <Typography component={'div'} className='d-flex-a'>
                            {/* {viewMoreDetails?.level !== 1 && ( */}
                            <Typography component={'div'}>
                              {viewMoreDetails?.image ? (
                                <img
                                  className='default-pic d-flex-ja'
                                  src={viewMoreDetails?.image}
                                />
                              ) : (
                                <Typography component={'div'} className='default-pic d-flex-ja'>
                                  {`${ details?.employeeInformations  &&
                                        details?.employeeInformations?.[0]?.name
                                      ?.split(' ')[0]
                                      ?.split('')[0]}${details?.employeeInformations?.[0]?.name
                                      ?.split(' ')[1]
                                      ?.split('')[0]}`}
                                </Typography>
                              )}
                            </Typography>
                            {/* )} */}
                            <Typography component={'div'} className='flex-1'>
                              {/* {viewMoreDetails?.level !== 1 ? ( */}
                              <Typography className='emp-name'>
                                { details?.employeeInformations &&
                                        details?.employeeInformations?.[0]?.name ?
                                        details?.employeeInformations?.[0]?.name : ''}
                              </Typography>
                              {/* // ) : ( */}
                              {/* <Typography className='emp-name'>Department</Typography> */}
                              {/* // )} */}
                              <Typography className='emp-dep'>
                                {details?.department ? details?.department : '-'}
                              </Typography>
                              {/* {viewMoreDetails?.level !== 1 && ( */}
                              <Typography className='emp-dep'>
                                Email:{' '}
                                <span>
                                  { details?.employeeInformations && 
                                    details?.employeeInformations?.[0]?.email
                                    ? details?.employeeInformations?.[0]?.email
                                    : '-'}
                                </span>
                              </Typography>
                              {/* )} */}
                            </Typography>
                          </Typography>
                        </Grid>
                        {/* <Grid
                          item
                          md={3}
                          sm={12}
                          xs={12}
                          className='d-flex-a'
                          justifyContent='flex-end'>
                          <Typography className='eye-view d-flex-ja'>
                            <VisibilityIcon />
                          </Typography>
                        </Grid> */}
                      </Grid>
                    </Typography>
                    <Typography component={'div'} className='warn-pad-box'>
                      {/* {viewMoreDetails?.level !== 1 && (
                        <Typography component={'div'} className='warn-info-box'>
                          <Typography component={'div'} className='d-flex'>
                            <GppMaybeIcon />

                            <Typography component={'div'} className='flex-1  ml-13'>
                              <Typography className='notes'>
                                This employee is at risk, due to a low performance score. Please
                                consider reaching out.
                              </Typography>

                              <Grid container columnSpacing={2} className='mt-14'>
                                <Grid item md={6} sm={12} xs={12}>
                                  <Typography component={'div'} className='d-flex-a'>
                                    <Typography className='info'>Email: </Typography>
                                    <Typography className='details' sx={{ marginLeft: '5px' }}>
                                      {details?.employeeInformations?.[0]?.email}
                                    </Typography>
                                  </Typography>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
        <Typography component={'div'} className='d-flex-a'>
          <Typography className='info'>Phone:</Typography>
          <Typography className='details'>214-456-7890</Typography>
        </Typography>
      </Grid>
                              </Grid>
                            </Typography>
                          </Typography>
                        </Typography>
                      )} */}
                      {/* {viewMoreDetails?.level !== 1 && ( */}
                      <Grid container className='mt-14 pb-24 border-bottom'>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-info'>Job Title</Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-detail'>
                            { details?.employeeInformations && 
                                    details?.employeeInformations?.[0]?.jobTitle
                              ? details?.employeeInformations?.[0]?.jobTitle
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-info'>Hire Date</Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-detail'>
                            { details?.employeeInformations && 
                                    details?.employeeInformations?.[0]?.hireDate
                              ? moment(details?.employeeInformations?.[0]?.hireDate).format(
                                  'MM/DD/YYYY',
                                )
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-info'>Salary</Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-detail'>
                            { details?.employeeInformations && 
                                    details?.employeeInformations?.[0]?.salary
                              ? `$${details?.employeeInformations?.[0]?.salary.toLocaleString()}`
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-info'>
                            Probability of Leaving in a year
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-detail'>
                            { details?.employeeInformations && 
                                    details?.employeeInformations[0]?.propabilityOfLeavingInAYear
                              ? details?.employeeInformations[0]?.propabilityOfLeavingInAYear
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-info'>Department</Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-detail'>
                            { details?.employeeInformations && 
                                    details?.employeeInformations?.[0]?.department
                              ? details?.employeeInformations?.[0]?.department
                              : '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                      {/* // )} */}
                      <Typography className='emp-line' sx={{ paddingTop: '5px' }}>
                        Engagement Score
                      </Typography>
                      {/* <Typography className='line sub'>
                        Avg monthly per voluntary or other
                      </Typography> */}
                      <LineChart
                        className='linechart-emp'
                        width={500}
                        height={300}
                        data={details.satisfactionScores}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}>
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='yearMonth' tick={<CustomizedAxisTick />} />
                        <YAxis />
                        <Tooltip content={CustomTooltip} />
                        <Legend verticalAlign='bottom' align='center' content={HRmonthLegend} />
                        <Line
                          type='monotone'
                          dataKey='teamScore'
                          stroke='#c3d34f'
                          strokeWidth='3'
                        />
                        {/* <Line
                          type='monotone'
                          dataKey='departmentScore'
                          stroke='#3441d2'
                          strokeWidth='3'
                        /> */}
                        <Line
                          type='monotone'
                          dataKey='organizationScore'
                          stroke='#C60C68'
                          strokeWidth='3'
                        />
                        <Line
                          type='monotone'
                          dataKey='individualScore'
                          stroke='#0DA728'
                          strokeWidth='3'
                        />
                      </LineChart>
                      <Grid
                        container
                        className='mt-30'
                        sx={{ borderTop: '1px solid #e8e8e8', paddingTop: '24px' }}>
                        <Grid item md={3} sm={12} xs={12}>
                          <Typography component={'div'} className='no-box d-flex-j flex-column'>
                            <Typography
                              className='total-title'
                              sx={{ letterSpacing: '-1px !important' }}>
                              Engagement Score
                            </Typography>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography className='total-num'>
                                {details?.averageSatisfactionScore
                                  ? details?.averageSatisfactionScore
                                  : 0}
                              </Typography>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12} paddingLeft={2}>
                          <Typography component={'div'} className='no-box d-flex-j flex-column'>
                            <Typography className='total-title'>Comp Ratio</Typography>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography className='total-num'>
                                {moreDetails?.avgCompaRatio}%
                              </Typography>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                          <Typography component={'div'} className='no-box d-flex-j flex-column'>
                            <Typography className='total-title'>Performance</Typography>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography className='total-num'>
                                {moreDetails?.avgPerformance}%
                              </Typography>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                          <Typography component={'div'} className='no-box d-flex-j flex-column'>
                            <Typography className='total-title'>Tenure</Typography>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography className='total-num'>
                                {moreDetails?.averageTenure} years
                              </Typography>
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  </>
                ) : (
                  <Typography
                    component={'div'}
                    sx={{ height: '550px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                )}
              </>
            )}
          </Drawer>
          { details?.employeeInformations?.[0]?.name !== undefined && 
                                    details?.employeeInformations?.[0]?.name !== null &&
          <Typography component={'div'} className="username_detailbox">
              <Typography className="nametext" component={'div'}>{details?.employeeInformations?.[0]?.name} </Typography>       
              <Typography className="viewdetails" onClick={() => handleToggle(true)} >View Details</Typography>
          </Typography>
          }
          <Typography component={'div'} className='org-total-box d-flex-a'>
            <Typography component={'div'} className='ot-box d-flex-j flex-column'>
              <Typography className='total-title'>Overall</Typography>
              {overallMagicScore && Object.keys(overallMagicScore).length ? (
                <Typography component={'div'} className='d-flex-a'>
                  <Typography className='total-num'>{overallMagicScore?.score}</Typography>
                  <Typography className='d-flex-a' component={'div'}>
                    <ArrowDownwardIcon
                      className={overallMagicScore?.scoreVariation >= 0 ? 'down-per' : 'up-per'}
                    />{' '}
                    <Typography className='per-txt'>
                      {overallMagicScore?.scorePercentage}%
                    </Typography>
                  </Typography>
                </Typography>
              ) : (
                <Typography component={'div'} className='d-flex-a' justifyContent={'center'}>
                  <Typography className='total-num'>-</Typography>
                </Typography>
              )}
            </Typography>
            <Typography component={'div'} className='flex-1'>
              <Grid container>
                <Grid item md={2.0} sm={12} xs={12}>
                  <Typography component={'div'} className='no-box d-flex-j flex-column'>
                    <Typography className='total-title'>Purpose</Typography>
                    {/* { Meaning changed has Purpose } */}
                    {R.findIndex(R.propEq('Meaning', 'value'))(magicScore ? magicScore : []) !==
                    -1 ? (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>
                          {
                            magicScore[
                              R.findIndex(R.propEq('Meaning', 'value'))(
                                magicScore ? magicScore : [],
                              )
                            ].score
                          }
                        </Typography>
                        <Typography className='d-flex-a' component={'div'}>
                          <ArrowDownwardIcon
                            className={
                              magicScore[
                                R.findIndex(R.propEq('Meaning', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scoreVariation >= 0
                                ? 'down-per'
                                : 'up-per'
                            }
                          />{' '}
                          <Typography className='per-txt'>
                            {
                              magicScore[
                                R.findIndex(R.propEq('Meaning', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scorePercentage
                            }
                            %
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>-</Typography>
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid item md={2.0} sm={12} xs={12}>
                  <Typography component={'div'} className='no-box d-flex-j flex-column'>
                    <Typography className='total-title'>Independence</Typography>
                    {/* { Autonomy has changed Independence } */}
                    {R.findIndex(R.propEq('Autonomy', 'value'))(magicScore ? magicScore : []) !==
                    -1 ? (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>
                          {
                            magicScore[
                              R.findIndex(R.propEq('Autonomy', 'value'))(
                                magicScore ? magicScore : [],
                              )
                            ].score
                          }
                        </Typography>
                        <Typography className='d-flex-a' component={'div'}>
                          <ArrowDownwardIcon
                            className={
                              magicScore[
                                R.findIndex(R.propEq('Autonomy', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scoreVariation >= 0
                                ? 'down-per'
                                : 'up-per'
                            }
                          />{' '}
                          <Typography className='per-txt'>
                            {
                              magicScore[
                                R.findIndex(R.propEq('Autonomy', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scorePercentage
                            }
                            %
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>-</Typography>
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid item md={2.0} sm={12} xs={12}>
                  <Typography component={'div'} className='no-box d-flex-j flex-column'>
                    <Typography className='total-title'>Growth</Typography>
                    {R.findIndex(R.propEq('Growth', 'value'))(magicScore ? magicScore : []) !==
                    -1 ? (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>
                          {
                            magicScore[
                              R.findIndex(R.propEq('Growth', 'value'))(magicScore ? magicScore : [])
                            ].score
                          }
                        </Typography>
                        <Typography className='d-flex-a' component={'div'}>
                          <ArrowDownwardIcon
                            className={
                              magicScore[
                                R.findIndex(R.propEq('Growth', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scoreVariation >= 0
                                ? 'down-per'
                                : 'up-per'
                            }
                          />{' '}
                          <Typography className='per-txt'>
                            {
                              magicScore[
                                R.findIndex(R.propEq('Growth', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scorePercentage
                            }
                            %
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>-</Typography>
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid item md={2.0} sm={12} xs={12}>
                  <Typography component={'div'} className='no-box d-flex-j flex-column'>
                    <Typography className='total-title'>Impact</Typography>
                    {R.findIndex(R.propEq('Impact', 'value'))(magicScore ? magicScore : []) !==
                    -1 ? (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>
                          {
                            magicScore[
                              R.findIndex(R.propEq('Impact', 'value'))(magicScore ? magicScore : [])
                            ].score
                          }
                        </Typography>
                        <Typography className='d-flex-a' component={'div'}>
                          <ArrowDownwardIcon
                            className={
                              magicScore[
                                R.findIndex(R.propEq('Impact', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scoreVariation >= 0
                                ? 'down-per'
                                : 'up-per'
                            }
                          />{' '}
                          <Typography className='per-txt'>
                            {
                              magicScore[
                                R.findIndex(R.propEq('Impact', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scorePercentage
                            }
                            %
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>-</Typography>
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid item md={2.0} sm={12} xs={12}>
                  <Typography component={'div'} className='no-box d-flex-j flex-column'>
                    <Typography className='total-title'>Relationship</Typography>
                    {/* {Connection has been changed Relationship} */}
                    {R.findIndex(R.propEq('Connection', 'value'))(magicScore ? magicScore : []) !==
                    -1 ? (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>
                          {
                            magicScore[
                              R.findIndex(R.propEq('Connection', 'value'))(
                                magicScore ? magicScore : [],
                              )
                            ].score
                          }
                        </Typography>
                        <Typography className='d-flex-a' component={'div'}>
                          <ArrowDownwardIcon
                            className={
                              magicScore[
                                R.findIndex(R.propEq('Connection', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scoreVariation >= 0
                                ? 'down-per'
                                : 'up-per'
                            }
                          />{' '}
                          <Typography className='per-txt'>
                            {
                              magicScore[
                                R.findIndex(R.propEq('Connection', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scorePercentage
                            }
                            %
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>-</Typography>
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid item md={2.0} sm={12} xs={12}>
                  <Typography component={'div'} className='no-box d-flex-j flex-column'>
                    <Typography className='total-title'>Capacity</Typography>
                    {/* { Autonomy has changed Capacity } */}
                    {R.findIndex(R.propEq('Capacity', 'value'))(magicScore ? magicScore : []) !==
                    -1 ? (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>
                          {
                            magicScore[
                              R.findIndex(R.propEq('Capacity', 'value'))(
                                magicScore ? magicScore : [],
                              )
                            ].score
                          }
                        </Typography>
                        <Typography className='d-flex-a' component={'div'}>
                          <ArrowDownwardIcon
                            className={
                              magicScore[
                                R.findIndex(R.propEq('Capacity', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scoreVariation >= 0
                                ? 'down-per'
                                : 'up-per'
                            }
                          />{' '}
                          <Typography className='per-txt'>
                            {
                              magicScore[
                                R.findIndex(R.propEq('Capacity', 'value'))(
                                  magicScore ? magicScore : [],
                                )
                              ].scorePercentage
                            }
                            %
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography component={'div'} className='d-flex-a'>
                        <Typography className='total-num'>-</Typography>
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EmployeeEgage;
