import React from 'react';
import { getAllListData } from 'services/CommonServices';
import { EE_DASHBOARD } from 'utils/Constant';
import { useAppSelector } from 'store/hooks';
import EmployeeSearchContext from 'context/employeeSearchContext';

const EmployeeSearchTextBox = () =>
{ 
    const {setSelectedEmployeeId} = React.useContext(EmployeeSearchContext);
    const [filteredSuggestions, setFilteredSuggestions] = React.useState<any>([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = React.useState(0);
    const [showSuggestions, setShowSuggestions] = React.useState(false);
    const [searchText, setSearchText] = React.useState('');
    const defferedSearch = React.useDeferredValue(searchText);

    const { userInfo: userDetail }: any = useAppSelector((store: any) => {
        return store.userLogin;
      });

    const onChange = (evt:any) => {
        const userInput = evt.target.value;

        setSearchText(userInput);

        if(userInput !== undefined && userInput != null && userInput !== ''){
        const url = `${EE_DASHBOARD.GetEmployeesBySearch}/${userDetail.ClientId}/${userDetail.Id}/${userInput}`;
                            
              getAllListData(url)
                .then((res) => {
                    // Filter our suggestions that don't contain the user's input
                    const unLinked = res.data.filter(
                        (suggestion:any) =>
                        suggestion?.fullName?.toLowerCase().indexOf(userInput?.toLowerCase()) > -1
                    );
                
                    setFilteredSuggestions(unLinked);
                    setActiveSuggestionIndex(0);
                    setShowSuggestions(true);
                })
                .finally(() => {
                });
            }
            else{
                setFilteredSuggestions([]);
                setActiveSuggestionIndex(0);
                setShowSuggestions(false);
            }
      }

      const onClick = (evt:any) => {
        setSelectedEmployeeId(evt.target.id)
        setSearchText(evt.target.innerText);
        setFilteredSuggestions([]);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
      };

      const onKeyPress = (event:any) =>{
        const code = event.keyCode || event.which;
        if(code === 13) { 
          setSelectedEmployeeId(event.target.id)
          setSearchText(event.target.innerText);
          setFilteredSuggestions([]);
          setActiveSuggestionIndex(0);
          setShowSuggestions(false);
        } 
      }

      const clearSelection = () => {
        setSelectedEmployeeId(0)
        setSearchText('');
        setFilteredSuggestions([]);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
      };

      const SuggestionsListComponent = () => {
        return filteredSuggestions.length ? (
                <ul className="suggestions" tabIndex={3}>{ 
                    filteredSuggestions.map((suggestion:any, index:any) => {
                    let className;
                    // Flag the active suggestion with a class
                    if (index === activeSuggestionIndex) {
                        className = 'suggestion-active';
                    }
                    if(index === 0){
                      return (
                          <li className={className} tabIndex={4} key={suggestion.id} id={suggestion.id} onClick={onClick} onKeyDown={onKeyPress}>
                              {suggestion.fullName }
                          </li>
                      )
                    }
                    else{
                      return (
                        <li className={className} tabIndex={4+index} key={suggestion.id} id={suggestion.id} onClick={onClick} onKeyDown={onKeyPress}>
                            {suggestion.fullName }
                        </li>
                      )
                    }
                  })}
                  </ul>
        ) : (
          <div className='no-suggestions'>
            <em>No employees found.</em>
          </div>
        );
      };

    return(
        <>        
            <input className='emp-search-input' 
                type='text'
                onChange={onChange}
                tabIndex={1}
                value={defferedSearch} 
                placeholder='Employee Search' /> <button onClick={clearSelection} className='emp-search-button' tabIndex={2}>x</button>          
            {showSuggestions && defferedSearch && <SuggestionsListComponent />}
        </>
    )
}; 
    
export default EmployeeSearchTextBox;