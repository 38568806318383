import  React from 'react';
import FilterDataContext from './filterDataContext';

const FilterDataContextProvider: React.FC<{children: React.ReactNode}> = ({ children }) =>{

    const [filterData, setFilterData] = React.useState( {
        pre :{},
        codeList: [],
        jobList: [],
        companyList: [],
        directSuperVisorList: []
    });

    const data = { filterData, setFilterData };

    return(
        <FilterDataContext.Provider value={data} >{ children }</FilterDataContext.Provider>
    );
};

export default FilterDataContextProvider;