import { Drawer, Grid, IconButton, Typography, Tooltip as TooltipMUI, Menu, MenuItem } from '@mui/material';
import InfoIcon from 'assets/png/info.png';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import React, { FunctionComponent, useEffect, useRef, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { EyeToolTipTextOverAll } from 'utils/Config';
import PageLoader from 'components/PageLoader';
import jsPDF from 'jspdf';
import domtoimage from 'dom-to-image';
// import { Network } from 'vis-network';
import {
  LineChart, 
  Line,
  Area,
  AreaChart,
  CartesianGrid,
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
  Legend,
} from 'recharts';
import * as R from 'ramda';
import { COLORS, PERFORMANCE, EE_DASHBOARD } from 'utils/Constant';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import ScreenLoader from 'components/ScreenLoader';
import moment from 'moment';
import Logo from 'assets/png/LogoDarkPng.png';
import fileDownload from 'js-file-download';
import FilterDataContext from 'context/filterDataContext';

const COLOR: { [key: string]: string } = {
  'Good Standing': '#344A5C',
  'On the Move': '#C3D34F',
  'Needs Attention': '#C60C68',
};

const Performance = () => {
  const { userInfo: userDetail, profileInfo }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });
  let cellPayload = {compaRatio:0,
    employeeId:0,
    engagementScore: 0,
    modelStatus:'Good Standing',
    name:'',
    performanceRating: 0.0
  };
  const [drawer, setDrawer] = React.useState(false);
  const [details, setDetails] = React.useState<any>([]);
  const [moreDetails, setMoreDetails] = React.useState<any>([]);
  const [viewMoreDetails, setViewMoreDetails] = React.useState<any>({});  
  const [loader1, setLoader1] = React.useState<boolean>(false);
  const [tab, setTab] = React.useState<any>('All');
  const [hideIcon, setHideIcon] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dwnldOption, setDwnldOption] = React.useState<{
    name: string;
    idx: number;
  }>({
    name: '',
    idx: 0,
  });
  const [performanceRating, setPerformanceRating] = React.useState<{
    loading: boolean;
    data: any;
  }>({
    loading: true,
    data: [],
  });

  const [salaryDistribution, setSalaryDistribution] = React.useState<{
    loading: boolean;
    data: any;
  }>({
    loading: true,
    data: [],
  });

  const [range, setRange] = React.useState<any>([]);

  const [compaRatio, setCompaRatio] = React.useState<{
    loading: boolean;
    data: any;
  }>({
    loading: true,
    data: [],
  });

  const open = Boolean(anchorEl);

  const downloadRef0: any = useRef(null);
  const downloadRef1: any = useRef(null);
  const downloadRef2: any = useRef(null);
  const scatterChartRef: any = useRef(null);
  const {filterData} = useContext(FilterDataContext);

  const handleDownLoadClose = () => {
    setAnchorEl(null);
    setDwnldOption({ name: '', idx: 0 });
  };

  const handleDownload = (type: string, name: string, idx: number) => {
    setHideIcon(true);
    const refOption =
      idx === 0
        ? downloadRef0.current
        : idx === 1
          ? downloadRef1.current
          : idx === 2
            ? downloadRef2.current
            : '';
    if (type === 'img') {
      domtoimage.toBlob(refOption).then(function (blob) {
        fileDownload(
          blob,
          `${name}-${profileInfo?.clientName}-${moment().format('MM/DD/YYYY')}.png`,
        );
        setHideIcon(false);
      });
    } else {
      domtoimage.toBlob(refOption).then(function (blob) {
        const newImg = new Image();
        newImg.onload = function () {
          const height = newImg.height;
          const width = newImg.width;
          const LogoSrc: any = newImg.src;
          const doc = new jsPDF();
          doc.setFontSize(10);
          doc.text(`Downloaded Date: ${moment().format('MM-DD-YYYY')}`, 10, 10);
          doc.text(`Company Name: ${profileInfo?.clientName}`, 10, 18);
          doc.addImage(
            LogoSrc,
            'png',
            30,
            30,
            width / 4 > 150 ? 150 : width / 4,
            height / 4 > 287 ? 250 : height / 4,
          );
          const img = new Image();
          const src = Logo;
          img.src = src;
          doc.addImage(img, 'png', 100, 287, 40, 5);
          doc.setFontSize(10);
          doc.text('Powered by ', 80, 290);
          doc.save(`${name}-${profileInfo?.clientName}-${moment().format('MM/DD/YYYY')}.pdf`);
          setHideIcon(false);
        };
        const imgSrc = URL.createObjectURL(blob);
        newImg.src = imgSrc;
      });
    }
    handleDownLoadClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, name: string, idx: number) => {
    setAnchorEl(event.currentTarget);
    setDwnldOption({ name, idx });
  };

  const CustomLabel = ({ viewBox }: any) => {
    let averageTotal = 0;
    if (performanceRating?.data?.length) {
      const getPrice: any = R.prop('totalCount');
      const sumPrices: any = R.reduce(R.add, 0);
      const averagePrice = sumPrices(R.map(getPrice, performanceRating?.data));
      averageTotal = Math.round(averagePrice);
    }

    return (
      <text
        x={viewBox.cx}
        y={viewBox.cy - 10}
        fill='#3d405c'
        style={{ outline: 'none !important' }}
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'>
        <tspan
          style={{
            outline: 'none !important',
          }}
          alignmentBaseline='middle'
          className={
            String(averageTotal).length > 5 ? 'total-pie total-pie-extra-text' : 'total-pie'
          }>
          {averageTotal}
        </tspan>
        <tspan
          style={{ outline: 'none !important' }}
          x={viewBox.cx}
          y={viewBox.cy + 20}
          className='ave-pie'>
          Total
        </tspan>
      </text>
    );
  };

  const GetApi = async () => {
    getAllListData(`${PERFORMANCE.PerformanceRating}/${userDetail.ClientId}/${userDetail?.Id}`)
      .then((res: any) => {
        const result = R.sortWith([R.descend(R.compose(R.prop('totalCount')))]);
        setPerformanceRating((_prev) => {
          return { ..._prev, loading: false, data: result(res?.data ? res?.data : []) };
        });
      })
      .finally(() => {
        setPerformanceRating((_prev) => {
          return { ..._prev, loading: false };
        });
      });
    getAllListData(`${PERFORMANCE.CompaRatio}/${userDetail.ClientId}/${userDetail?.Id}`)
      .then((resp) => {
        const result = R.sortWith([R.descend(R.compose(R.prop('performanceRating')))]);
        setCompaRatio((_prev) => {
          return { ..._prev, loading: false, data: result(resp?.data ? resp?.data : []) };
        });
      })
      .finally(() => {
        setCompaRatio((_prev) => {
          return { ..._prev, loading: false };
        });
      });
    getAllListData(`${PERFORMANCE.SalaryDistribution}/${userDetail.ClientId}/${userDetail?.Id}`)
      .then((resp) => {
        setSalaryDistribution((_prev) => {
          return { ..._prev, loading: false, data: resp?.data ? resp?.data : [] };
        });
        const data = resp?.data.map((e: any) => e.range);
        setRange(data);
      })
      .finally(() => {
        setSalaryDistribution((_prev) => {
          return { ..._prev, loading: false };
        });
      });
  };

  const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor='end' fill='#979598' fontSize={12}>
          {moment(payload.value).format('MMM')}
        </text>
      </g>
    );
  };

  const findMedian = (numbers: any) => {
    const sortedNumbers = numbers.slice().sort((a: any, b: any) => a - b);

    if (sortedNumbers.length % 2 === 1) {
      // Odd number of elements
      return sortedNumbers[Math.floor(sortedNumbers.length / 2)];
    } else {
      // Even number of elements
      const middle1 = sortedNumbers[sortedNumbers.length / 2 - 1];
      const middle2 = sortedNumbers[sortedNumbers.length / 2];
      return (middle1 + middle2) / 2;
    }
  };

  useEffect(() => {
    GetApi();
    setViewMoreDetails({});
  }, [filterData]);

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const handleTabChange = (tab: any) => {
    setTab(tab);
  };

  const filteredData =
    tab === 'All'
      ? compaRatio?.data
      : compaRatio?.data.filter((item: any) => item.modelStatus === tab);

  const PerformanceRating = () => {
    return (
      <Typography className='legend-ul'>
        {/* <Typography className='leg-info'>Top 10 Categories</Typography> */}
        {performanceRating?.data.map((entry: any, index: any) => (
          <Typography key={`item-${index}`}>
            <Typography component={'div'} className='d-flex-a flex-sb'>
              <li className='leg-info d-flex-a' style={{ width: '100%' }}>
                <Typography
                  component={'div'}
                  sx={{ backgroundColor: `${COLORS[index]}`, width: '100%' }}
                  className='legend-dot'
                />
                {entry?.roundedRating ? `Level ${entry?.roundedRating}` : 'No Rating'}
              </li>
              <Typography className='leg-info'>{entry?.totalCount}</Typography>
            </Typography>
          </Typography>
        ))}
      </Typography>
    );
  };

  const CustomPerformanceRating = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'} sx={{ color: `${COLORS[payload[0].name]} !important` }}>
            {payload[0].payload.roundedRating
              ? `Level ${payload[0].payload.roundedRating}`
              : 'No Rating'}
            : {Math.round(payload[0].payload.totalCount)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'}>{`${moment(payload[0].payload.yearMonth).format(
            'MMMM YYYY',
          )}`}</Typography>
          <Typography component={'p'} className='view-green'>
            Team: {payload[0].payload?.teamScore}
          </Typography>
          <Typography component={'p'} className='view-org'>
            Organization: {payload[0].payload?.organizationScore}
          </Typography>
          <Typography component={'p'} className='view-you'>
            Employee: {payload[0].payload?.individualScore}
          </Typography>
        </Typography>
      );
    }
    return null;
  };
  const handleToggle = async (toogleBoolean: boolean) => {    
    setDrawer(toogleBoolean); 
  };
  const scatterChartClick = async () => {
    if (cellPayload && cellPayload?.employeeId) 
    { 
        const nodeItem = { employeeId: cellPayload?.employeeId, image: ''}
          if (nodeItem.employeeId) {
            const url = `${EE_DASHBOARD.ViewMore}/${userDetail.ClientId}/${userDetail.Id}/${nodeItem.employeeId}`;
            setLoader1(true);
            await getAllListData(url)
              .then((res) => {
                setDetails(res?.data);

                if (!scatterChartRef.current) {
                  return;
                }
                scatterChartRef.current.setState({
                  isTooltipActive: false
                });
              })
              .finally(() => {
                setLoader1(false);  
                window.focus();              
              });
            const UrlM = `${EE_DASHBOARD.ViewMoreMagic}/${userDetail.ClientId}/${userDetail.Id}/${nodeItem.employeeId}`;
            await getAllListData(UrlM).then((res) => {
              setMoreDetails(res?.data);
              setDrawer(true);              
            });            
        }
    };
  }
  const HRmonthLegend = () => {
    return (
      <Typography component={'div'} className='d-flex-ja'>
        <Typography component={'div'} className='d-flex-a hr-text mr-10'>
          <Typography className='s-dot' sx={{ backgroundColor: '#C3D34F' }} />
          Team
        </Typography>
        <Typography component={'div'} className='d-flex-a hr-text mr-10'>
          <Typography className='s-dot' sx={{ backgroundColor: '#C60C68' }} />
          Organization
        </Typography>
        <Typography component={'div'} className='d-flex-a hr-text'>
          <Typography className='s-dot' sx={{ backgroundColor: '#0DA728' }} />
          Employee
        </Typography>
      </Typography>
    );
  };
  const CustomSalaryDistribution = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'} sx={{ color: `${COLORS[payload[0].name]} !important` }}>
            {payload[0].payload.range
              ? `Annual Rate: $${payload[0].payload.range.toLocaleString()} `
              : 'No Rating'}
          </Typography>
          <Typography> Count: {Math.round(payload[0].payload.count)}</Typography>
        </Typography>
      );
    }
    return null;
  };
  const CustomHeatMap = ({ active, payload }: any) => {
    if (active && payload && payload.length) {      
      cellPayload = payload[0].payload;
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography> Name: {`${payload[0].payload.name}`}</Typography>
          <Typography component={'p'} sx={{ color: `${COLORS[payload[0].name]} !important` }}>
            Performance Rating: {payload[0].payload.performanceRating}
          </Typography>
          <Typography> Compa Ratio: {`${payload[0].payload.compaRatio}%`}</Typography>
          <Typography> Model Status: {`${payload[0].payload.modelStatus}`}</Typography>
          <Typography> Engagement Score: {`${payload[0].payload.engagementScore}`}</Typography>
        </Typography>
      );
    }
    return null;
  };
  return (
    <Grid container>
      <PageLoader loading={loader1} />
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleDownLoadClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={() => handleDownload('img', dwnldOption.name, dwnldOption.idx)}>
          Image
        </MenuItem>
        <MenuItem onClick={() => handleDownload('pdf', dwnldOption.name, dwnldOption.idx)}>
          PDF
        </MenuItem>
      </Menu>
      <Typography component={'div'} className='d-flex-ja flex-column'>
          {<Drawer
            className='employee-drawer'
            anchor='right'
            open={drawer}
          >
            <Typography className='drawer-close d-flex-ja' onClick={() => handleToggle(false)}>
              <CloseIcon />
            </Typography>
            {(
              <>
                {!details?.length ? (
                  <>
                    <Typography component={'div'} className='top-pad-box'>
                      <Grid container>
                        <Grid item md={9} sm={12} xs={12}>
                          <Typography component={'div'} className='d-flex-a'>
                            {/* {viewMoreDetails?.level !== 1 && ( */}
                            <Typography component={'div'}>
                              {viewMoreDetails?.image ? (
                                <img
                                  className='default-pic d-flex-ja'
                                  src={viewMoreDetails?.image}
                                />
                              ) : (
                                <Typography component={'div'} className='default-pic d-flex-ja'>
                                  {`${ details?.employeeInformations  &&
                                        details?.employeeInformations?.[0]?.name
                                      ?.split(' ')[0]
                                      ?.split('')[0]}${details?.employeeInformations?.[0]?.name
                                      ?.split(' ')[1]
                                      ?.split('')[0]}`}
                                </Typography>
                              )}
                            </Typography>
                            {/* )} */}
                            <Typography component={'div'} className='flex-1'>
                              {/* {viewMoreDetails?.level !== 1 ? ( */}
                              <Typography className='emp-name'>
                                { details?.employeeInformations &&
                                        details?.employeeInformations?.[0]?.name ?
                                        details?.employeeInformations?.[0]?.name : ''}
                              </Typography>
                              {/* // ) : ( */}
                              {/* <Typography className='emp-name'>Department</Typography> */}
                              {/* // )} */}
                              <Typography className='emp-dep'>
                                {details?.department ? details?.department : '-'}
                              </Typography>
                              {/* {viewMoreDetails?.level !== 1 && ( */}
                              <Typography className='emp-dep'>
                                Email:{' '}
                                <span>
                                  { details?.employeeInformations && 
                                    details?.employeeInformations?.[0]?.email
                                    ? details?.employeeInformations?.[0]?.email
                                    : '-'}
                                </span>
                              </Typography>
                              {/* )} */}
                            </Typography>
                          </Typography>
                        </Grid>
                        {}
                      </Grid>
                    </Typography>
                    <Typography component={'div'} className='warn-pad-box'>
                      {}
                      {}
                      <Grid container className='mt-14 pb-24 border-bottom'>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-info'>Job Title</Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-detail'>
                            { details?.employeeInformations && 
                                    details?.employeeInformations?.[0]?.jobTitle
                              ? details?.employeeInformations?.[0]?.jobTitle
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-info'>Hire Date</Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-detail'>
                            { details?.employeeInformations && 
                                    details?.employeeInformations?.[0]?.hireDate
                              ? moment(details?.employeeInformations?.[0]?.hireDate).format(
                                  'MM/DD/YYYY',
                                )
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-info'>Salary</Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-detail'>
                            { details?.employeeInformations && 
                                    details?.employeeInformations?.[0]?.salary
                              ? `$${details?.employeeInformations?.[0]?.salary.toLocaleString()}`
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-info'>
                            Probability of Leaving in a year
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-detail'>
                            { details?.employeeInformations && 
                                    details?.employeeInformations[0]?.propabilityOfLeavingInAYear
                              ? details?.employeeInformations[0]?.propabilityOfLeavingInAYear
                              : '-'}
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-info'>Department</Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography className='det-detail'>
                            { details?.employeeInformations && 
                                    details?.employeeInformations?.[0]?.department
                              ? details?.employeeInformations?.[0]?.department
                              : '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                      {/* // )} */}
                      <Typography className='emp-line' sx={{ paddingTop: '5px' }}>
                        Engagement Score
                      </Typography>
                      {/* <Typography className='line sub'>
                        Avg monthly per voluntary or other
                      </Typography> */}
                      <LineChart
                        className='linechart-emp'
                        width={500}
                        height={300}
                        data={details.satisfactionScores}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}>
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='yearMonth' tick={<CustomizedAxisTick />} />
                        <YAxis />
                        <Tooltip content={CustomTooltip} />
                        <Legend verticalAlign='bottom' align='center' content={HRmonthLegend} />
                        <Line
                          type='monotone'
                          dataKey='teamScore'
                          stroke='#c3d34f'
                          strokeWidth='3'
                        />
                        {/* <Line
                          type='monotone'
                          dataKey='departmentScore'
                          stroke='#3441d2'
                          strokeWidth='3'
                        /> */}
                        <Line
                          type='monotone'
                          dataKey='organizationScore'
                          stroke='#C60C68'
                          strokeWidth='3'
                        />
                        <Line
                          type='monotone'
                          dataKey='individualScore'
                          stroke='#0DA728'
                          strokeWidth='3'
                        />
                      </LineChart>
                      <Grid
                        container
                        className='mt-30'
                        sx={{ borderTop: '1px solid #e8e8e8', paddingTop: '24px' }}>
                        <Grid item md={3} sm={12} xs={12}>
                          <Typography component={'div'} className='no-box d-flex-j flex-column'>
                            <Typography
                              className='total-title'
                              sx={{ letterSpacing: '-1px !important' }}>
                              Engagement Score
                            </Typography>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography className='total-num'>
                                {details?.averageSatisfactionScore
                                  ? details?.averageSatisfactionScore
                                  : 0}
                              </Typography>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12} paddingLeft={2}>
                          <Typography component={'div'} className='no-box d-flex-j flex-column'>
                            <Typography className='total-title'>Comp Ratio</Typography>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography className='total-num'>
                                {moreDetails?.avgCompaRatio}%
                              </Typography>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                          <Typography component={'div'} className='no-box d-flex-j flex-column'>
                            <Typography className='total-title'>Performance</Typography>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography className='total-num'>
                                {moreDetails?.avgPerformance}%
                              </Typography>
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item md={3} sm={12} xs={12}>
                          <Typography component={'div'} className='no-box d-flex-j flex-column'>
                            <Typography className='total-title'>Tenure</Typography>
                            <Typography component={'div'} className='d-flex-a'>
                              <Typography className='total-num'>
                                {moreDetails?.averageTenure} years
                              </Typography>
                            </Typography>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  </>
                ) : (
                  <Typography
                    component={'div'}
                    sx={{ height: '550px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                )}
              </>
            )}
          </Drawer>
        }
      </Typography>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='ov-box'>
          <Grid container columnSpacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography
                component={'div'}
                className='pie-box'
                sx={{ minHeight: '450px !important' }}
                ref={downloadRef0}>
                {!hideIcon && performanceRating?.data?.length ? (
                  <Typography className='down-box d-flex-ja'>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e: any) => handleClick(e, 'Performance Rating', 0)}>
                      <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography />
                )}
                <Typography className='c-title d-flex-a mb-15'>
                  Performance Rating&nbsp;&nbsp;
                  {!hideIcon && (
                    <TooltipMUI
                      title={
                        <Typography className='tooltip-text'>
                          {EyeToolTipTextOverAll.performance.performanceRating}
                        </Typography>
                      }>
                      <img className='info-im' src={InfoIcon} alt='info' />
                    </TooltipMUI>
                  )}
                </Typography>
                {performanceRating?.loading ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={performanceRating?.loading} />
                  </Typography>
                ) : !performanceRating?.data?.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '100%', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : (
                  <Typography component={'div'} className='d-flex'>
                    <Typography component={'div'} sx={{ width: '175px', height: 250 }}>
                      <ResponsiveContainer width={'100%'} height={'100%'}>
                        <PieChart>
                          <Tooltip content={<CustomPerformanceRating />} />
                          <Pie
                            data={performanceRating?.data}
                            cx='50%'
                            cy='50%'
                            innerRadius={60}
                            outerRadius={80}
                            fill='#8884d8'
                            paddingAngle={1}
                            dataKey='totalCount'>
                            {performanceRating?.data.map((entry: any, index: number) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                            <Label
                              width={30}
                              position='center'
                              content={<CustomLabel y={130} />}></Label>
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </Typography>
                    <Typography component={'div'} className='flex-1'>
                      {PerformanceRating()}
                    </Typography>
                  </Typography>
                )}
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='pie-box' ref={downloadRef1}
                    sx={{ minHeight: '450px !important' }}>
                {!hideIcon && salaryDistribution?.data?.length ? (
                  <Typography className='down-box d-flex-ja'>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e: any) => handleClick(e, 'Salary Distribution', 1)}>
                      <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography />
                )}
                <Typography className='c-title d-flex-a mb-15'>
                  Salary Distribution &nbsp;&nbsp;
                  {!hideIcon && (
                    <TooltipMUI
                      title={
                        <Typography className='tooltip-text'>
                          {EyeToolTipTextOverAll.performance.salaryDistribution}
                        </Typography>
                      }>
                      <img className='info-im' src={InfoIcon} alt='info' />
                    </TooltipMUI>
                  )}
                </Typography>
                {salaryDistribution?.loading ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={salaryDistribution?.loading} />
                  </Typography>
                ) : !salaryDistribution?.data?.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '100%', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : (
                  <>
                    <Typography component={'div'} className='d-flex'>
                      <ResponsiveContainer width='100%' height={300}>
                        <AreaChart
                          data={salaryDistribution.data}
                          margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                          }}>
                          <defs>
                            <linearGradient id='colorUv' x='0' y='0'>
                              <stop offset='0%' stopColor='#C3D34F' stopOpacity={1} />
                              <stop offset='100%' stopColor='#0BAFC6' stopOpacity={1} />
                            </linearGradient>
                          </defs>
                          <CartesianGrid
                            strokeDasharray='8 8'
                            horizontal={false}
                            strokeWidth={1}
                            stroke='#979797'
                            fillOpacity={1}
                          />
                          <YAxis
                            strokeDasharray='0 10'
                            label={{
                              value: 'Average of Count',
                              angle: 270,
                              dx: -20,
                              dy: -20,
                            }}
                            type='number'
                            dataKey='count'
                            name='Compa Ratio'
                          />
                          <XAxis dataKey='range' interval={0} tick={false}>
                            {/* <Label
                              position='insideBottom'
                              offset={0}
                              value={salaryDistribution.data.range}
                              style={{ fontSize: '80%', fill: 'black', padding: '100px' }}
                            /> */}
                          </XAxis>
                          <Area
                            type='monotone'
                            dataKey='count'
                            // stroke='#8884d8'
                            strokeWidth={0}
                            fillOpacity={0.9}
                            fill='url(#colorUv)'
                          />
                          <Tooltip
                            content={<CustomSalaryDistribution />}
                            cursor={{ strokeDasharray: '3 3' }}
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </Typography>

                    <Grid container>
                      <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        justifyContent={'space-between'}
                        className='d-flex-a'>
                        <Typography>
                          <Typography className='leg-info'>{`$${Math.min(
                            ...range,
                          ).toLocaleString()}`}</Typography>
                          <Typography>Min Salary</Typography>
                        </Typography>
                        <Typography>
                          <Typography className='leg-info'>
                            {' '}
                            {`$${findMedian(range).toLocaleString()}`}
                          </Typography>
                          <Typography> Median Salary</Typography>
                        </Typography>
                        <Typography>
                          <Typography className='leg-info'>{`$${Math.max(
                            ...range,
                          ).toLocaleString()}`}</Typography>
                          <Typography>Max Salary</Typography>
                        </Typography>
                      </Grid>
                      {/* <Grid item md={4} sm={4} xs={12}>
                        Medimum
                      </Grid>
                      <Grid item md={4} sm={4} xs={12}>
                        Medimum
                      </Grid> */}
                    </Grid>
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography component={'div'} className='pie-box' ref={downloadRef2}>
                {!hideIcon && compaRatio?.data?.length ? (
                  <Typography className='down-box d-flex-ja'>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e: any) =>
                        handleClick(e, 'Performance to Compa-Ratio Heat Map-1', 2)
                      }>
                      <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography />
                )}
                <Typography className='c-title d-flex-a mb-15'>
                  Performance to Compa-Ratio Heat Map&nbsp;
                  {!hideIcon && (
                    <TooltipMUI
                      title={
                        <Typography className='tooltip-text'>
                          {EyeToolTipTextOverAll.performance.performancetoCompaRatioHeatMap}
                        </Typography>
                      }>
                      <img className='info-im' src={InfoIcon} alt='info' />
                    </TooltipMUI>
                  )}
                  &nbsp;&nbsp;
                  {!hideIcon && compaRatio?.data?.length ? (
                    <Typography
                      component={'div'}
                      className='d-flex-a view-option-tab employee-legent'
                      sx={{ width: '500px', position: 'absolute', right: '70px' }}>
                      <Typography
                        className={tab === 'All' ? 'tab-btn active' : 'tab-btn'}
                        onClick={() => handleTabChange('All')}>
                        All
                      </Typography>
                      <Typography
                        className={
                          tab === 'On the Move' ? 'tab-btn active d-flex-a' : 'tab-btn d-flex-a'
                        }
                        onClick={() => handleTabChange('On the Move')}>
                        <Typography className='opt-box rs' />
                        <Typography className='opt-text'> On the Move</Typography>
                      </Typography>
                      <Typography
                        className={
                          tab === 'Good Standing' ? 'tab-btn active d-flex-a' : 'tab-btn d-flex-a'
                        }
                        onClick={() => handleTabChange('Good Standing')}>
                        <Typography className='opt-box gs' />
                        <Typography className='opt-text'> Good Standing</Typography>
                      </Typography>
                      <Typography
                        className={
                          tab === 'Needs Attention' ? 'tab-btn active d-flex-a' : 'tab-btn d-flex-a'
                        }
                        onClick={() => handleTabChange('Needs Attention')}>
                        <Typography className='opt-box na' />
                        <Typography className='opt-text'>Needs Attention</Typography>
                      </Typography>
                    </Typography>
                  ) : (
                    <Typography />
                  )}
                </Typography>
                {compaRatio?.loading ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={compaRatio?.loading} />
                  </Typography>
                ) : !compaRatio?.data?.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '250px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : (
                  <Typography component={'div'} className='d-flex'>
                    <ResponsiveContainer width='100%' height={300}>
                      <ScatterChart
                        ref={scatterChartRef}
                        margin={{
                          top: 20,
                          right: 20,
                          bottom: 20,
                          left: 20,
                        }}>
                        <CartesianGrid stroke='white' />
                        <XAxis
                          strokeDasharray='0 10'
                          label={{ value: 'Performance  Rating', dx: 10, dy: 15 }}
                          type='number'
                          domain={[0, 5]}
                          dataKey='performanceRating'
                          name='Performance Rating'
                        />
                        <YAxis
                          strokeDasharray='0 10'
                          label={{ value: 'Average of Compa-Ratio', angle: 270, dx: -30, dy: -20 }}
                          type='number'
                          unit='%'
                          dataKey='compaRatio'
                          name='Compa Ratio'
                        />
                        <ZAxis range={[200, 200]} />
                        <Tooltip content={<CustomHeatMap />} cursor={{ strokeDasharray: '3 3' }} />
                        <Scatter data={filteredData} fill='#8884d8'>
                          {filteredData.map((entry: any, index: number) => (
                            <Cell
                              width={'100px'}
                              key={`cell-${index}`}
                              fill={COLOR[entry.modelStatus]}                              
                              onClick={scatterChartClick}
                            />
                          ))}
                        </Scatter>
                      </ScatterChart>
                    </ResponsiveContainer>
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Performance;
