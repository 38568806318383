import React, { useEffect, useState } from 'react';
import BackImg from 'assets/png/loginbg.jpg';
import Logo from 'assets/png/LogoDark.svg';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import { ClientLoginForm } from 'utils/ValidationForm';
import { ClientLoginSchema } from 'utils/ValidatorSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { PublicRoutes } from 'routes/routeConstans';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import CryptoJS from 'crypto-js';
import { USER_LOGIN_SUCCESS } from 'store/types/UserTypes';
import { useDispatch } from 'react-redux';
import Storage from 'utils/Storage';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CustomSelect from 'components/customSelect';

const ClientLogin = () => {
  const location: any = useLocation();
  const navigation: any = useNavigate();
  const dispatch: any = useDispatch();
  const [ip, setIP] = useState('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const { handleSubmit, control, formState } = useForm<ClientLoginForm>({
    defaultValues: {
      password: '',
      clientId: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(ClientLoginSchema),
  });

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   event.preventDefault();
  // };

  const getUserIp = async () => {
    const res = await axios.get('https://api.ipify.org?format=json');
    setIP(res?.data?.ip);
  };

  useEffect(() => {
    getUserIp();
  }, []);

  const goBackOption = () => {
    navigation(PublicRoutes.CLIENTLOGIN);
  };

  const submitForm = (data: ClientLoginForm) => {
    setLoading(true);
    const secertKey = CryptoJS.enc.Utf8.parse('6ENY03$PR@CT*CE$');
    const iv = CryptoJS.enc.Utf8.parse('6ENY03$PR@CT*CE$');
    const encryptedPassword = CryptoJS.AES.encrypt(`${data.password}`, secertKey, {
      keySize: 128 / 8,
      mode: CryptoJS.mode.CBC,
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
    getAllListData(
      `${MASTER_ENDPOINT.VerifyClient}?userId=${location?.state
        ?.email}&password=${encryptedPassword}&userIp=${ip ? ip : null}&clientId=${
        data['clientId']
      }`,
      true,
    )
      .then((response: any) => {
        if (response.token) {
          const userData: any = jwtDecode(String(response?.token));
          userData['token'] = response?.token;
          userData['userDetailsData'] = response?.data;
          userData['isAdmin'] = false;
          userData['isClient'] = true;
          userData['isImpersonate'] = false;
          userData['isFromSuperAdmin'] = '';
          userData['superUserId'] = '';
          const tokenData: any = {
            refreshToken: '',
            token: response?.token,
            userId: userData.id,
            isAdmin: false,
            isClient: true,
            isImpersonate: false,
            isFromSuperAdmin: '',
            superUserId: '',
          };
          Storage.setItem(Storage.KEYS.AUTH_TOKEN, JSON.stringify(tokenData));
          setTimeout(
            () =>
              dispatch({
                payload: userData,
                type: USER_LOGIN_SUCCESS,
              }),
            500,
          );
        } else {
          navigation(PublicRoutes.CLIENTRESET, { state: { ...location?.state, ...data } });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          component={'div'}
          sx={{ backgroundImage: `url(${BackImg})` }}
          className='login-banner'>
          <Typography component={'div'} className='login-box'>
            <Typography className='logo-text d-flex-a'>
              <img src={Logo} alt='simplifiedHR' />
            </Typography>
            <form id='login-form' onSubmit={handleSubmit(submitForm)} autoComplete='new-password'>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>Select Company*</Typography>
                <Controller
                  control={control}
                  defaultValue=''
                  name='clientId'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        id='outlined-basic'
                        placeHolder={'Select'}
                        name={name}
                        value={value}
                        labelKey={'clientName'}
                        valueKey={'clientID'}
                        options={location?.state?.companyList ? location?.state?.companyList : []}
                        error={formState.errors.clientId?.message}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </Typography>
              <Typography component={'div'} className='custom-field' sx={{ paddingTop: '10px' }}>
                <Typography sx={{ display: 'flex' }} justifyContent={'space-between'}>
                  <Typography component={'p'}>Password*</Typography>
                  <Typography
                    className='forgot-password'
                    component={'p'}
                    onClick={() =>
                      navigation(PublicRoutes.FORGOTPASSWORDCLIENT, { state: location?.state })
                    }>
                    Forget Password?
                  </Typography>
                </Typography>
                <Controller
                  control={control}
                  defaultValue=''
                  name='password'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <TextField
                        className='custom-input'
                        id='standard-basic'
                        placeholder='Enter Password'
                        variant='standard'
                        sx={{
                          '& button': {
                            marginRight: '5px',
                          },
                        }}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge='end'>
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          ),
                        }}
                        onChange={(e) => {
                          onChange(e.target.value.replace(/\s/g, ''));
                        }}
                        value={value}
                        name={name}
                        error={formState.errors.password?.message ? true : false}
                        helperText={formState.errors.password?.message}
                      />
                    );
                  }}
                />
              </Typography>
              <LoadingButton
                className='submit-btn'
                loading={loading}
                form='login-form'
                type='submit'
                loadingPosition='center'>
                Login
              </LoadingButton>
              <Typography className='border-box' />
            </form>
            <Typography component={'p'} textAlign={'center'}>
              <Typography
                component={'span'}
                className='back-to-login'
                onClick={() => goBackOption()}>
                Back to Email
              </Typography>
            </Typography>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ClientLogin;
