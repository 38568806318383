import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { ColorRing } from 'react-loader-spinner';

interface LoaderProps {
  colsSpan: number;
  loading: boolean;
}

const TableLoader = (props: LoaderProps) => {
  const { colsSpan, loading } = props;
  return (
    <TableRow>
      <TableCell align='center' colSpan={colsSpan}>
        <ColorRing
          visible={loading}
          height='60'
          width='60'
          ariaLabel='vortex-loading'
          wrapperStyle={{}}
          wrapperClass='vortex-wrapper'
          colors={['#C3D34F', '#C3D34F', '#C3D34F', '#C3D34F', '#C3D34F']}
        />
      </TableCell>
    </TableRow>
  );
};

export default TableLoader;
