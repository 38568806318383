import { Grid, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import Loader from 'components/Loader';
import _ from 'lodash';

const statickParam: any = {
  order: '',
  sortBy: '',
};

const Classification = () => {
  const [list, setList] = React.useState<any>([]);
  const [param, setParam] = React.useState<{
    sortBy: string;
    order: 'asc' | 'desc';
  }>(statickParam);
  const [loader, setLoader] = React.useState<any>(false);

  const urlParams: any = useParams();

  useEffect(() => {
    setLoader(true);
    getAllListData(`${MASTER_ENDPOINT.Classification}/${urlParams.companyId}`)
      .then((res) => {
        setList(res?.data);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  const changeParams = (keyName: string) => {
    setLoader(true);
    setParam((pre: any) => {
      return {
        ...pre,
        sortBy: keyName,
        order: param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      };
    });
    setList([
      ..._.orderBy(
        list,
        [
          (item: any) =>
            item[keyName]
              ? typeof item[keyName] === 'number'
                ? item[keyName]
                : item[keyName]?.toLowerCase()
              : '',
        ],
        param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      ),
    ]);
    setTimeout(() => setLoader(false), 500);
  };

  const tableData = React.useMemo(() => {
    if (list.length) {
      return list.map((row: any) => (
        <TableRow key={row.id} sx={{ ' &:last-child th': { border: 0 } }}>
          <TableCell className='pl-62' sx={{ width: '350px' }}>
            <Typography className='company-name'> Class Code{row?.classificationCode}</Typography>
          </TableCell>
          <TableCell>{row?.mapping}</TableCell>
        </TableRow>
      ));
    }
    return (
      <TableRow>
        <TableCell colSpan={2} align='center'>
          <Typography component={'div'} className='d-flex-ja norecord'>
            No Records Found
          </Typography>
        </TableCell>
      </TableRow>
    );
  }, [list]);

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <TableContainer component={Paper} className='companie-table'>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell className='pl-62' sx={{ width: '350px' }}>
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='th-title'>Classification Codes</Typography>
                    <Typography
                      className='arrow-box'
                      onClick={() => changeParams('classificationCode')}>
                      <ArrowDropDownIcon className='down' />
                      <ArrowDropUpIcon className='up' />
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='th-title'>Mapping</Typography>
                    <Typography className='arrow-box' onClick={() => changeParams('mapping')}>
                      <ArrowDropDownIcon className='down' />
                      <ArrowDropUpIcon className='up' />
                    </Typography>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{loader ? <Loader loading={loader} colsSpan={2} /> : tableData}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Classification;
