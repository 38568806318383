import { Grid, Tooltip, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import React, { useEffect } from 'react';
import { getAllListData } from 'services/CommonServices';
import { EE_DASHBOARD, MASTER_ENDPOINT } from 'utils/Constant';
import { useAppSelector } from 'store/hooks';
import PageLoader from 'components/PageLoader';
import _ from 'lodash';

const statickParam: any = {
  sortBy: '',
  order: '',
  heading: '',
};

const EmployeeEngagementList = () => {
  const [loader, setLoader] = React.useState<boolean>(true);
  const [param, setParam] = React.useState<{
    sortBy: string;
    order: 'asc' | 'desc';
    heading: string;
  }>(statickParam);
  const [autonomyEmployeeList, setAutonomyEmployeeList] = React.useState<any>([]);
  const [meaningEmployeeList, setMeaningEmployeeList] = React.useState<any>([]);
  const [growthEmployeeList, setGrowthEmployeeList] = React.useState<any>([]);
  const [impactEmployeeList, setImpactEmployeeList] = React.useState<any>([]);
  const [connectionEmployeeList, setConnectionEmployeeList] = React.useState<any>([]);

  const { userInfo: userDetail }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });
  const EmployeeList = () => {
    setLoader(true);
    getAllListData(`${EE_DASHBOARD.OrgList}/${userDetail.ClientId}/${userDetail.Id}`)
      .then((res) => {
        if (res?.data && res?.data?.length) {
          res?.data.filter((e: any) => {
            if (e.value === 'Autonomy') {
              const employeeList = e?.employeeList;
              setAutonomyEmployeeList(employeeList);
            } else if (e.value === 'Meaning') {
              const employeeList = e?.employeeList;
              setMeaningEmployeeList(employeeList);
            } else if (e.value === 'Growth') {
              const employeeList = e?.employeeList;
              setGrowthEmployeeList(employeeList);
            } else if (e.value === 'Impact') {
              const employeeList = e?.employeeList;
              setImpactEmployeeList(employeeList);
            } else if (e.value === 'Connection') {
              const employeeList = e?.employeeList;
              setConnectionEmployeeList(employeeList);
            }
          });
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    EmployeeList();
  }, []);

  // useEffect(() => {
  //   if (params.sortBy && params.order) {
  //     if (params.order === 'desc' && params.state === 'Autonomy') {
  //       setAutonomyEmployeeList((pre: any) => [
  //         ...pre.sort((a: any, b: any) => {
  //           if (a.name.toLowerCase() > b.name.toLowerCase()) {
  //             return -1;
  //           }
  //           if (b.name.toLowerCase() > a.name.toLowerCase()) {
  //             return 1;
  //           }
  //           return 0;
  //         }),
  //       ]);
  //     } else {
  //       setAutonomyEmployeeList((pre: any) => [
  //         ...pre.sort((a: any, b: any) => {
  //           if (a.name.toLowerCase() < b.name.toLowerCase()) {
  //             return -1;
  //           }
  //           if (b.name.toLowerCase() < a.name.toLowerCase()) {
  //             return 1;
  //           }
  //           return 0;
  //         }),
  //       ]);
  //     }
  //     if (params.order === 'desc' && params.state === 'Meaning') {
  //       setMeaningEmployeeList((pre: any) => [
  //         ...pre.sort((a: any, b: any) => {
  //           if (a.name.toLowerCase() > b.name.toLowerCase()) {
  //             return -1;
  //           }
  //           if (b.name.toLowerCase() > a.name.toLowerCase()) {
  //             return 1;
  //           }
  //           return 0;
  //         }),
  //       ]);
  //     } else {
  //       setMeaningEmployeeList((pre: any) => [
  //         ...pre.sort((a: any, b: any) => {
  //           if (a.name.toLowerCase() < b.name.toLowerCase()) {
  //             return -1;
  //           }
  //           if (b.name.toLowerCase() < a.name.toLowerCase()) {
  //             return 1;
  //           }
  //           return 0;
  //         }),
  //       ]);
  //     }
  //     if (params.order === 'desc' && params.state === 'Growth') {
  //       setGrowthEmployeeList((pre: any) => [
  //         ...pre.sort((a: any, b: any) => {
  //           if (a.name.toLowerCase() > b.name.toLowerCase()) {
  //             return -1;
  //           }
  //           if (b.name.toLowerCase() > a.name.toLowerCase()) {
  //             return 1;
  //           }
  //           return 0;
  //         }),
  //       ]);
  //     } else {
  //       setGrowthEmployeeList((pre: any) => [
  //         ...pre.sort((a: any, b: any) => {
  //           if (a.name.toLowerCase() < b.name.toLowerCase()) {
  //             return -1;
  //           }
  //           if (b.name.toLowerCase() < a.name.toLowerCase()) {
  //             return 1;
  //           }
  //           return 0;
  //         }),
  //       ]);
  //     }
  //     if (params.order === 'desc' && params.state === 'Impact') {
  //       setImpactEmployeeList((pre: any) => [
  //         ...pre.sort((a: any, b: any) => {
  //           if (a.name.toLowerCase() > b.name.toLowerCase()) {
  //             return -1;
  //           }
  //           if (b.name.toLowerCase() > a.name.toLowerCase()) {
  //             return 1;
  //           }
  //           return 0;
  //         }),
  //       ]);
  //     } else {
  //       setImpactEmployeeList((pre: any) => [
  //         ...pre.sort((a: any, b: any) => {
  //           if (a.name.toLowerCase() < b.name.toLowerCase()) {
  //             return -1;
  //           }
  //           if (b.name.toLowerCase() < a.name.toLowerCase()) {
  //             return 1;
  //           }
  //           return 0;
  //         }),
  //       ]);
  //     }
  //     if (params.order === 'desc' && params.state === 'Connection') {
  //       setConnectionEmployeeList((pre: any) => [
  //         ...pre.sort((a: any, b: any) => {
  //           if (a.name.toLowerCase() > b.name.toLowerCase()) {
  //             return -1;
  //           }
  //           if (b.name.toLowerCase() > a.name.toLowerCase()) {
  //             return 1;
  //           }
  //           return 0;
  //         }),
  //       ]);
  //     } else {
  //       setConnectionEmployeeList((pre: any) => [
  //         ...pre.sort((a: any, b: any) => {
  //           if (a.name.toLowerCase() < b.name.toLowerCase()) {
  //             return -1;
  //           }
  //           if (b.name.toLowerCase() < a.name.toLowerCase()) {
  //             return 1;
  //           }
  //           return 0;
  //         }),
  //       ]);
  //     }
  //   }
  // }, [params]);

  // const ascendingDes = (key: any, order: string, state: any) => {
  //   setParams({
  //     sortBy: 'name',
  //     order: order,
  //     state: state,
  //   });
  // };

  const changeParam = (keyName: string, Heading: string) => {
    setLoader(true);
    setParam((pre: any) => {
      return {
        ...pre,
        sortBy: keyName,
        order:
          param.heading === Heading && param.sortBy === keyName && param.order === 'asc'
            ? 'desc'
            : 'asc',
        heading: Heading,
      };
    });
    setMeaningEmployeeList([
      ..._.orderBy(
        meaningEmployeeList,
        [
          (item: any) =>
            item[keyName]
              ? typeof item[keyName] === 'number'
                ? item[keyName]
                : item[keyName]?.toLowerCase()
              : '',
        ],
        param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      ),
    ]);
    setTimeout(() => setLoader(false), 500);
  };
  const changeParamAutonomy = (keyName: string, Heading: string) => {
    setLoader(true);
    setParam((pre: any) => {
      return {
        ...pre,
        sortBy: keyName,
        heading: Heading,
        order: param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      };
    });
    setAutonomyEmployeeList([
      ..._.orderBy(
        autonomyEmployeeList,
        [
          (item: any) =>
            item[keyName]
              ? typeof item[keyName] === 'number'
                ? item[keyName]
                : item[keyName]?.toLowerCase()
              : '',
        ],
        param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      ),
    ]);
    setTimeout(() => setLoader(false), 500);
  };

  const changeParamGrowth = (keyName: string, Heading: string) => {
    setLoader(true);
    setParam((pre: any) => {
      return {
        ...pre,
        sortBy: keyName,
        heading: Heading,
        order: param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      };
    });
    setGrowthEmployeeList([
      ..._.orderBy(
        growthEmployeeList,
        [
          (item: any) =>
            item[keyName]
              ? typeof item[keyName] === 'number'
                ? item[keyName]
                : item[keyName]?.toLowerCase()
              : '',
        ],
        param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      ),
    ]);
    setTimeout(() => setLoader(false), 500);
  };

  const changeParamImpact = (keyName: string, Heading: string) => {
    setLoader(true);
    setParam((pre: any) => {
      return {
        ...pre,
        sortBy: keyName,
        heading: Heading,
        order: param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      };
    });
    setImpactEmployeeList([
      ..._.orderBy(
        impactEmployeeList,
        [
          (item: any) =>
            item[keyName]
              ? typeof item[keyName] === 'number'
                ? item[keyName]
                : item[keyName]?.toLowerCase()
              : '',
        ],
        param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      ),
    ]);
    setTimeout(() => setLoader(false), 500);
  };

  const changeParamConnection = (keyName: string, Heading: string) => {
    setLoader(true);
    setParam((pre: any) => {
      return {
        ...pre,
        sortBy: keyName,
        heading: Heading,
        order: param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      };
    });
    setConnectionEmployeeList([
      ..._.orderBy(
        connectionEmployeeList,
        [
          (item: any) =>
            item[keyName]
              ? typeof item[keyName] === 'number'
                ? item[keyName]
                : item[keyName]?.toLowerCase()
              : '',
        ],
        param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      ),
    ]);
    setTimeout(() => setLoader(false), 500);
  };

  return (
    <Typography component={'div'} className='employee-table'>
      <PageLoader loading={loader} />
      <Grid container>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography
            component={'div'}
            className='d-flex-a flex-sb h-69 border-right border-bottom'>
            <Typography className='th-title'>Purpose</Typography>
            <Typography
              className='arrow-box'
              onClick={() => {
                // meaning changed has purpose
                changeParam('score', 'meaning');
              }}>
              <ArrowDropDownIcon
                className='down'
                style={{
                  fill:
                    param.heading === 'meaning' &&
                    param.sortBy === 'score' &&
                    param.order === 'desc'
                      ? 'black'
                      : '',
                }}
              />
              <ArrowDropUpIcon
                className='up'
                style={{
                  fill:
                    param.heading === 'meaning' && param.sortBy === 'score' && param.order === 'asc'
                      ? 'black'
                      : '',
                }}
              />
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography
            component={'div'}
            className='d-flex-a flex-sb h-69 border-right border-bottom'>
            <Typography className='th-title'>Independence</Typography>
            <Typography
              className='arrow-box'
              onClick={() => {
                // Autonomy has changed Independence
                changeParamAutonomy('score', 'Autonomy');
              }}>
              <ArrowDropDownIcon
                className='down'
                style={{
                  fill:
                    param.heading === 'Autonomy' &&
                    param.sortBy === 'score' &&
                    param.order === 'desc'
                      ? 'black'
                      : '',
                }}
              />
              <ArrowDropUpIcon
                className='up'
                style={{
                  fill:
                    param.heading === 'Autonomy' &&
                    param.sortBy === 'score' &&
                    param.order === 'asc'
                      ? 'black'
                      : '',
                }}
              />
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography
            component={'div'}
            className='d-flex-a flex-sb h-69 border-right border-bottom'>
            <Typography className='th-title'>Growth</Typography>
            <Typography
              className='arrow-box'
              onClick={() => {
                changeParamGrowth('score', 'Growth');
              }}>
              <ArrowDropDownIcon
                className='down'
                style={{
                  fill:
                    param.heading === 'Growth' && param.sortBy === 'score' && param.order === 'desc'
                      ? 'black'
                      : '',
                }}
              />
              <ArrowDropUpIcon
                className='up'
                style={{
                  fill:
                    param.heading === 'Growth' && param.sortBy === 'score' && param.order === 'asc'
                      ? 'black'
                      : '',
                }}
              />
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography
            component={'div'}
            className='d-flex-a flex-sb h-69 border-right border-bottom'>
            <Typography className='th-title'>Impact</Typography>
            <Typography
              className='arrow-box'
              onClick={() => {
                changeParamImpact('score', 'Impact');
              }}>
              <ArrowDropDownIcon
                className='down'
                style={{
                  fill:
                    param.heading === 'Impact' && param.sortBy === 'score' && param.order === 'desc'
                      ? 'black'
                      : '',
                }}
              />
              <ArrowDropUpIcon
                className='up'
                style={{
                  fill:
                    param.heading === 'Impact' && param.sortBy === 'score' && param.order === 'asc'
                      ? 'black'
                      : '',
                }}
              />
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='d-flex-a flex-sb h-69 border-bottom'>
            <Typography className='th-title'>Relationship</Typography>
            <Typography
              className='arrow-box'
              onClick={() => {
                // Connection has changed Relationship
                changeParamConnection('score', 'Connection');
              }}>
              <ArrowDropDownIcon
                className='down'
                style={{
                  fill:
                    param.heading === 'Connection' &&
                    param.sortBy === 'score' &&
                    param.order === 'desc'
                      ? 'black'
                      : '',
                }}
              />
              <ArrowDropUpIcon
                className='up'
                style={{
                  fill:
                    param.heading === 'Connection' &&
                    param.sortBy === 'score' &&
                    param.order === 'asc'
                      ? 'black'
                      : '',
                }}
              />
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Grid container className='body-content'>
        {!autonomyEmployeeList.length &&
        !meaningEmployeeList.length &&
        !growthEmployeeList.length &&
        !impactEmployeeList.length &&
        !connectionEmployeeList.length ? (
          <Grid item md={12} sm={12} xs={12}>
            <Typography component={'div'} className='d-flex-ja norecord'>
              No Records Found
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid item md={2.4} sm={12} xs={12}>
              <Grid container>
                {meaningEmployeeList.map((row: any, i: number) => {
                  return (
                    <Grid md={12} sm={12} xs={12} key={i} item>
                      <Typography
                        component={'div'}
                        className={`${
                          row?.status === 'Needs Attention'
                            ? 'normal-box red-bg'
                            : row?.status === 'Good Standing'
                              ? 'normal-box blue-bg'
                              : row?.status === 'On the Move'
                                ? 'normal-box green-bg'
                                : 'normal-box'
                        }`}
                        // 'normal-box red-bg'
                      >
                        <Typography
                          className={`${
                            row?.status === 'Needs Attention'
                              ? 'redbar'
                              : row?.status === 'Good Standing'
                                ? 'bluebar'
                                : row?.status === 'On the Move'
                                  ? 'greenbar'
                                  : ''
                          }`}
                          // className='redbar'
                        />
                        <Typography className='count d-flex-ja'>{i + 1}</Typography>
                        <Typography component={'div'} className='d-flex-ja'>
                          {row?.userImageId ? (
                            <img
                              className='d-avtaor d-flex-ja'
                              src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${row?.userImageId}`}
                            />
                          ) : (
                            <Typography className='d-avtaor d-flex-ja'>{`${row?.name?.charAt(
                              0,
                            )}`}</Typography>
                          )}
                        </Typography>
                        <Typography component={'div'} className='ml-8 d-flex-a flex-1'>
                          <Typography component={'div'} className='pr-8' sx={{ width: '80%' }}>
                            <Tooltip title={row?.name}>
                              <Typography className='ename'>{row?.name}</Typography>
                            </Tooltip>
                            <Typography className='edep'>{row?.description}</Typography>
                          </Typography>
                          <Typography className='eval text-right flex-1'>{row.score}</Typography>
                        </Typography>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item md={2.4} sm={12} xs={12}>
              <Grid container>
                {autonomyEmployeeList.map((row: any, i: number) => {
                  return (
                    <Grid md={12} sm={12} xs={12} key={i} item>
                      <Typography
                        component={'div'}
                        className={`${
                          row?.status === 'Needs Attention'
                            ? 'normal-box red-bg'
                            : row?.status === 'Good Standing'
                              ? 'normal-box blue-bg'
                              : row?.status === 'On the Move'
                                ? 'normal-box green-bg'
                                : 'normal-box'
                        }`}>
                        <Typography
                          className={`${
                            row?.status === 'Needs Attention'
                              ? 'redbar'
                              : row?.status === 'Good Standing'
                                ? 'bluebar'
                                : row?.status === 'On the Move'
                                  ? 'greenbar'
                                  : ''
                          }`}
                          // className='redbar'
                        />
                        <Typography className='count d-flex-ja'>{i + 1}</Typography>
                        <Typography component={'div'} className='d-flex-ja'>
                          {row?.userImageId ? (
                            <img
                              className='d-avtaor d-flex-ja'
                              src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${row?.userImageId}`}
                            />
                          ) : (
                            <Typography className='d-avtaor d-flex-ja'>{`${row?.name?.charAt(
                              0,
                            )}`}</Typography>
                          )}
                        </Typography>
                        <Typography component={'div'} className='ml-8 d-flex-a flex-1'>
                          <Typography component={'div'} className='pr-8' sx={{ width: '80%' }}>
                            <Tooltip title={row?.name}>
                              <Typography className='ename'>{row?.name}</Typography>
                            </Tooltip>
                            <Typography className='edep'>{row?.description}</Typography>
                          </Typography>
                          <Typography className='eval text-right flex-1'>{row.score}</Typography>
                        </Typography>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item md={2.4} sm={12} xs={12}>
              <Grid container>
                {growthEmployeeList.map((row: any, i: number) => {
                  return (
                    <Grid md={12} sm={12} xs={12} key={i} item>
                      <Typography
                        component={'div'}
                        className={`${
                          row?.status === 'Needs Attention'
                            ? 'normal-box red-bg'
                            : row?.status === 'Good Standing'
                              ? 'normal-box blue-bg'
                              : row?.status === 'On the Move'
                                ? 'normal-box green-bg'
                                : 'normal-box'
                        }`}>
                        <Typography
                          className={`${
                            row?.status === 'Needs Attention'
                              ? 'redbar'
                              : row?.status === 'Good Standing'
                                ? 'bluebar'
                                : row?.status === 'On the Move'
                                  ? 'greenbar'
                                  : ''
                          }`}
                          // className='redbar'
                        />
                        <Typography className='count d-flex-ja'>{i + 1}</Typography>
                        <Typography component={'div'} className='d-flex-ja'>
                          {row?.userImageId ? (
                            <img
                              className='d-avtaor d-flex-ja'
                              src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${row?.userImageId}`}
                            />
                          ) : (
                            <Typography className='d-avtaor d-flex-ja'>{`${row?.name?.charAt(
                              0,
                            )}`}</Typography>
                          )}
                        </Typography>
                        <Typography component={'div'} className='ml-8 d-flex-a flex-1 '>
                          <Typography component={'div'} className='pr-8' sx={{ width: '80%' }}>
                            <Tooltip title={row?.name}>
                              <Typography className='ename'>{row?.name}</Typography>
                            </Tooltip>
                            <Typography className='edep'>{row?.description}</Typography>
                          </Typography>
                          <Typography className='eval text-right flex-1'>{row.score}</Typography>
                        </Typography>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item md={2.4} sm={12} xs={12}>
              <Grid container>
                {impactEmployeeList.map((row: any, i: number) => {
                  return (
                    <Grid md={12} sm={12} xs={12} key={i} item>
                      <Typography
                        component={'div'}
                        className={`${
                          row?.status === 'Needs Attention'
                            ? 'normal-box red-bg'
                            : row?.status === 'Good Standing'
                              ? 'normal-box blue-bg'
                              : row?.status === 'On the Move'
                                ? 'normal-box green-bg'
                                : 'normal-box'
                        }`}>
                        <Typography
                          className={`${
                            row?.status === 'Needs Attention'
                              ? 'redbar'
                              : row?.status === 'Good Standing'
                                ? 'bluebar'
                                : row?.status === 'On the Move'
                                  ? 'greenbar'
                                  : ''
                          }`}
                          // className='redbar'
                        />
                        <Typography className='count d-flex-ja'>{i + 1}</Typography>
                        <Typography component={'div'} className='d-flex-ja'>
                          {row?.userImageId ? (
                            <img
                              className='d-avtaor d-flex-ja'
                              src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${row?.userImageId}`}
                            />
                          ) : (
                            <Typography className='d-avtaor d-flex-ja'>{`${row?.name?.charAt(
                              0,
                            )}`}</Typography>
                          )}
                        </Typography>
                        <Typography component={'div'} className='ml-8 d-flex-a flex-1'>
                          <Typography component={'div'} className='pr-8' sx={{ width: '80%' }}>
                            <Tooltip title={row?.name}>
                              <Typography className='ename'>{row?.name}</Typography>
                            </Tooltip>
                            <Typography className='edep'>{row?.description}</Typography>
                          </Typography>
                          <Typography className='eval text-right flex-1'>{row.score}</Typography>
                        </Typography>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item md={2.4} sm={12} xs={12}>
              <Grid container>
                {connectionEmployeeList.map((row: any, i: number) => {
                  return (
                    <Grid md={12} sm={12} xs={12} key={i} item>
                      <Typography
                        component={'div'}
                        className={`${
                          row?.status === 'Needs Attention'
                            ? 'normal-box red-bg'
                            : row?.status === 'Good Standing'
                              ? 'normal-box blue-bg'
                              : row?.status === 'On the Move'
                                ? 'normal-box green-bg'
                                : 'normal-box'
                        }`}>
                        <Typography
                          className={`${
                            row?.status === 'Needs Attention'
                              ? 'redbar'
                              : row?.status === 'Good Standing'
                                ? 'bluebar'
                                : row?.status === 'On the Move'
                                  ? 'greenbar'
                                  : ''
                          }`}
                          // className='redbar'
                        />
                        <Typography className='count d-flex-ja'>{i + 1}</Typography>
                        <Typography component={'div'} className='d-flex-ja'>
                          {row?.userImageId ? (
                            <img
                              className='d-avtaor d-flex-ja'
                              src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${row?.userImageId}`}
                            />
                          ) : (
                            <Typography className='d-avtaor d-flex-ja'>{`${row?.name?.charAt(
                              0,
                            )}`}</Typography>
                          )}
                        </Typography>
                        <Typography component={'div'} className='ml-8 d-flex-a flex-1'>
                          <Typography component={'div'} className='pr-8' sx={{ width: '80%' }}>
                            <Tooltip title={row?.name}>
                              <Typography className='ename'>{row?.name}</Typography>
                            </Tooltip>
                            <Typography className='edep'>{row?.description}</Typography>
                          </Typography>
                          <Typography className='eval text-right flex-1'>{row.score}</Typography>
                        </Typography>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </>
        )}

        {/* <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box red-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box blue-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box'>
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box'>
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box'>
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>

        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box red-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box blue-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box'>
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box red-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box red-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box red-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box blue-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box'>
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box red-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box red-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box red-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box blue-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box'>
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box red-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box red-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box red-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box blue-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box'>
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box red-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box red-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box red-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box blue-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box'>
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box'>
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box'>
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box red-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box blue-bg'>
            <Typography className='redbar' />
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box'>
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={2.4} sm={12} xs={12}>
          <Typography component={'div'} className='normal-box'>
            <Typography className='count d-flex-ja'>1</Typography>
            <Typography component={'div'} className='d-flex-ja'>
              <Typography className='d-avtaor d-flex-ja'>CB</Typography>
            </Typography>
            <Typography component={'div'} className='ml-8 d-flex-a' sx={{ width: '80%' }}>
              <Typography component={'div'} sx={{ width: '80%' }}>
                <Typography className='ename'>Carolien Bloeme</Typography>
                <Typography className='edep'>Department</Typography>
              </Typography>
              <Typography className='eval text-right flex-1'>1.8</Typography>
            </Typography>
          </Typography>
        </Grid> */}
      </Grid>
    </Typography>
  );
};

export default EmployeeEngagementList;
