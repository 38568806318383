import React from 'react';
import _ from 'lodash';
import PublicRoutes from 'routes/PublicRoutes';
import PrivateRoutes from 'routes/PrivateRoutes';
import AdminPrivateRoutes from 'routes/AdminRoutes';
import { useAppSelector } from 'store/hooks';

const AppRoutes = () => {
    
  const { userInfo: userDetail }: any = useAppSelector((store: any) => store.userLogin);
   
  if (!_.isEmpty(userDetail) && userDetail.isAdmin) {
    return <AdminPrivateRoutes />;
  } else if (!_.isEmpty(userDetail) && userDetail.isClient) {
    return <PrivateRoutes />;
  } else {
    return <PublicRoutes />;
  }
};

export default AppRoutes;
