import { Typography, IconButton, Menu, MenuItem, Grid, Tooltip as TooltipMUI } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ScreenLoader from 'components/ScreenLoader';
import fileDownload from 'js-file-download';
import jsPDF from 'jspdf';
import InfoIcon from 'assets/png/info.png';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import domtoimage from 'dom-to-image';
import * as R from 'ramda';
import { ResponsiveContainer, PieChart, Tooltip, Pie, Cell, Label } from 'recharts';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COLORS, DEI_DASHBOARD } from 'utils/Constant';
import Logo from 'assets/png/LogoDarkPng.png';
import { EyeToolTipTextOverAll } from 'utils/Config';

const DEI = () => {
  const downloadRef0: any = useRef(null);
  const downloadRef1: any = useRef(null);
  const downloadRef2: any = useRef(null);
  const downloadRef3: any = useRef(null);
  const { userInfo: userDetail, profileInfo }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });

  const [employeeEthnicityLoader, setEmployeeEthnicityLoader] = React.useState<boolean>(true);
  const [employeeEthnicityList, setEmployeeEthnicityList] = React.useState<any>([]);

  const [employeeGenderLoader, setEmployeeGenderLoader] = React.useState<boolean>(true);
  const [employeeGenderList, setEmployeeGenderList] = React.useState<any>([]);

  const [tenureRaceList, setTenureRaceList] = React.useState<any>([]);
  const [tenureRaceLoader, setTenureRaceLoader] = React.useState<boolean>(true);

  const [hiresRaceList, setHiresRaceList] = React.useState<any>([]);
  const [hiresRaceLoader, setHiresRaceLoader] = React.useState<boolean>(true);

  const [hideIcon, setHideIcon] = React.useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dwnldIndex, setDwnldIndex] = React.useState<any>({
    index: 0,
    name: '',
  });

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, idx: number, name: string) => {
    setAnchorEl(event.currentTarget);
    setDwnldIndex({
      index: idx,
      name: name,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDwnldIndex({
      index: 0,
      name: '',
    });
  };

  const getApiChart = async () => {
    getAllListData(`${DEI_DASHBOARD.EmployeeEthnicity}/${userDetail?.ClientId}/${userDetail?.Id}`)
      .then((resp: any) => {
        const result = R.sortWith([R.descend(R.compose(R.prop('count')))]);
        setEmployeeEthnicityList(result(resp?.data ? resp?.data : []));
      })
      .finally(() => {
        setEmployeeEthnicityLoader(false);
      });
    getAllListData(`${DEI_DASHBOARD.EmployeeGender}/${userDetail.ClientId}/${userDetail?.Id}`)
      .then((resp: any) => {
        const result = R.sortWith([R.descend(R.compose(R.prop('count')))]);
        setEmployeeGenderList(result(resp?.data ? resp?.data : []));
      })
      .finally(() => {
        setEmployeeGenderLoader(false);
      });
    getAllListData(`${DEI_DASHBOARD.HiresRace}/${userDetail.ClientId}/${userDetail?.Id}`)
      .then((resp: any) => {
        const result = R.sortWith([R.descend(R.compose(R.prop('hiresCount')))]);
        setHiresRaceList(result(resp?.data ? resp?.data : []));
      })
      .finally(() => {
        setHiresRaceLoader(false);
      });
    getAllListData(`${DEI_DASHBOARD.TenureRace}/${userDetail.ClientId}/${userDetail?.Id}`)
      .then((resp: any) => {
        const result = R.sortWith([R.descend(R.compose(R.prop('average')))]);
        setTenureRaceList(result(resp?.data ? resp?.data : []));
      })
      .finally(() => {
        setTenureRaceLoader(false);
      });
  };

  const CustomCurrentEmployeeTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'} sx={{ color: `${COLORS[payload[0].name]} !important` }}>
            {payload[0].payload.ethnicity}: {`${Math.round(payload[0].payload.percentage)}%`}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  const CustomHiresRaceTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'} sx={{ color: `${COLORS[payload[0].name]} !important` }}>
            {payload[0].payload.race} Hires: {Math.round(payload[0].payload.hiresCount)}
            /Applicants: {Math.round(payload[0].payload.applicantsCount)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };
  const CustomEmployeeGenderTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'} sx={{ color: `${COLORS[payload[0].name]} !important` }}>
            {payload[0].payload.gender === 'M'
              ? 'Male'
              : payload[0].payload.gender === 'F'
                ? 'Female'
                : payload[0].payload.gender}
            : {`${Math.round(payload[0].payload.percentage)}%`}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  const CustomTenureRaceTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'} sx={{ color: `${COLORS[payload[0].name]} !important` }}>
            {payload[0].payload.race}: {Math.round(payload[0].payload.average)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  const handleDownload = (type: string, name: string, index: number) => {
    setHideIcon(true);
    const refOption =
      index === 0
        ? downloadRef0.current
        : index === 1
          ? downloadRef1.current
          : index === 2
            ? downloadRef2.current
            : downloadRef3.current;
    if (type === 'img') {
      domtoimage.toBlob(refOption).then(function (blob) {
        fileDownload(
          blob,
          `${name}-${profileInfo?.clientName}-${moment().format('MM/DD/YYYY')}.png`,
        );
        setHideIcon(false);
      });
    } else {
      domtoimage.toBlob(refOption).then(function (blob) {
        const newImg = new Image();
        newImg.onload = function () {
          const height = newImg.height;
          const width = newImg.width;
          const LogoSrc: any = newImg.src;
          const doc = new jsPDF();
          doc.setFontSize(10);
          doc.text(`Downloaded Date: ${moment().format('MM-DD-YYYY')}`, 10, 10);
          doc.text(`Company Name: ${profileInfo?.clientName}`, 10, 18);
          doc.addImage(
            LogoSrc,
            'png',
            30,
            30,
            width / 4 > 150 ? 150 : width / 4,
            height / 4 > 287 ? 250 : height / 4,
          );
          const img = new Image();
          const src = Logo;
          img.src = src;
          doc.addImage(img, 'png', 100, 287, 40, 5);
          doc.setFontSize(10);
          doc.text('Powered by ', 80, 290);
          doc.save(`${name}-${profileInfo?.clientName}-${moment().format('MM/DD/YYYY')}.pdf`);
          setHideIcon(false);
        };
        const imgSrc = URL.createObjectURL(blob);
        newImg.src = imgSrc;
      });
    }
    handleClose();
  };

  const renderLegend = () => {
    return (
      <Typography className='legend-ul'>
        {/* <Typography className='leg-info'></Typography> */}
        {employeeEthnicityList.map((entry: any, index: any) => (
          <li key={`item-${index}`}>
            <Typography component={'div'} className='d-flex flex-sb'>
              <Typography className='d-flex'>
                <Typography component={'div'}>
                  <Typography
                    component={'div'}
                    sx={{ backgroundColor: `${COLORS[index]}` }}
                    className='legend-dot'
                  />
                </Typography>
                <Typography className='leg-info' sx={{ marginTop: '-5px' }}>
                  {entry?.ethnicity}
                </Typography>
              </Typography>
              <Typography className='num-info'>{entry?.percentage}%</Typography>
            </Typography>
          </li>
        ))}
      </Typography>
    );
  };

  const HiresLegend = () => {
    return (
      <ul className='legend-ul'>
        <li>
          <Typography component={'div'} className='d-flex-a flex-sb'>
            <Typography className='vp-title' />
            <Typography component={'div'} className='d-flex-a mb-15'>
              <Typography className='app-title mr-10'>Hires</Typography>
              <Typography className='app-info'>Applicants</Typography>
            </Typography>
          </Typography>
        </li>
        {hiresRaceList.map((entry: any, index: any) => (
          <li key={`item-${index}`}>
            <Typography component={'div'} className='d-flex flex-sb'>
              <Typography className='d-flex'>
                <Typography component={'div'}>
                  <Typography
                    component={'div'}
                    sx={{ backgroundColor: `${COLORS[index]}` }}
                    className='legend-dot'
                  />
                </Typography>
                <Typography className='leg-info' sx={{ marginTop: '-5px' }}>
                  {entry?.race}
                </Typography>
              </Typography>
              <Typography className='d-flex'>
                <Typography className='num-info mr-10' sx={{ textAlign: 'right', width: '43px' }}>
                  {entry?.hiresCount}
                </Typography>
                <Typography
                  className='app-info'
                  sx={{ marginTop: '-5px', textAlign: 'right', width: '75px' }}>
                  {entry?.applicantsCount}
                </Typography>
              </Typography>
            </Typography>
          </li>
        ))}
      </ul>
    );
  };
  const TenureLegend = () => {
    return (
      <Typography className='legend-ul'>
        {/* <Typography className='leg-info'></Typography> */}
        {tenureRaceList.map((entry: any, index: any) => (
          <li key={`item-${index}`}>
            <Typography component={'div'} className='d-flex flex-sb'>
              <Typography className=' d-flex'>
                <Typography component={'div'}>
                  <Typography
                    component={'div'}
                    sx={{ backgroundColor: `${COLORS[index]}` }}
                    className='legend-dot'
                  />
                </Typography>
                <Typography className='leg-info' sx={{ marginTop: '-5px' }}>
                  {entry?.race}
                </Typography>
              </Typography>
              <Typography className='num-info'>{entry?.average}</Typography>
            </Typography>
          </li>
        ))}
      </Typography>
    );
  };

  const LenderLegend = () => {
    return (
      <Typography className='legend-ul' sx={{ marginTop: '85px !important' }}>
        {/* <Typography className='leg-info'></Typography> */}
        {employeeGenderList.map((entry: any, index: any) => (
          <li key={`item-${index}`}>
            <Typography component={'div'} className='d-flex flex-sb'>
              <Typography className=' d-flex'>
                <Typography component={'div'}>
                  <Typography
                    component={'div'}
                    sx={{ backgroundColor: `${COLORS[index]}` }}
                    className='legend-dot'
                  />
                </Typography>
                <Typography className='leg-info' sx={{ marginTop: '-5px' }}>
                  {entry?.gender === 'M'
                    ? 'Male'
                    : entry?.gender === 'F'
                      ? 'Female'
                      : entry?.gender}
                </Typography>
              </Typography>
              <Typography className='num-info'>{entry.percentage}%</Typography>
            </Typography>
          </li>
        ))}
      </Typography>
    );
  };

  const CustomLabel = ({ viewBox, count }: any) => {
    let averageTotal = 0;
    if (count === 0 && employeeEthnicityList?.length) {
      const getPrice: any = R.prop('count');
      const sumPrices: any = R.reduce(R.add, 0);
      const averagePrice = sumPrices(R.map(getPrice, employeeEthnicityList));
      averageTotal = Math.round(averagePrice);
    } else if (count === 1 && hiresRaceList?.length) {
      const getPrice: any = R.prop('hiresCount');
      const sumPrices: any = R.reduce(R.add, 0);
      const averagePrice = sumPrices(R.map(getPrice, hiresRaceList));
      averageTotal = Math.round(averagePrice);
    } else if (count === 2 && employeeGenderList?.length) {
      const getPrice: any = R.prop('count');
      const sumPrices: any = R.reduce(R.add, 0);
      const averagePrice = sumPrices(R.map(getPrice, employeeGenderList));
      averageTotal = Math.round(averagePrice);
    } else if (count === 3 && tenureRaceList?.length) {
      const getPrice: any = R.prop('average');
      const sumPrices: any = R.reduce(R.add, 0);
      const averagePrice = sumPrices(R.map(getPrice, tenureRaceList));
      averageTotal = Math.round(averagePrice / tenureRaceList?.length);
    }

    return (
      <text
        x={viewBox.cx}
        y={viewBox.cy - 10}
        fill='#3d405c'
        style={{ outline: 'none !important' }}
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'>
        <tspan
          style={{
            outline: 'none !important',
          }}
          alignmentBaseline='middle'
          className={
            String(averageTotal).length > 5 ? 'total-pie total-pie-extra-text' : 'total-pie'
          }>
          {averageTotal}
        </tspan>
        <tspan
          style={{ outline: 'none !important' }}
          x={viewBox.cx}
          y={viewBox.cy + 20}
          className='ave-pie'>
          {count === 3 ? 'Average' : 'Total'}
        </tspan>
      </text>
    );
  };

  useEffect(() => {
    getApiChart();
  }, []);

  return (
    <Grid container>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={() => handleDownload('img', dwnldIndex.name, dwnldIndex.index)}>
          Image
        </MenuItem>
        <MenuItem onClick={() => handleDownload('pdf', dwnldIndex.name, dwnldIndex.index)}>
          PDF
        </MenuItem>
      </Menu>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='ov-box'>
          <Grid container columnSpacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography ref={downloadRef0} component={'div'} className='pie-box'
                  sx={{ minHeight: '371px !important' }}>
                {!hideIcon && employeeEthnicityList.length ? (
                  <Typography className='down-box d-flex-ja'>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e: any) => handleClick(e, 0, 'Employees by Ethnicity')}>
                      <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography />
                )}
                <Typography className='c-title d-flex-a'>
                  Employees by Ethnicity
                  {!hideIcon && (
                    <TooltipMUI
                      title={
                        <Typography className='tooltip-text'>
                          {EyeToolTipTextOverAll.DEI.employeesbyEthnicity}
                        </Typography>
                      }>
                      <img className='info-im' src={InfoIcon} alt='info' />
                    </TooltipMUI>
                  )}
                </Typography>
                {employeeEthnicityLoader ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={employeeEthnicityLoader} />
                  </Typography>
                ) : !employeeEthnicityList.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '250px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : (
                  <Typography component={'div'} className='d-flex'>
                    <Typography component={'div'} sx={{ width: '175px', height: 250 }}>
                      <ResponsiveContainer width={'100%'} height={'100%'}>
                        <PieChart>
                          <Tooltip content={<CustomCurrentEmployeeTooltip />} />
                          <Pie
                            data={employeeEthnicityList}
                            cx='50%'
                            cy='50%'
                            innerRadius={60}
                            outerRadius={80}
                            fill='#8884d8'
                            paddingAngle={1}
                            dataKey='percentage'>
                            {employeeEthnicityList.map((entry: any, index: number) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                            <Label
                              width={30}
                              position='center'
                              content={<CustomLabel count={0} y={130} />}></Label>
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </Typography>
                    <Typography component={'div'} className='flex-1'>
                      {renderLegend()}
                    </Typography>
                  </Typography>
                )}
              </Typography>
              <Typography ref={downloadRef1} component={'div'} className='pie-box'>
                {!hideIcon && hiresRaceList.length ? (
                  <Typography className='down-box d-flex-ja'>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e: any) => handleClick(e, 1, 'Hires by Ethnicity')}>
                      <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography />
                )}
                <Typography className='c-title d-flex-a'>
                  Hires by Ethnicity
                  {!hideIcon && (
                    <TooltipMUI
                      title={
                        <Typography className='tooltip-text'>
                          {EyeToolTipTextOverAll.DEI.hiresbyEthnicity}
                        </Typography>
                      }>
                      <img className='info-im' src={InfoIcon} alt='info' />
                    </TooltipMUI>
                  )}
                </Typography>
                {hiresRaceLoader ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={hiresRaceLoader} />
                  </Typography>
                ) : !hiresRaceList.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '250px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : (
                  <Typography component={'div'} className='d-flex'>
                    <Typography component={'div'} sx={{ width: '175px', height: 250 }}>
                      <ResponsiveContainer width={'100%'} height={'100%'}>
                        <PieChart>
                          <Tooltip content={<CustomHiresRaceTooltip />} />
                          <Pie
                            data={hiresRaceList}
                            cx='50%'
                            cy='50%'
                            innerRadius={60}
                            outerRadius={80}
                            fill='#8884d8'
                            paddingAngle={1}
                            dataKey='hiresCount'>
                            {hiresRaceList.map((entry: any, index: number) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                            <Label
                              width={30}
                              position='center'
                              content={<CustomLabel count={1} y={130} />}></Label>
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </Typography>
                    <Typography component={'div'} className='flex-1'>
                      {HiresLegend()}
                    </Typography>
                  </Typography>
                )}
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography
                ref={downloadRef2}
                component={'div'}
                className='pie-box'
                sx={{ minHeight: '371px !important' }}>
                {!hideIcon && employeeGenderList.length ? (
                  <Typography className='down-box d-flex-ja'>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e: any) => handleClick(e, 2, 'Employee by Sex')}>
                      <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography />
                )}
                <Typography className='c-title d-flex-a'>
                  Employees by Sex
                  {!hideIcon && (
                    <TooltipMUI
                      title={
                        <Typography className='tooltip-text'>
                          {EyeToolTipTextOverAll.DEI.employeebySex}
                        </Typography>
                      }>
                      <img className='info-im' src={InfoIcon} alt='info' />
                    </TooltipMUI>
                  )}
                </Typography>
                {employeeGenderLoader ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={employeeGenderLoader} />
                  </Typography>
                ) : !employeeGenderList.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '250px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : (
                  <Typography component={'div'} className='d-flex'>
                    <Typography component={'div'} sx={{ width: '175px', height: 250 }}>
                      <ResponsiveContainer width={'100%'} height={'100%'}>
                        <PieChart>
                          <Tooltip content={<CustomEmployeeGenderTooltip />} />
                          <Pie
                            data={employeeGenderList}
                            cx='50%'
                            cy='50%'
                            innerRadius={60}
                            outerRadius={80}
                            fill='#8884d8'
                            paddingAngle={1}
                            dataKey='percentage'>
                            {employeeGenderList.map((entry: any, index: number) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                            <Label
                              width={30}
                              position='center'
                              content={<CustomLabel count={2} y={130} />}></Label>
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </Typography>
                    <Typography component={'div'} className='flex-1'>
                      {LenderLegend()}
                    </Typography>
                  </Typography>
                )}
              </Typography>
              <Typography ref={downloadRef3} component={'div'} className='pie-box'>
                {!hideIcon && tenureRaceList.length ? (
                  <Typography className='down-box d-flex-ja'>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e: any) => handleClick(e, 3, 'Tenure by Ethnicity')}>
                      <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography />
                )}
                <Typography className='c-title d-flex-a'>
                  Tenure by Ethnicity
                  {!hideIcon && (
                    <TooltipMUI
                      title={
                        <Typography className='tooltip-text'>
                          {EyeToolTipTextOverAll.DEI.tenurebyEthnicity}
                        </Typography>
                      }>
                      <img className='info-im' src={InfoIcon} alt='info' />
                    </TooltipMUI>
                  )}
                </Typography>
                {tenureRaceLoader ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={tenureRaceLoader} />
                  </Typography>
                ) : !tenureRaceList.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '250px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : (
                  <Typography component={'div'} className='d-flex'>
                    <Typography component={'div'} sx={{ width: '175px', height: 250 }}>
                      <ResponsiveContainer width={'100%'} height={'100%'}>
                        <PieChart>
                          <Tooltip content={<CustomTenureRaceTooltip />} />
                          <Pie
                            data={tenureRaceList}
                            cx='50%'
                            cy='50%'
                            innerRadius={60}
                            outerRadius={80}
                            fill='#8884d8'
                            paddingAngle={1}
                            dataKey='average'>
                            {tenureRaceList.map((entry: any, index: number) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                            <Label
                              width={30}
                              position='center'
                              content={<CustomLabel count={3} y={130} />}></Label>
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </Typography>
                    <Typography component={'div'} className='flex-1'>
                      {TenureLegend()}
                    </Typography>
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DEI;
