import { Box, Button, Modal, Tooltip, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { addNewData, updateData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import moment from 'moment';
import { useAppSelector } from 'store/hooks';
import { useDispatch } from 'react-redux';
import { USER_REFRESH_TABLE } from 'store/types/UserTypes';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { EmployeeEdit } from 'utils/ValidationForm';
import { EditEmployeeSchema } from 'utils/ValidatorSchema';
import CustomSelect from 'components/customSelect';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';

interface PropsData {
  visible: boolean;
  handleClose: () => void;
  editObject?: any;
  roleList?: any;
  status?: string;
  from?: string;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  minHeight: '168px',
  width: '414px',
  borderRadius: ' 8px',
  boxShadow: ' 0 5px 14px 0 rgba(0,0,0,0.22)',
  padding: '28px 30px',
};

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  minHeight: '168px',
  width: '414px',
  borderRadius: ' 8px',
  boxShadow: ' 0 5px 14px 0 rgba(0,0,0,0.22)',
  padding: '28px 30px',
};

const EmployeeHandleOption = (props: PropsData) => {
  const dispatch: any = useDispatch();
  const { visible, handleClose, editObject, roleList, status, from } = props;
  const userDetail: any = useAppSelector((store: any) => {
    return store.userLogin.userInfo;
  });
  const [disabledSelect, setDisableSelect] = React.useState<boolean>(false);
  const [uploadImage, setUploadImage] = React.useState<any>({});
  const [localImage, setLocalImage] = React.useState<any>('');

  const closeEvent = () => {
    handleClose();
    setLoader(false);
    reset();
    setUploadImage({});
    setLocalImage('');
  };

  useEffect(() => {
    if (visible && status === 'invite') {
      setValue('role', editObject.roleId);
      setDisableSelect(editObject.roleId ? true : false);
    } else if (visible && status === 'edit') {
      if (from === 'admin') {
        setValue('role', editObject.roleId ? editObject.roleId : '1');
        setDisableSelect(true);
      } else {
        setDisableSelect(false);
        setValue('role', editObject?.roleIdNew ? editObject?.roleIdNew : editObject.roleId);
      }
    }
  }, [visible, status, from]);

  const {
    control,
    formState,
    setValue,
    handleSubmit,
    reset,
    // watch
  } = useForm<EmployeeEdit>({
    defaultValues: {
      role: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(EditEmployeeSchema),
  });

  const deactiveEmployee = () => {
    const data = {
      clientID: editObject.clientID,
      employeeId: editObject.id,
      employeeStatus: 'Deactivated',
      updatedBy: userDetail.id,
      updatedOn: moment(),
    };
    updateData('', data, `${MASTER_ENDPOINT.UpdateEmployee}`, true)
      .then(() => {
        closeEvent();
        dispatch({
          type: USER_REFRESH_TABLE,
        });
      })
      .finally(() => {});
  };

  const inviteUser = () => {
    addNewData(
      {},
      `${MASTER_ENDPOINT.ClientLogin}?toEmailAddress=${editObject['workEmail']}&clientId=${editObject.clientID}`,
      {},
      true,
    )
      .then(() => {
        dispatch({
          type: USER_REFRESH_TABLE,
        });
        closeEvent();
      })
      .finally(() => {});
  };

  const [loader, setLoader] = React.useState(false);

  const updateRole = (data: any) => {
    setLoader(true);
    if (status === 'invite') {
      let postJson = {};
      if (!editObject.roleId) {
        postJson = {
          employeeId: editObject.id,
          roleId: parseInt(data['role']),
          createdBy: parseInt(userDetail.Id),
        };
      }
      addNewData(
        postJson,
        `${MASTER_ENDPOINT.ClientLogin}?toEmailAddress=${editObject['workEmail']}&clientId=${editObject.clientID}`,
        {},
        true,
      )
        .then(async () => {
          if (localImage && localImage?.length) {
            const formData2 = new FormData();
            if (uploadImage) {
              formData2.append('files', uploadImage);
            }
            formData2.append('UserId', editObject?.workEmail);
            if (editObject.clientID) {
              formData2.append('ClientId', editObject.clientID);
            } else {
              formData2.append('ClientId', `${0}`);
            }
            editObject?.imageId
              ? formData2.append('ImageId', editObject?.imageId)
              : formData2.append('ImageId', '');
            formData2.append('CreatedBy', userDetail.Id);
            formData2.append('CreatedOn', moment().format());
            formData2.append('UpdatedBy', userDetail.Id);
            formData2.append('UpdatedOn', moment().format());

            await updateData('', formData2, `${MASTER_ENDPOINT.UploadPicture}`, true, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
          }
          closeEvent();
          dispatch({
            type: USER_REFRESH_TABLE,
          });
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      const employeRoleData = {
        employeeId: editObject.id,
        roleId: data['role'] ? parseInt(data['role']) : 0,
        updatedBy: parseInt(userDetail.Id),
        updatedOn: moment(),
      };
      updateData('', employeRoleData, `${MASTER_ENDPOINT.UpdateEmployeeRole}`, true)
        .then(async () => {
          if (localImage && localImage?.length) {
            const formData2 = new FormData();
            if (uploadImage) {
              formData2.append('files', uploadImage);
            }
            formData2.append('UserId', editObject?.workEmail);
            if (editObject.clientID) {
              formData2.append('ClientId', editObject.clientID);
            } else {
              formData2.append('ClientId', `${0}`);
            }
            editObject?.imageId
              ? formData2.append('ImageId', editObject?.imageId)
              : formData2.append('ImageId', '');
            formData2.append('CreatedBy', userDetail.Id);
            formData2.append('CreatedOn', moment().format());
            formData2.append('UpdatedBy', userDetail.Id);
            formData2.append('UpdatedOn', moment().format());

            await updateData('', formData2, `${MASTER_ENDPOINT.UploadPicture}`, true, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
          }
          closeEvent();
          dispatch({
            type: USER_REFRESH_TABLE,
          });
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const updatePhoto = (e: any) => {
    const imageFormatCheck = e?.target?.files[0]?.name.split('.');
    const formatCheck = ['tiff', 'jpeg', 'jpg', 'png'].includes(
      imageFormatCheck[imageFormatCheck.length - 1].toLowerCase(),
    );
    const maxSizeInMB = 10;
    const fileSizeInMB = e?.target?.files[0].size / 1024 / 1024;
    if (fileSizeInMB > maxSizeInMB) {
      toast.error('File size should not exceed 10MB.');
    } else if (formatCheck) {
      setUploadImage(e?.target?.files[0]);
      setLocalImage(URL.createObjectURL(e?.target?.files[0]));
    } else {
      toast.error('Upload valid images only PNG, JPEG, JPG and TIFF are allowed');
    }
  };

  if (visible) {
    if (status === 'reinvite' || status === 'deactive') {
      return (
        <Modal
          open={visible}
          onClose={closeEvent}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box component='div' sx={style}>
            <Typography className='d-flex-a flex-sb'>
              <Typography className='modal-title'>
                {status === 'deactive' ? 'DEACTIVATE USER' : 'Invite USER'}
              </Typography>
            </Typography>
            <Typography className='modal-dis'>
              {status === 'deactive'
                ? 'Are you sure you want to deactivate this user?'
                : 'Are you sure you want to Invite this user?'}
            </Typography>
            <Typography component={'div'} className='d-flex-a pt-15' columnGap='5px'>
              <Button className='modal-no-btn' onClick={closeEvent}>
                NO, CANCEL
              </Button>
              <Button
                className={status === 'deactive' ? 'modal-yes-btn' : 'modal-yes1-btn'}
                onClick={() => (status === 'deactive' ? deactiveEmployee() : inviteUser())}>
                {status === 'deactive' ? 'YES, DEACTIVATE' : 'YES, Invite'}
              </Button>
            </Typography>
          </Box>
        </Modal>
      );
    } else if (status === 'invite' || status === 'edit') {
      return (
        <Modal
          open={visible}
          onClose={closeEvent}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box component='div' sx={style1}>
            <Typography className='d-flex-a flex-sb'>
              <Typography className='modal-title'>
                {from === 'admin' && status === 'invite'
                  ? 'Invite Client'
                  : status === 'invite'
                    ? 'Invite User'
                    : 'EDIT USER'}
              </Typography>
              <CloseIcon className='closesvg' onClick={closeEvent} />
            </Typography>
            <Typography className='modal-dis'>
              {status === 'invite'
                ? 'Invite a client employee to join the platform'
                : 'Change role and save or remove access for this user to the system'}
            </Typography>
            <Typography component={'div'} sx={{ display: 'flex', marginBottom: '14px' }}>
              <Typography component={'div'}>
                <Typography className='pop-default d-flex-ja' sx={{ textTransform: 'capitalize' }}>
                  {localImage ? (
                    <img className='pop-default2 d-flex-ja' src={localImage} />
                  ) : editObject?.imageId ? (
                    <img
                      className='pop-default2 d-flex-ja'
                      src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${editObject?.imageId}`}
                    />
                  ) : (
                    editObject?.firstName?.[0]
                  )}
                </Typography>
                <Typography className='d-flex-ja'>
                  <Button component='label'>
                    <span className='add-photo'>
                      <input
                        hidden
                        type='file'
                        accept='image/*'
                        onChange={(e) => updatePhoto(e)}
                        onClick={(event: any) => {
                          event.target.value = null;
                        }}
                      />
                      + Photo
                    </span>
                  </Button>
                  <span className='add-photo' style={{ marginTop: '10px !important' }}>
                    <Tooltip
                      title={
                        <Typography className='tooltip-text'>
                          Maximum file size should be 10 MB
                        </Typography>
                      }
                      arrow
                      placement='bottom-start'>
                      <InfoIcon fontSize='small' />
                    </Tooltip>
                  </span>
                </Typography>
              </Typography>
              <Typography component={'div'} className='pl-8'>
                <Typography className='modal-name mt-10'>{editObject?.employeeName}</Typography>
                <Typography className='modal-gmail'>{editObject?.workEmail}</Typography>
              </Typography>
            </Typography>
            <form className='private-form'>
              <Typography component={'div'} className='custom-field'>
                {from === 'admin' ? (
                  <Typography component={'p'}>Select Role*</Typography>
                ) : (
                  <Typography component={'p'}>Select Role</Typography>
                )}
                <Controller
                  control={control}
                  defaultValue=''
                  name='role'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        id='outlined-basic'
                        placeHolder={'Select Role'}
                        name={name}
                        value={value}
                        labelKey={'roleName'}
                        isDefault
                        valueKey={'id'}
                        disabled={disabledSelect}
                        // disabled={from === 'admin' ? true : status === 'invite' ? true : false}
                        options={
                          from === 'admin' && disabledSelect === false
                            ? roleList.filter((item: any) => item.clientID === 0)
                            : roleList
                        }
                        error={formState.errors.role?.message}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </Typography>
              <Typography component={'span'} className='warn-text'>
                Warning: This role provides access to compensation data
              </Typography>
              <LoadingButton
                className='submit-btn'
                // disabled={watch('role') ? false : true}
                onClick={handleSubmit(updateRole)}
                loading={loader}
                loadingPosition='center'>
                {from === 'admin' && status === 'invite'
                  ? editObject.roleId
                    ? 'INVITE Client'
                    : 'Save & INVITE Client'
                  : status === 'invite'
                    ? editObject.roleId
                      ? 'INVITE Employee'
                      : 'Save & INVITE Employee'
                    : 'Save Changes'}
              </LoadingButton>
            </form>
          </Box>
        </Modal>
      );
    }
  }
  return <Typography />;
};

export default EmployeeHandleOption;
