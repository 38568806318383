import {
  ROLE_MASTER,
  SIDE_MENUS,
  SIDE_CLASSES,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_PROFILE,
  USER_REFRESH_TABLE,
  USER_REFRESH_TOKEN,
  ADMIN_PROFILE,
} from '../types/UserTypes';
import Storage from 'utils/Storage';

const user = Storage.isTokenValidDetails();
export interface UserState {
  loading?: boolean;
  userInfo?: any;
  isLoggedIn?: boolean;
  tableReload?: boolean;
  menuList?: any;
  classList?: any;
  profileInfo?: any;
  adminProfile?: any;
  roleMasterData?: any;
}

export interface Action {
  type: string;
  payload?: string;
}

const INITIAL_STATE: UserState = {
  isLoggedIn: Storage.isTokenValidDetails() ? true : false,
  loading: false,
  userInfo: Storage.isTokenValidDetails() ? Storage.isTokenValidDetails() : null,
  tableReload: true,
  menuList: Storage.getMenus() ? Storage.getMenus() : [],
  classList: Storage.getClasses() ? Storage.getClasses() : [],
  profileInfo: Storage.getProfile() ? Storage.getProfile() : {},
  adminProfile: Storage.getAdminProfile() ? Storage.getAdminProfile() : {},
  roleMasterData: Storage.getRoleMaster() ? Storage.getRoleMaster() : [],
};

export const userLoginReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case USER_LOGOUT:
      return {
        isLoggedIn: false,
        loading: false,
        userInfo: {},
        adminprofile: {},
        menuList: [],
        classList: [],
        profileInfo: {},
        roleMasterData: [],
      };
    case ROLE_MASTER:
      return {
        ...state,
        roleMasterData: action.payload,
      };
    case USER_PROFILE:
      return {
        ...state,
        profileInfo: action.payload,
      };
    case ADMIN_PROFILE:
      return {
        ...state,
        adminProfile: action.payload,
      };
    case SIDE_MENUS:
      return {
        ...state,
        menuList: action.payload,
      };
    case SIDE_CLASSES:
      return {
        ...state,
        classList: action.payload,
      };
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
        isLoggedIn: true,
        loading: false,
        userInfo: action.payload,
      };
    case USER_LOGIN_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoggedIn: false,
        loading: false,
        userInfo: null,
      };
    case USER_REFRESH_TOKEN:
      return {
        ...state,
        userInfo: { ...user, token: action.payload },
      };
    case USER_REFRESH_TABLE:
      return {
        ...state,
        tableReload: !state.tableReload,
      };
    default:
      return state;
  }
};
