import  React from 'react';

interface IFilterDataContextType {
    filterData: any,
    setFilterData: (data:any) => void;
}

const defaultSearchData = {
    filterData: { },
    setFilterData: function(data: any){ console.log(data)}
}

const FilterDataContext = React.createContext<IFilterDataContextType>(defaultSearchData);

export default FilterDataContext;