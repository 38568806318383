import React from 'react';
import { Navigate, Route, Routes, To, useNavigate } from 'react-router-dom';
import { PrivateRoutes as PrivateRoutesDirectives, PublicRoutes } from './routeConstans';
import Dashboard from 'pages/private/dashboard';
import PrivateLayout from 'pages/private';
import Oraganization from 'pages/private/organization';
import EmployeeEgage from 'pages/private/employeeengagment';
import EmployeeEgageList from 'pages/private/employeeengagment/list';
import Performance from 'pages/private/performance';
import Accessmanagement from 'pages/private/accessmanagement';
import Recruiting from 'pages/private/recruiting';
import Dei from 'pages/private/dei';
import LearnDevlopment from 'pages/private/learndevelopment';
import Reports from 'pages/private/reports';
import Settings from 'pages/private/settings';
import RolesandPermissions from 'pages/private/accessmanagement/rolesandpermissions';
import { logout } from 'store/actions/UserAction';
import { toast } from 'react-toastify';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { alertOptions } from 'utils/Config';
import { useAppSelector } from 'store/hooks';
import EmployeeSearchContextProvider from 'context/EmployeeSearchContextProvider';

const PrivateRoutes = () => {
  const dispatch: any = useDispatch();
  const {
    DASHBOARD,
    ORGANIZATION,
    EMPLOYEEENGAGE,
    EMPLOYEEENGAGELIST,
    PERFORMANCE,
    ACCESSMANAGEMENT,
    RECRUITING,
    SETTINGS,
    DEI,
    REPORTS,
    LEARNDEVLOPMENT,
  } = PrivateRoutesDirectives;

  const { userInfo: userDetail }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });

  const navigate = useNavigate();
  const gotoNavigate = (link: To) => {
    navigate(link);
  };
  const handleIdle = () => {
    logout(dispatch);
    if (userDetail?.isImpersonate) {
      setTimeout(() => gotoNavigate(PublicRoutes.ADMINLOGIN), 0);
    }
    toast.error('Session expired! Please login again.', alertOptions);
  };
  useIdleTimer({
    timeout: 3600000,
    onIdle: handleIdle,
    debounce: 500,
  });
  return (
    <Routes>
      <Route path='/' element={<Navigate to={DASHBOARD} replace />} />
      <Route
        path={DASHBOARD}
        element={
          <PrivateLayout path={DASHBOARD} title={'DashBoard'} header={true}>
            <Dashboard />
          </PrivateLayout>
        }
      />
      <Route
        path={ORGANIZATION}
        element={
          <PrivateLayout path={ORGANIZATION} title={'Organizational Overview'} header={true}>
            <Oraganization />
          </PrivateLayout>
        }
      />
      <Route
        path={EMPLOYEEENGAGE}
        element={
          <EmployeeSearchContextProvider>
            <PrivateLayout path={EMPLOYEEENGAGE} title={'Employee Engagement'} header={true}>
              <EmployeeEgage />
            </PrivateLayout>
          </EmployeeSearchContextProvider>
        }
      />
      <Route
        path={EMPLOYEEENGAGELIST}
        element={
          <PrivateLayout path={EMPLOYEEENGAGELIST} title={'Employee Engagement'} header={true}>
            <EmployeeEgageList />
          </PrivateLayout>
        }
      />
      <Route
        path={RECRUITING}
        element={
          <PrivateLayout path={RECRUITING} title={'Recruiting'} header={true}>
            <Recruiting />
          </PrivateLayout>
        }
      />
      <Route
        path={PERFORMANCE}
        element={
          <PrivateLayout path={PERFORMANCE} title={'Performance'} header={true}>
            <Performance />
          </PrivateLayout>
        }
      />
      <Route
        path={DEI}
        element={
          <PrivateLayout path={DEI} title={'Diversity, Equity & Inclusion'} header={true}>
            <Dei />
          </PrivateLayout>
        }
      />
      <Route
        path={SETTINGS}
        element={
          <PrivateLayout path={SETTINGS} header={false}>
            <Settings />
          </PrivateLayout>
        }
      />
      <Route
        path={LEARNDEVLOPMENT}
        element={
          <PrivateLayout path={LEARNDEVLOPMENT} title={'Learning & Development'} header={true}>
            <LearnDevlopment />
          </PrivateLayout>
        }
      />
      <Route
        path={REPORTS}
        element={
          <PrivateLayout path={REPORTS} title={'Reports'} header={true}>
            <Reports />
          </PrivateLayout>
        }
      />
      <Route
        path={ACCESSMANAGEMENT.EMPLOYEES}
        element={
          <PrivateLayout
            path={ACCESSMANAGEMENT.EMPLOYEES}
            title={'Access Management'}
            header={true}>
            <Accessmanagement />
          </PrivateLayout>
        }
      />
      <Route
        path={ACCESSMANAGEMENT.ROLESPERMISSIONS}
        element={
          <PrivateLayout
            path={ACCESSMANAGEMENT.ROLESPERMISSIONS}
            title={'Access Management'}
            header={true}>
            <RolesandPermissions />
          </PrivateLayout>
        }
      />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default PrivateRoutes;
