import React, { useEffect } from 'react';
import BackImg from 'assets/png/loginbg.jpg';
import Logo from 'assets/png/LogoDark.svg';
import { Grid, TextField, Typography } from '@mui/material';
import { ForgotPasswordForm } from 'utils/ValidationForm';
import { ForgotPasswordSchema } from 'utils/ValidatorSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { addNewData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import LoadingButton from '@mui/lab/LoadingButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { PublicRoutes } from 'routes/routeConstans';
import CustomSelect from 'components/customSelect';

const ForgotPassword = ({ from }: any) => {
  const navigate: any = useNavigate();
  const location: any = useLocation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { handleSubmit, control, formState, setValue, trigger } = useForm<ForgotPasswordForm>({
    defaultValues: {
      email: '',
      clientId: '',
      from: from === 'admin' ? true : false,
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const goBackOption = () => {
    if (from === 'client') {
      navigate(PublicRoutes.CLIENTLOGIN);
    } else {
      navigate(PublicRoutes.ADMINLOGIN);
    }
  };

  useEffect(() => {
    if (from === 'client') {
      setValue('email', location?.state?.email);
    }
  }, [from, location?.state]);

  const submitForm = (data: ForgotPasswordForm) => {
    setLoading(true);
    addNewData(
      {},
      `${MASTER_ENDPOINT.ResetPassword}?toEmailAddress=${data['email']}&isFromSuperAdmin=${
        from === 'admin' ? true : false
      }&clientId=${data['clientId'] ? data['clientId'] : 0}`,
      {},
      true,
    )
      .then(() => {
        from === 'admin'
          ? navigate(PublicRoutes.FORGOTPASSWORDSUCCESSADMIN, { state: data })
          : navigate(PublicRoutes.FORGOTPASSWORDSUCCESSCLIENT, { state: data });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          component={'div'}
          sx={{ backgroundImage: `url(${BackImg})` }}
          className='login-banner'>
          <Typography component={'div'} className='login-box'>
            <Typography className='logo-text d-flex-a'>
              <img src={Logo} alt='simplifiedHR' />
            </Typography>
            <form id='login-form' onSubmit={handleSubmit(submitForm)}>
              <Typography component={'p'} className='forgot-pass-heading'>
                Forgot Password
              </Typography>
              <Typography component={'p'} className='forgot-pass-description'>
                Enter your email address below and submit to receive an reset password link in your
                email account.
              </Typography>
              {from === 'client' && (
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>Select Company*</Typography>
                  <Controller
                    control={control}
                    defaultValue=''
                    name='clientId'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomSelect
                          id='outlined-basic'
                          placeHolder={'Select'}
                          name={name}
                          value={value}
                          labelKey={'clientName'}
                          valueKey={'clientID'}
                          options={location?.state?.companyList ? location?.state?.companyList : []}
                          error={formState.errors.clientId?.message}
                          onChange={onChange}
                        />
                      );
                    }}
                  />
                </Typography>
              )}
              <Typography component={'div'} className='custom-field' sx={{ paddingTop: '10px' }}>
                <Typography component={'p'}>Email*</Typography>
                <Controller
                  control={control}
                  defaultValue=''
                  name='email'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <TextField
                        className='custom-input'
                        id='standard-basic'
                        placeholder='Enter Email'
                        variant='standard'
                        onChange={(e) => {
                          const vals = e.target.value.replace(
                            /[^a-zA-Z0-9!#$%&'*+-/=?^_`{|}~(),:;<>@[\]]/g,
                            '',
                          );
                          onChange(vals);
                          trigger('email');
                        }}
                        disabled={from === 'client'}
                        value={value}
                        name={name}
                        error={formState.errors.email?.message ? true : false}
                        helperText={formState.errors.email?.message}
                      />
                    );
                  }}
                />
              </Typography>
              <LoadingButton
                className='submit-btn'
                loading={loading}
                form='login-form'
                type='submit'
                loadingPosition='center'>
                Submit
              </LoadingButton>
              <Typography className='border-box' />
            </form>
            <Typography component={'p'} textAlign={'center'}>
              <Typography
                component={'span'}
                className='back-to-login'
                onClick={() => goBackOption()}>
                Cancel, Back to Login
              </Typography>
            </Typography>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
