export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_TOKEN = 'USER_TOKEN';
export const USER_REFRESH_TOKEN = 'USER_REFRESH_TOKEN';
export const USER_REFRESH_TABLE = 'USER_REFRESH_TABLE';
export const SIDE_MENUS = 'SIDE_MENUS';
export const SIDE_CLASSES = 'SIDE_CLASSES';
export const ROLE_MASTER = 'ROLE_MASTER';
export const USER_PROFILE = 'USER_PROFILE';
export const ADMIN_PROFILE = 'ADMIN_PROFILE';
