import axios from 'axios';
import { useCustomNavigation } from 'hooks';
import { toast } from 'react-toastify';
import { store } from 'store';
import { logout } from 'store/actions/UserAction';
import { MASTER_ENDPOINT } from 'utils/Constant';
import Storage from 'utils/Storage';

const { dispatch }: any = store;

const instance = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config: any) => {
    const TokenStatus: any = Storage.getTokens();
    const token = TokenStatus && TokenStatus.token ? TokenStatus.token : '';
    if (token && !config.url.includes('RefreshToken')) {
      config.headers['Authorization'] = 'Bearer '.concat(token);
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (res: any) => {
    return res;
  },
  async (err: any) => {
    console.log(err);
    const originalConfig = err.config;
    const responseData = err.response.data;
    if (originalConfig.url !== 'login' && err.response) {
      // Access Token was expired
      if (err.response.status === 401) {
        originalConfig._retry = true;
        try {
          // setTimeout(async () => {
          const TokenStatus: any = Storage.getTokens();
          // await Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
          const rs = await instance.post(
            `${MASTER_ENDPOINT.RefreshToken}?token=${TokenStatus && TokenStatus.token ? TokenStatus.token : ''
            }`,
            {},
            { headers: { Authorization: '' } },
          );
          const { token } = rs.data;
          // dispatch(refreshToken(token));
          await Storage.updateAccessToken(token);
          return await instance(originalConfig);
          // }, 500);
        } catch (_error) {
          logout(dispatch);
          Storage.clear();
          useCustomNavigation('/login', { replace: true });
        }
      }
      if (err.response.status === 403) {
        toast.error('Your account has been deactivated. Please contact your Administrator.');
        logout(dispatch);
        Storage.clear();
        useCustomNavigation('/login', { replace: true });
      }
      if (responseData.error?.message && responseData.error?.message === 'Invalid Token!') {
        logout(dispatch);
        Storage.clear();
        useCustomNavigation('/login', { replace: true });
      }
    }
    return Promise.reject(err);
  },
);
export default instance;
