import { Grid, IconButton, Menu, MenuItem, Typography, Tooltip as TooltipMUI } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import InfoIcon from 'assets/png/info.png';
import React, { FunctionComponent, useEffect, useRef, useContext } from 'react';
import {
  PieChart,
  Pie,
  Label,
  Legend,
  Cell,
  ComposedChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { getAllListData } from 'services/CommonServices';
import { COLORS, ORGANIZTION_DASHBOARD } from 'utils/Constant';
import { useAppSelector } from 'store/hooks';
import ScreenLoader from 'components/ScreenLoader';
import moment from 'moment';
import * as R from 'ramda';
import { jsPDF } from 'jspdf';
import domtoimage from 'dom-to-image';
import fileDownload from 'js-file-download';
import Logo from 'assets/png/LogoDarkPng.png';
import { EyeToolTipTextOverAll, LastYearData } from 'utils/Config';
import FilterDataContext from 'context/filterDataContext';


const Oraganization = () => {
  const downloadRef0: any = useRef(null);
  const downloadRef1: any = useRef(null);
  const downloadRef2: any = useRef(null);
  const downloadRef3: any = useRef(null);
  const { userInfo: userDetail, profileInfo }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });
  const [ternuePopulationLoader, setTernuePopulationLoader] = React.useState<boolean>(true);
  const [ternuePopulationList, setTernuePopulationList] = React.useState<any>([]);

  const [headerCountTurnOverLoader, setHeaderCountTurnOverLoader] = React.useState<boolean>(true);
  const [headerCountTurnOverList, setHeaderCountTurnOverList] = React.useState<any>([]);

  const [turnOverCountLoader, setTurnOverCountLoader] = React.useState<boolean>(true);
  const [turnOverVoluntaryCountList, setTurnOverVoluntaryCountList] = React.useState<any>([]);
  const [turnOverVoluntaryCountNumber, setTurnOverVoluntaryCountNumber] = React.useState<number>(0);

  const [turnOveInrVoluntaryCountList, setTurnOverInVoluntaryCountList] = React.useState<any>([]);
  const [turnOverInVoluntaryCountNumber, setTurnOverInVoluntaryCountNumber] =
    React.useState<number>(0);

  const [hiredTerminationLoader, setHiredTerminationLoader] = React.useState<boolean>(true);
  const [hiredTerminationList, setHiredTerminationList] = React.useState<any>([]);

  const [hideIcon, setHideIcon] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dwnldIndex, setDwnldIndex] = React.useState<any>({
    index: 0,
    name: '',
  });
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, idx: number, name: string) => {
    setAnchorEl(event.currentTarget);
    setDwnldIndex({
      index: idx,
      name: name,
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
    setDwnldIndex({
      index: 0,
      name: '',
    });
  };

  const {filterData} = useContext(FilterDataContext);

  const getChartApi = async () => {
    getAllListData(
      `${ORGANIZTION_DASHBOARD.PopuationTernue}/${userDetail.ClientId}/${userDetail.Id}`,
    )
      .then((resp: any) => {
        const result = R.sortWith([R.descend(R.compose(R.prop('totalHire')))]);
        setTernuePopulationList(result(resp?.data ? resp?.data : []));
      })
      .finally(() => {
        setTernuePopulationLoader(false);
      });
    getAllListData(
      `${ORGANIZTION_DASHBOARD.HeadCountTurnOverRadio}/${userDetail.ClientId}/${moment().format(
        'MM-DD-YYYY',
      )}/${userDetail.Id}`,
    )
      .then((resp: any) => {
        setHeaderCountTurnOverList(resp?.data);
      })
      .finally(() => {
        setHeaderCountTurnOverLoader(false);
      });
    getAllListData(
      `${ORGANIZTION_DASHBOARD.TurnoverTermination}/${userDetail.ClientId}/${moment().format(
        'MM-DD-YYYY',
      )}/${userDetail?.Id}`,
    )
      .then((res: any) => {
        const hasVoluntry = R.propEq('Voluntary', 'termReasonType');
        const voluentryList = R.filter(hasVoluntry, res.data);
        if (voluentryList?.length) {
          const result = R.sortWith([R.descend(R.compose(R.prop('totalTermReason')))]);
          setTurnOverVoluntaryCountList(
            result(voluentryList?.[0]?.termReasons ? voluentryList?.[0]?.termReasons : []),
          );
          setTurnOverVoluntaryCountNumber(voluentryList?.[0]?.totalTermReasonType);
        }
        const hasInVoluntry = R.propEq('Involuntary', 'termReasonType');
        const inVoluentryList = R.filter(hasInVoluntry, res.data);
        if (inVoluentryList?.length) {
          const result = R.sortWith([R.descend(R.compose(R.prop('totalTermReason')))]);
          setTurnOverInVoluntaryCountList(
            result(inVoluentryList?.[0]?.termReasons ? inVoluentryList?.[0]?.termReasons : []),
          );
          setTurnOverInVoluntaryCountNumber(inVoluentryList?.[0]?.totalTermReasonType);
        }
      })
      .finally(() => {
        setTurnOverCountLoader(false);
      });
    getAllListData(
      `${ORGANIZTION_DASHBOARD.HiresAndTerms}/${userDetail.ClientId}/${moment().format(
        'MM-DD-YYYY',
      )}/${userDetail?.Id}`,
    )
      .then((resp: any) => {
        setHiredTerminationList(
          R.map((row: any) => {
            row['yearMonthName'] = moment(row.yearMonth).format('MMM');
            return { ...row };
          }, resp.data),
        );
      })
      .finally(() => {
        setHiredTerminationLoader(false);
      });
  };

  useEffect(() => {
    getChartApi();
  }, [filterData]);

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleDownload = (type: string, name: string, index: number) => {
    setHideIcon(true);
    const refOption =
      index === 0
        ? downloadRef0.current
        : index === 1
          ? downloadRef1.current
          : index === 2
            ? downloadRef2.current
            : downloadRef3.current;
    if (type === 'img') {
      domtoimage.toBlob(refOption).then(function (blob) {
        fileDownload(
          blob,
          `${name}-${profileInfo?.clientName}-${moment().format('MM/DD/YYYY')}.png`,
        );
        setHideIcon(false);
      });
    } else {
      domtoimage.toBlob(refOption).then(function (blob) {
        const newImg = new Image();
        newImg.onload = function () {
          const height = newImg.height;
          const width = newImg.width;
          const LogoSrc: any = newImg.src;
          const doc = new jsPDF();
          doc.setFontSize(10);
          doc.text(`Downloaded Date: ${moment().format('MM-DD-YYYY')}`, 10, 10);
          doc.text(`Company Name: ${profileInfo?.clientName}`, 10, 18);
          doc.addImage(
            LogoSrc,
            'png',
            30,
            30,
            width / 4 > 150 ? 150 : width / 4,
            height / 4 > 287 ? 250 : height / 4,
          );
          const img = new Image();
          const src = Logo;
          img.src = src;
          doc.addImage(img, 'png', 100, 287, 40, 5);
          doc.setFontSize(10);
          doc.text('Powered by ', 80, 290);
          doc.save(`${name}-${profileInfo?.clientName}-${moment().format('MM/DD/YYYY')}.pdf`);
          setHideIcon(false);
        };
        const imgSrc = URL.createObjectURL(blob);
        newImg.src = imgSrc;
      });
    }
    handleClose();
  };

  const renderLegend = () => {
    return (
      <Typography className='legend-ul'>
        {/* <Typography className='leg-info'>Top 10 Categories</Typography> */}
        {ternuePopulationList.map((entry: any, index: any) => (
          <Typography key={`item-${index}`}>
            <Typography component={'div'} className='d-flex-a flex-sb'>
              <li className='leg-info d-flex-a' style={{ width: '100%' }}>
                <Typography
                  component={'div'}
                  sx={{ backgroundColor: `${COLORS[index]}`, width: '100%' }}
                  className='legend-dot'
                />
                {entry.hireDurationCategory}
              </li>
              <Typography className='leg-info'>{entry.totalHire}</Typography>
            </Typography>
          </Typography>
        ))}
      </Typography>
    );
  };

  const HRmonthLegend = () => {
    return (
      <Typography component={'div'} className='d-flex-a'>
        <Typography component={'div'} className='d-flex-a hr-text mr-10'>
          <Typography className='s-dot' sx={{ backgroundColor: '#344A5C' }} />
          New Hires
        </Typography>
        <Typography component={'div'} className='d-flex-a hr-text'>
          <Typography className='s-dot' sx={{ backgroundColor: '#C3D34F' }} />
          Terms
        </Typography>
      </Typography>
    );
  };
  const HRLegend = () => {
    return (
      <Typography component={'div'} className='d-flex-a'>
        <Typography component={'div'} className='d-flex-a hr-text mr-10'>
          <Typography className='s-dot' sx={{ backgroundColor: '#344A5C' }} />
          Headcount
        </Typography>
        <Typography component={'div'} className='d-flex-a hr-text'>
          <Typography className='s-dot' sx={{ backgroundColor: '#C3D34F' }} />
          Turnover
        </Typography>
      </Typography>
    );
  };
  const VoluntaryLegend = () => {
    return (
      <ul className='legend-ul'>
        <li>
          <Typography component={'div'} className='d-flex-a flex-sb'>
            <Typography className='vp-title'>Voluntary Term Reasons</Typography>
            <Typography className='vp-title'>{turnOverVoluntaryCountNumber}</Typography>
          </Typography>
        </li>
        {turnOverVoluntaryCountList.map((entry: any, index: any) => (
          <li key={`item-${index}`}>
            <Typography component={'div'} className='d-flex flex-sb'>
              <Typography className=' d-flex'>
                <Typography component={'div'}>
                  <Typography
                    component={'div'}
                    sx={{ backgroundColor: `${COLORS[index]}` }}
                    className='legend-dot'
                  />
                </Typography>
                <Typography className='leg-info' sx={{ marginTop: '-5px' }}>
                  {entry?.termReason}
                </Typography>
              </Typography>
              <Typography className='num-info'>{entry?.totalTermReason}</Typography>
            </Typography>
          </li>
        ))}
      </ul>
    );
  };

  const InvoluntaryLegend = () => {
    return (
      <ul className='legend-ul'>
        <li>
          <Typography component={'div'} className='d-flex-a flex-sb'>
            <Typography className='vp-title'>Involuntary Term Reasons</Typography>
            <Typography className='vp-title'>{turnOverInVoluntaryCountNumber}</Typography>
          </Typography>
        </li>
        {turnOveInrVoluntaryCountList.map((entry: any, index: any) => (
          <li key={`item-${index}`}>
            <Typography component={'div'} className='d-flex flex-sb'>
              <Typography className=' d-flex'>
                <Typography component={'div'}>
                  <Typography
                    component={'div'}
                    sx={{ backgroundColor: `${COLORS[index]}` }}
                    className='legend-dot'
                  />
                </Typography>
                <Typography className='leg-info' sx={{ marginTop: '-5px' }}>
                  {entry?.termReason}
                </Typography>
              </Typography>
              <Typography className='num-info'>{entry?.totalTermReason}</Typography>
            </Typography>
          </li>
        ))}
      </ul>
    );
  };

  const CustomLabel = ({ viewBox, count }: any) => {
    let averageTotal = 0;
    if (count === 0 && ternuePopulationList.length) {
      const getPrice: any = R.prop('averageTenure');
      const sumPrices: any = R.reduce(R.add, 0);
      const averagePrice = sumPrices(R.map(getPrice, ternuePopulationList));
      averageTotal = Math.round(averagePrice / ternuePopulationList.length);
      // averageTotal = Math.round(averagePrice);
    } else if (count === 1 && turnOverVoluntaryCountList.length) {
      averageTotal = Math.round(turnOverVoluntaryCountNumber);
    } else if (count === 2 && turnOveInrVoluntaryCountList.length) {
      averageTotal = Math.round(turnOverInVoluntaryCountNumber);
    }

    return (
      <text
        x={viewBox.cx}
        y={viewBox.cy - 10}
        fill='#3d405c'
        style={{ outline: 'none !important' }}
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'>
        <tspan
          style={{ outline: 'none !important' }}
          alignmentBaseline='middle'
          className={
            String(averageTotal).length > 5 ? 'total-pie total-pie-extra-text' : 'total-pie'
          }>
          {averageTotal}
        </tspan>
        <tspan
          x={viewBox.cx}
          y={viewBox.cy + 20}
          className={count === 0 ? 'ave-pie1' : 'ave-pie'}
          style={{ outline: 'none !important' }}>
          {count === 0 ? 'Average Tenure' : 'Total'}
        </tspan>
      </text>
    );
  };
  const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor='end'
          fill='#979598'
          fontSize={12}
          transform='rotate(-50)'>
          {moment(payload.value).format('YYYY') + '' + moment(payload.value).format('MMM')}
        </text>
      </g>
    );
  };

  const CustomHeadCountTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'}>{`${moment(label).format('MMMM YYYY')}`}</Typography>
          <Typography component={'p'} className='qns-blue'>
            Headcount: {Math.round(payload[0].payload.beginningOfTheMonthCount)}
          </Typography>
          <Typography component={'p'} className='qns-green'>
            Turnover: {payload[0].payload.toRatio}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  const CustomCurrentEmployeeTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          {/* <Typography component={'p'}>Top 10 Categories</Typography> */}
          <Typography component={'p'} sx={{ color: `${COLORS[payload[0].name]} !important` }}>
            {payload[0].payload.hireDurationCategory}: {Math.round(payload[0].payload.totalHire)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  const CustomCurrentTurnOverTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          {/* <Typography component={'p'}>Top 10 Categories</Typography> */}
          <Typography component={'p'} sx={{ color: `${COLORS[payload[0].name]} !important` }}>
            {payload[0].payload.termReason}: {Math.round(payload[0].payload.totalTermReason)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  const CustomHireAndTermTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'}>{`${moment(payload[0].payload.yearMonth).format(
            'MMMM YYYY',
          )}`}</Typography>
          <Typography component={'p'} className='qns-blue'>
            New Hires: {Math.round(payload[0].payload.totalHired)}
          </Typography>
          <Typography component={'p'} className='qns-green'>
            Terms: {Math.round(payload[0].payload.totalTermination)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  // const renderCustomizedLabel = ({ x, y, value }) => (
  //   <text x={x} y={y} dy={-10} textAnchor='middle' fill='#666'>
  //     {value}
  //   </text>
  // );

  const renderCustomizedLabel = ({ x, y, width, value }: any) => {
    if (value) {
      return (
        <g>
          <text
            x={x + width / 2}
            y={y + 20}
            fill='#fff'
            textAnchor='middle'
            fontSize={'8px'}
            dominantBaseline='middle'>
            {value}
          </text>
        </g>
      );
    }
    return <Typography />;
  };
  const hiresCustomizedLabel = ({ x, y, width, value }: any) => {
    if (value) {
      return (
        <g>
          <text
            x={x + width / 2}
            y={y + 10}
            fill='#fff'
            textAnchor='middle'
            fontSize={'10px'}
            dominantBaseline='middle'>
            {value}
          </text>
        </g>
      );
    }
    return <Typography />;
  };
  const hiresCustomizedLabel2 = ({ x, y, width, value }: any) => {
    if (value) {
      return (
        <g>
          <text
            x={x + width / 2}
            y={y - 20}
            dx={0}
            dy={17}
            fill='#fff'
            textAnchor='middle'
            fontSize={'10px'}
            dominantBaseline='middle'>
            {value}
          </text>
        </g>
      );
    }
    return <Typography />;
  };

  return (
    <Grid container>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={() => handleDownload('img', dwnldIndex.name, dwnldIndex.index)}>
          Image
        </MenuItem>
        <MenuItem onClick={() => handleDownload('pdf', dwnldIndex.name, dwnldIndex.index)}>
          PDF
        </MenuItem>
      </Menu>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='ov-box'>
          <Grid container columnSpacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography ref={downloadRef0} component={'div'} className='pie-box'>
                {!hideIcon && ternuePopulationList.length ? (
                  <Typography className='down-box d-flex-ja'>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e: any) => handleClick(e, 0, 'Current Employee Population Tenure')}>
                      <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography />
                )}
                <Typography className='c-title d-flex-a'>
                  Current Employee Population Tenure
                  {!hideIcon && (
                    <TooltipMUI
                      title={
                        <Typography className='tooltip-text'>
                          {
                            EyeToolTipTextOverAll.organizationalOverview
                              .currentEmployeePopulationTenure
                          }
                        </Typography>
                      }>
                      <img className='info-im' src={InfoIcon} alt='info' />
                    </TooltipMUI>
                  )}
                </Typography>
                {ternuePopulationLoader ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={ternuePopulationLoader} />
                  </Typography>
                ) : !ternuePopulationList.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '250px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : (
                  <Typography component={'div'} className='d-flex'>
                    <Typography component={'div'} sx={{ width: '175px', height: 250 }}>
                      <ResponsiveContainer width={'100%'} height={'100%'}>
                        <PieChart>
                          <Tooltip content={<CustomCurrentEmployeeTooltip />} />
                          <Pie
                            data={ternuePopulationList}
                            cx='50%'
                            cy='50%'
                            innerRadius={60}
                            outerRadius={80}
                            fill='#8884d8'
                            paddingAngle={1}
                            dataKey='totalHire'>
                            {ternuePopulationList.map((entry: any, index: number) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                            <Label
                              width={30}
                              position='center'
                              content={<CustomLabel count={0} y={130} />}></Label>
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </Typography>
                    <Typography component={'div'} className='flex-1'>
                      {renderLegend()}
                    </Typography>
                  </Typography>
                )}
              </Typography>
              <Typography
                component={'div'}
                className='pie-box'
                ref={downloadRef1}
                sx={{ minHeight: '640px !important' }}>
                {!hideIcon &&
                (turnOverVoluntaryCountList.length || turnOveInrVoluntaryCountList.length) ? (
                  <Typography className='down-box d-flex-ja'>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e: any) => handleClick(e, 1, 'Turnover & Termination Reasons')}>
                      <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography />
                )}
                <Typography className='c-title d-flex-a'>
                  Turnover & Termination Reasons{' '}
                  {!hideIcon && (
                    <TooltipMUI
                      title={
                        <Typography className='tooltip-text'>
                          {EyeToolTipTextOverAll.organizationalOverview.turnoverTerminatonReasons}
                        </Typography>
                      }>
                      <img className='info-im' src={InfoIcon} alt='info' />
                    </TooltipMUI>
                  )}
                </Typography>
                {turnOverCountLoader ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={turnOverCountLoader} />
                  </Typography>
                ) : !turnOverVoluntaryCountList.length && !turnOveInrVoluntaryCountList.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '250px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : (
                  <>
                    <Typography component={'div'} className='d-flex'>
                      <Typography component={'div'} sx={{ width: '175px', height: 250 }}>
                        <ResponsiveContainer width={'100%'} height={'100%'}>
                          <PieChart>
                            <Tooltip content={<CustomCurrentTurnOverTooltip />} />
                            <Pie
                              data={turnOverVoluntaryCountList}
                              cx='50%'
                              cy='50%'
                              innerRadius={60}
                              outerRadius={80}
                              fill='#8884d8'
                              paddingAngle={1}
                              dataKey='totalTermReason'>
                              {turnOverVoluntaryCountList?.map((entry: any, index: number) => (
                                <Cell
                                  style={{ outline: 'none' }}
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                  strokeWidth={1}
                                />
                              ))}
                              <Label
                                width={30}
                                position='center'
                                content={<CustomLabel count={1} y={150} />}></Label>
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                      </Typography>
                      <Typography component={'div'} className='flex-1'>
                        {VoluntaryLegend()}
                      </Typography>
                    </Typography>
                    <Typography component={'div'} className='d-flex'>
                      <Typography component={'div'} sx={{ width: '175px', height: 250 }}>
                        <ResponsiveContainer width={'100%'} height={'100%'}>
                          <PieChart>
                            <Tooltip content={<CustomCurrentTurnOverTooltip />} />
                            <Pie
                              data={turnOveInrVoluntaryCountList}
                              cx='50%'
                              cy='50%'
                              innerRadius={60}
                              outerRadius={80}
                              fill='#8884d8'
                              paddingAngle={1}
                              dataKey='totalTermReason'>
                              {turnOveInrVoluntaryCountList.map((entry: any, index: number) => (
                                <Cell
                                  style={{ outline: 'none' }}
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                  strokeWidth={1}
                                />
                              ))}
                              <Label
                                width={30}
                                position='center'
                                style={{ outline: 'none !important' }}
                                content={<CustomLabel count={2} y={150} />}></Label>
                            </Pie>
                          </PieChart>
                        </ResponsiveContainer>
                      </Typography>
                      <Typography component={'div'} className='flex-1'>
                        {InvoluntaryLegend()}
                      </Typography>
                    </Typography>
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Typography component={'div'} className='pie-box' ref={downloadRef2}>
                {!hideIcon && headerCountTurnOverList.length ? (
                  <Typography className='down-box d-flex-ja'>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e: any) =>
                        handleClick(e, 2, 'Average HeadCount Vs TurnOver Ratio')
                      }>
                      <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography />
                )}
                <Typography className='c-title d-flex-a mb-15'>
                  Average Headcount vs Turnover Ratio
                  {!hideIcon && (
                    <TooltipMUI
                      title={
                        <Typography className='tooltip-text'>
                          {
                            EyeToolTipTextOverAll.organizationalOverview
                              .averageHeadcountvsTurnoverRatio
                          }
                        </Typography>
                      }>
                      <img className='info-im' src={InfoIcon} alt='info' />
                    </TooltipMUI>
                  )}
                </Typography>
                {headerCountTurnOverLoader ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={headerCountTurnOverLoader} />
                  </Typography>
                ) : !headerCountTurnOverList.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '250px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : (
                  <ResponsiveContainer aspect={1.5}>
                    <ComposedChart
                      className='hrchart'
                      data={headerCountTurnOverList}  
                      margin={{
                        top: 15,
                        left: 0,
                        right: 0,
                        bottom: 0,
                      }}>
                      <CartesianGrid stroke='white' />
                      <XAxis
                        label={{ value: 'Year Month', dx: 0, dy: 40 }}
                        strokeDasharray='0 10'
                        dataKey='date'
                        height={120}
                        fontSize={12}
                        interval={0}
                        tick={<CustomizedAxisTick />}
                      />
                      <YAxis
                        strokeDasharray='0 10'
                        yAxisId='left'
                        orientation='left'
                        label={{ value: 'Average Headcount', angle: 270, dx: -25, dy: -50 }}
                      />
                      <YAxis
                        strokeDasharray='0 10'
                        orientation='right'
                        yAxisId='right'
                        unit={'%'}
                        label={{ value: 'Turnover Ratio', angle: 90, dx: 25, dy: 10 }}
                      />
                      {/* <YAxis yAxisId='left' orientation='left' stroke='#8884d8' />
                      <YAxis yAxisId='right' orientation='right' stroke='#82ca9d' /> */}
                      <Tooltip content={<CustomHeadCountTooltip />} />
                      <Legend verticalAlign='top' align='right' content={HRLegend} />
                      {/* <Bar dataKey='beginningOfTheMonthCount' barSize={20} fill='#3441D2'>
                        {hideIcon && (
                          <LabelList
                            dataKey='beginningOfTheMonthCount'
                            content={renderCustomizedLabel}
                          />
                        )}
                      </Bar> */}
                      <Bar
                        yAxisId='left'
                        dataKey='beginningOfTheMonthCount'
                        barSize={20}
                        fill='#344A5C'>
                        {hideIcon && (
                          <LabelList
                            dataKey='beginningOfTheMonthCount'
                            content={renderCustomizedLabel}
                          />
                        )}
                      </Bar>

                      {/* <Bar yAxisId='left' dataKey='beginningOfTheMonthCount' fill='#8884d8' /> */}
                      {/* <Bar yAxisId='right' dataKey='beginningOfTheMonthCount' fill='#82ca9d' /> */}
                      <Line yAxisId='right' dataKey='toRatio' stroke='#C3D34F' strokeWidth={3} />
                    </ComposedChart>
                  </ResponsiveContainer>
                )}
              </Typography>
              <Typography component={'div'} className='pie-box' ref={downloadRef3}>
                {!hideIcon && hiredTerminationList.length ? (
                  <Typography className='down-box d-flex-ja'>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e: any) => handleClick(e, 3, 'Hires & Terms by Month')}>
                      <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography />
                )}
                <Typography className='c-title d-flex-a mb-15'>
                  Hires & Terms by Month{' '}
                  {!hideIcon && (
                    <TooltipMUI
                      title={
                        <Typography className='tooltip-text'>
                          {EyeToolTipTextOverAll.organizationalOverview.hiresTermsbyMonth}
                        </Typography>
                      }>
                      <img className='info-im' src={InfoIcon} alt='info' />
                    </TooltipMUI>
                  )}
                </Typography>
                {hiredTerminationLoader ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={hiredTerminationLoader} />
                  </Typography>
                ) : !hiredTerminationList.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '250px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : (
                  <ResponsiveContainer aspect={1.5}>
                    <BarChart
                      className='hrmonth-chart'
                      // width={500}
                      // height={450}
                      data={hiredTerminationList}
                      stackOffset='sign'
                      margin={{
                        top: 20,
                        right: 0,
                        left: 0,
                        bottom: 0,
                      }}>
                      <CartesianGrid stroke='white' />
                      <XAxis
                        label={{ value: `${LastYearData}`, dx: 0, dy: 17 }}
                        dataKey='yearMonthName'
                        height={60}
                        strokeDasharray='0 10'
                      />
                      <YAxis strokeDasharray='0 10' />
                      <Tooltip content={<CustomHireAndTermTooltip />} />
                      <Legend verticalAlign='top' align='right' content={HRmonthLegend} />
                      <Bar dataKey='totalHired' stackId='stack' fill='#344A5C'>
                        {hideIcon && (
                          <LabelList dataKey='totalHired' content={hiresCustomizedLabel} />
                        )}
                      </Bar>
                      <Bar dataKey='totalTermination' stackId='stack' fill='#C3D34F'>
                        {hideIcon && (
                          <LabelList dataKey='totalTermination' content={hiresCustomizedLabel2} />
                        )}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Oraganization;
