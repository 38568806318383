export const PublicRoutes = {
  ADMINLOGIN: '/admin/login',
  CLIENTLOGIN: '/client/login',
  CLIENTCOMPANYLOGIN: '/client/company/login',
  ADMINRESET: '/admin/verify-otp',
  CLIENTRESET: '/client/verify-otp',
  VERIFYADMIN: '/admin/verify-admin',
  VERIFYCLIENT: '/client/verify-user',
  ADMINPASSWORDSUCESS: '/admin/password-success',
  CLIENTPASSWORDSUCESS: '/client/password-success',
  FORGOTPASSWORDADMIN: '/admin/forgot-password',
  FORGOTPASSWORDCLIENT: '/client/forgot-password',
  FORGOTPASSWORDSUCCESSCLIENT: '/client/forgot-password-success',
  FORGOTPASSWORDSUCCESSADMIN: '/admin/forgot-password-success',
};

export const PrivateRoutes = {
  DASHBOARD: '/client/dashboard',
  ORGANIZATION: '/client/organizational-overview',
  EMPLOYEEENGAGE: '/client/employee-engagement',
  EMPLOYEEENGAGELIST: '/client/employee-engagement/list',
  RECRUITING: '/client/recruiting',
  PERFORMANCE: '/client/performance',
  ACCESSMANAGEMENT: {
    EMPLOYEES: '/client/access-management/employees',
    ROLESPERMISSIONS: '/client/access-management/roles-permissions',
  },
  LEARNDEVLOPMENT: '/client/learning-development',
  REPORTS: '/client/reports',
  SETTINGS: '/client/settings',
  DEI: '/client/de&i',
};

export const AdminPrivateRoutes = {
  COMPANY: '/admin/companies',
  EMPLOYEES: '/admin/companies/employees',
  CLASSIFICATION: '/admin/companies/classificationcode',
  ADMINS: '/admin/list',
};
