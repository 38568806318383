import { Button, Grid, Popover, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import Loader from 'components/Loader';
import moment from 'moment';
import AddAndEdit from './addAndEdit';
import { useAppSelector } from 'store/hooks';
import ActiveAndDeActive from './activeAndDeActive';
import _ from 'lodash';

const statickParam: any = {
  sortBy: '',
  order: '',
};

const Admins = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [param, setParam] = React.useState<{
    sortBy: string;
    order: 'asc' | 'desc';
  }>(statickParam);
  const [loader, setLoader] = React.useState<boolean>(false);
  const [list, setList] = React.useState<any>([]);
  const [editItem, setEditItem] = React.useState<any>({});
  const [modelOpen, setModelOpen] = React.useState<boolean>(false);
  const [inviteModelOpen, setInviteModelOpen] = React.useState<boolean>(false);
  const activeAndDeActiveModalOpen = () => {
    setInviteModelOpen(true);
  };

  const activeAndDeActiveModalClose = () => {
    setInviteModelOpen(false);
    handleClose();
  };

  const handleOpen = () => setModelOpen(true);
  const handleCloseModel = () => {
    setModelOpen(false);
    handleClose();
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, item: any) => {
    setAnchorEl(event.currentTarget);
    setEditItem(item);
  };

  const getData = () => {
    setLoader(true);
    const endPoint = `${MASTER_ENDPOINT.SuperAdminUser}?order=${param.order}&sortby=${param.sortBy}`;
    getAllListData(endPoint)
      .then((response: any) => {
        setList(response?.data);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const { tableReload } = useAppSelector((store: any) => {
    return store.userLogin;
  });

  useEffect(() => {
    getData();
  }, [tableReload]);

  const changeParam = (keyName: string) => {
    setLoader(true);
    setParam((pre: any) => {
      return {
        ...pre,
        sortBy: keyName,
        order: param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      };
    });
    setList([
      ..._.orderBy(
        list,
        [
          (item: any) =>
            item[keyName]
              ? typeof item[keyName] === 'boolean'
                ? item[keyName]
                : item[keyName]?.toLowerCase()
              : '',
        ],
        param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      ),
    ]);
    setTimeout(() => setLoader(false), 500);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setEditItem({});
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const tableData = React.useMemo(() => {
    if (list.length) {
      return list.map((row: any) => (
        <TableRow
          key={row.id}
          sx={{ ' &:last-child th': { border: 0 } }}
          className={
            row?.isActive === true
              ? 'active-row'
              : row?.isActive === false
                ? 'deactive-row'
                : row?.isActive === null
                  ? 'inited-row'
                  : ''
          }>
          <TableCell>
            <Typography component={'div'} className='d-flex-a'>
              <Typography component={'div'}>
                {row?.imageId ? (
                  <img
                    className='d-avtaor d-flex-ja'
                    src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${row?.imageId}`}
                  />
                ) : (
                  <Typography className='d-avtaor d-flex-ja' sx={{ textTransform: 'capitalize' }}>
                    {row?.firstName?.[0]}
                  </Typography>
                )}
              </Typography>
              <Typography className='company-name' sx={{ textTransform: 'capitalize' }}>
                {`${row?.firstName} ${row?.lastName}`}
              </Typography>
            </Typography>
          </TableCell>
          <TableCell>{row?.jobTitle}</TableCell>
          <TableCell>{row?.username}</TableCell>
          <TableCell>
            <Typography
              className={
                row?.isActive === true
                  ? 'active-badge'
                  : row?.isActive === false
                    ? 'decative-badge'
                    : row?.isActive === null
                      ? 'ivited-badge'
                      : ''
              }>
              {row?.isActive === true
                ? 'Active'
                : row?.isActive === false
                  ? 'InActive'
                  : row?.isActive === null && 'Invited'}
            </Typography>
          </TableCell>
          <TableCell>
            {row?.lastActiveDate && moment(row?.lastActiveDate).format('MM/DD/YYYY')}
          </TableCell>
          <TableCell align='right'>
            <Button
              aria-describedby={id}
              onClick={(e) => handleClick(e, row)}
              sx={{ minWidth: '23px' }}>
              <MoreVertIcon />
            </Button>
          </TableCell>
        </TableRow>
      ));
    }
    return (
      <TableRow>
        <TableCell colSpan={6} align='center'>
          <Typography component={'div'} className='d-flex-ja norecord'>
            No Records Found
          </Typography>
        </TableCell>
      </TableRow>
    );
  }, [list]);

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <TableContainer component={Paper} className='companie-table'>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ paddingLeft: '62px !important', width: '20%' }}>
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='th-title'>Name</Typography>
                    <Typography className='arrow-box' onClick={() => changeParam('firstName')}>
                      <ArrowDropDownIcon
                        className='down'
                        style={{
                          fill:
                            param.sortBy === 'firstName' && param.order === 'desc' ? 'black' : '',
                        }}
                      />
                      <ArrowDropUpIcon
                        className='up'
                        style={{
                          fill:
                            param.sortBy === 'firstName' && param.order === 'asc' ? 'black' : '',
                        }}
                      />
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: '15%' }}>
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='th-title'>Job Title</Typography>
                    <Typography className='arrow-box' onClick={() => changeParam('jobTitle')}>
                      <ArrowDropDownIcon
                        className='down'
                        style={{
                          fill:
                            param.sortBy === 'jobTitle' && param.order === 'desc' ? 'black' : '',
                        }}
                      />
                      <ArrowDropUpIcon
                        className='up'
                        style={{
                          fill: param.sortBy === 'jobTitle' && param.order === 'asc' ? 'black' : '',
                        }}
                      />
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: '30%' }}>
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='th-title'>Email</Typography>
                    <Typography className='arrow-box' onClick={() => changeParam('username')}>
                      <ArrowDropDownIcon
                        className='down'
                        style={{
                          fill:
                            param.sortBy === 'username' && param.order === 'desc' ? 'black' : '',
                        }}
                      />
                      <ArrowDropUpIcon
                        className='up'
                        style={{
                          fill: param.sortBy === 'username' && param.order === 'asc' ? 'black' : '',
                        }}
                      />
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: '15%' }}>
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='th-title'>Status</Typography>
                    <Typography className='arrow-box' onClick={() => changeParam('isActive')}>
                      <ArrowDropDownIcon
                        className='down'
                        style={{
                          fill:
                            param.sortBy === 'isActive' && param.order === 'desc' ? 'black' : '',
                        }}
                      />
                      <ArrowDropUpIcon
                        className='up'
                        style={{
                          fill: param.sortBy === 'isActive' && param.order === 'asc' ? 'black' : '',
                        }}
                      />
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: '15%' }}>
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='th-title'>Last Active</Typography>
                    <Typography className='arrow-box' onClick={() => changeParam('lastActiveDate')}>
                      <ArrowDropDownIcon
                        className='down'
                        style={{
                          fill:
                            param.sortBy === 'lastActiveDate' && param.order === 'desc'
                              ? 'black'
                              : '',
                        }}
                      />
                      <ArrowDropUpIcon
                        className='up'
                        style={{
                          fill:
                            param.sortBy === 'lastActiveDate' && param.order === 'asc'
                              ? 'black'
                              : '',
                        }}
                      />
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: '5%' }} />
              </TableRow>
            </TableHead>
            <TableBody>{loader ? <Loader loading={loader} colsSpan={6} /> : tableData}</TableBody>
          </Table>
        </TableContainer>
        <Popover
          className='edit-popup'
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}>
          <Typography className='arrow-top' />
          <Typography className='pop-item' onClick={() => handleOpen()}>
            Edit
          </Typography>
          <Typography className='pop-item ' onClick={() => activeAndDeActiveModalOpen()}>
            {editItem?.isActive === true
              ? 'Deactivate'
              : editItem?.isActive === false
                ? 'Invite'
                : editItem?.isActive === null && 'Reinvite'}
          </Typography>
        </Popover>
        <AddAndEdit visible={modelOpen} handleClose={handleCloseModel} editObject={editItem} />
        <ActiveAndDeActive
          visible={inviteModelOpen}
          handleClose={activeAndDeActiveModalClose}
          editObject={editItem}
        />
      </Grid>
    </Grid>
  );
};

export default Admins;
