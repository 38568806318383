import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import FilterDataContextProvider from '../../context/FilterDataContextProvider';
import FilterDataContext from '../../context/filterDataContext';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardActive from 'assets/png/NavDashboardActive.svg';
import DashboardNormal from 'assets/png/NavDashboardNormal.svg';
import NavNotificationActive from 'assets/png/NavNotificationActive.svg';
import NavNotificationNormal from 'assets/png/NavNotificationNormal.svg';
import NavTodoActive from 'assets/png/NavTodoActive.svg';
import NavTodoNormal from 'assets/png/NavTodoNormal.svg';
import NavServiceRequestActive from 'assets/png/NavServiceRequestActive.svg';
import NavServiceRequestNormal from 'assets/png/NavServiceRequestNormal.svg';
import NavPerformanceActive from 'assets/png/NavPerformanceActive.svg';
import NavPerformanceNormal from 'assets/png/NavPerformanceNormal.svg';
import NavAccessManagementActive from 'assets/png/NavAccessManagementActive.svg';
import NavAccessManagementNormal from 'assets/png/NavAccessManagementNormal.svg';
import NavReportsActive from 'assets/png/NavReportsActive.svg';
import NavReportsNormal from 'assets/png/NavReportsNormal.svg';
import NavLearningDevelopmentActive from 'assets/png/NavLearningDevelopmentActive.svg';
import NavLearningDevelopmentNormal from 'assets/png/NavLearningDevelopmentNormal.svg';
import NavDEIActive from 'assets/png/NavDEIActive.svg';
import NavDEINormal from 'assets/png/NavDEINormal.svg';
import NavSettingsActive from 'assets/png/NavSettingsActive.svg';
import NavSettingsNormal from 'assets/png/NavSettingsNormal.svg';
import NavLogout from 'assets/png/NavLogout.svg';
// import SearchIcon from 'assets/png/Search.svg';
import FilterIcon from 'assets/png/Filter.svg';
import MenuLogo from 'assets/png/Expand.svg';
import Fulllogo from 'assets/png/LogoWhite.svg';
import Halflogo from 'assets/png/LogoIcon.png';
import {
  Avatar,
  Badge,
  Button,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  Popover,
  TextField,
  Tooltip,
} from '@mui/material';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import {
  AdminPrivateRoutes,
  PrivateRoutes,
  PrivateRoutes as PrivateRoutesDirectives,
} from 'routes/routeConstans';
import { Link, To, useNavigate } from 'react-router-dom';
import { logout } from 'store/actions/UserAction';
import { useDispatch } from 'react-redux';
import { addNewData, deleteData, getAllListData, updateData } from 'services/CommonServices';
import { useEffect } from 'react';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { useAppSelector } from 'store/hooks';
import PageLoader from 'components/PageLoader';
import Storage from 'utils/Storage';
import { SIDE_CLASSES, SIDE_MENUS, USER_LOGIN_SUCCESS, USER_PROFILE } from 'store/types/UserTypes';
import _ from 'lodash';
import * as R from 'ramda';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
import moment from 'moment';
import CheckIconClassCode from '@mui/icons-material/Check';
import { Search } from '@mui/icons-material';
import { useDebounce } from 'use-debounce';
import EmployeeSearchTextBox from '../../components/EmployeeSearchTextBox';

const drawerWidth = 245;

const logoutStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  minHeight: '168px',
  width: '414px',
  borderRadius: ' 8px',
  boxShadow: ' 0 5px 14px 0 rgba(0,0,0,0.22)',
  padding: '28px 30px',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 500,
  maxWidth: 700,
  bgcolor: 'background.paper',
  boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
  padding: '30px',
};

const openedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: '#344A5C',

  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'auto',
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: '#344A5C',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const loginimgopen = (): CSSObject => ({
  display: 'flex',
  paddingRight: 15,
  paddingLeft: 15,
});

const loginimgclose = (): CSSObject => ({
  display: 'none',
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
      '& .sidebaravatar': loginimgopen(),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      '& .sidebaravatar': loginimgclose(),
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  paddingLeft: '20px !important',
  height: '80px !important',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  height: '80px !important',
  justifyContent: 'center',
  background: '#FFF',
  boxShadow: 'unset !important',
  borderBottom: '1px solid #E8E8E8',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

type LayoutConfigsProps = {
  path: string;
  header: boolean;
  title?: string;
  children: any;
};

const drawerStyle = {
  text: {
    fontFamily: 'Poppins-Regular !important',
    fontSize: '16px !important',
    lineHeight: '24px !important',
    fontWeight: '600',
  },
  icon: {
    height: 20,
    width: 20,
  },
  iconMenu: {
    height: 22,
    width: 22,
  },
  zastiIcon: {
    height: 26,
    width: 75,
  },
  listButton: {
    borderRadius: '4px',
    margin: '15px',
  },
  versionText: {
    position: 'absolute',
    right: 15,
    fontFamily: 'Poppins-Regular !important',
    fontSize: '12px !important',
    lineHeight: '20px !important',
    fontWeight: '400',
  },
  organizationLogo: {
    width: 68,
    height: 55,
  },
  nameLogo: {
    height: 32,
    width: 32,
    borderRadius: 16,
    backgroundColor: '#28A9E1',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px !important',
    lineHeight: '24px !important',
    fontWeight: '500',
  },
  nametext: {
    fontSize: '14px !important',
    lineHeight: '20px !important',
    fontWeight: '500',
    color: '#111',
    marginRight: '10px',
  },
  profilePng: {
    height: 20,
    width: 24,
  },
};

const PrivateLayout = (props: LayoutConfigsProps) => {
  const {
    DASHBOARD,
    ORGANIZATION,
    EMPLOYEEENGAGE,
    EMPLOYEEENGAGELIST,
    PERFORMANCE,
    ACCESSMANAGEMENT,
    RECRUITING,
    DEI,
    SETTINGS,
    REPORTS,
    LEARNDEVLOPMENT,
  } = PrivateRoutesDirectives;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [logoutOpen, setLogoutOpen] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(true);
  const [codeListAndJob, setCodeListAndJob] = React.useState<{
    codeList: any;
    jobList: any;
    companyList: any;
    directSuperVisorList: any;
  }>({
    codeList: [],
    jobList: [],
    companyList: [],
    directSuperVisorList: [],
  });
  const [classificationCodeAndJob, setClassificationCodeAndJob] = React.useState<{
    codeList: any;
    jobList: any;
    companyList: any;
    directSuperVisorList: any;
  }>({
    codeList: [],
    jobList: [],
    companyList: [],
    directSuperVisorList: [],
  });
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [profileopen, setProfileOpen] = React.useState(false);
  const {
    profileInfo: clientList,
    userInfo: userDetail,
    menuList: screenList,
    classList,
  }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });
  const [uploadImage, setUploadImage] = React.useState<any>({});
  const [localImage, setLocalImage] = React.useState<any>('');
  const handleProfileOpen = () => setProfileOpen(true);
  const handleProfileClose = () => setProfileOpen(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    getFilterData();
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const gotoNavigate = (link: To, paramData?: any) => {
      if (paramData) {
         navigate(link, paramData);
      } else {
         navigate(link);
      }
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const logoutFunction = () => {
    setLoader(true);
    addNewData(
      '',
      `${MASTER_ENDPOINT.LogoutUser}?clientId=${
        userDetail.ClientId ? userDetail.ClientId : 0
      }&userId=${userDetail.UserId}`,
    ).then(() => {
      logout(dispatch);
      setLoader(false);
    });
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getRoleByData = (roleId: any) => {
    if(roleId === null) return;
    const endPoint = `${MASTER_ENDPOINT.GetRoleById}/${roleId}`;
    getAllListData(endPoint).then((response: any) => {
      if (screenList.length === 0 || !_.isEqual(response?.data?.screens, screenList)) {
        Storage.removeItem(Storage.KEYS.MENUS);
        dispatch({
          type: SIDE_MENUS,
          payload: response?.data?.screens,
        });
        Storage.setItem(Storage.KEYS.MENUS, JSON.stringify(response?.data?.screens));
      }
      const endPointClassCode = `${MASTER_ENDPOINT.ClassificationCode}/${userDetail.ClientId}`;
      getAllListData(endPointClassCode).then(async (response1: any) => {
        setClassificationCodeAndJob((pre: any) => {
          return { ...pre, codeList: response1?.data };
        });
        Promise.all(
          response1.data.map((item: any) => {
            const endPoint1 = `${MASTER_ENDPOINT.ClassificationCodeForClient}/${userDetail?.ClientId}/${userDetail.Id}/${item.classificationCode}`;
            return new Promise(function (resolve, reject) {
              getAllListData(endPoint1)
                .then((response2: any) => {
                  resolve(response2?.data);
                })
                .catch((err) => {
                  reject(err);
                });
            });
          }),
        ).then((results: any) => {
          if (results && results.length) {
            let classesArray: any = [];
            results.map((item: any) => {
              classesArray = classesArray.concat(item ? item : []);
            });
            if (classList.length === 0 || !_.isEqual(classesArray, classList)) {
              Storage.removeItem(Storage.KEYS.CLASSES);
              dispatch({
                type: SIDE_CLASSES,
                payload: classesArray,
              });
              Storage.setItem(Storage.KEYS.CLASSES, JSON.stringify(classesArray));
            }
          }
        });
      });
    });
  };

  const [loader, setLoader] = React.useState(false);

  const getProfileData = () => {
    getAllListData(`${MASTER_ENDPOINT.GetEmployeeId}/${userDetail.ClientId}/${userDetail.Id}`)
      .then(async (res) => {
        if (clientList.length === 0 || !_.isEqual(res?.data?.[0], clientList)) {
          Storage.removeItem(Storage.KEYS.PROFILE_INFO);
          dispatch({
            type: USER_PROFILE,
            payload: res?.data?.[0],
          });
          Storage.setItem(Storage.KEYS.PROFILE_INFO, JSON.stringify(res?.data?.[0]));
        }
        getRoleByData(res?.data?.[0].roleId);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const {setFilterData} = React.useContext(FilterDataContext);

  useEffect(() => {
    if (userDetail?.Id) {
      if (screenList.length === 0) {
        setLoader(true);
      }
      getProfileData();
      const endPointJobCode = `${MASTER_ENDPOINT.GetJobCode}/${userDetail.ClientId}/${userDetail.Id}`;
      getAllListData(endPointJobCode).then(async (response: any) => {
        setClassificationCodeAndJob((pre: any) => {
          return { ...pre, jobList: response?.data };
        });
      });
      const endPointDirectSupervisor = `${MASTER_ENDPOINT.GetDirectSupervisor}/${userDetail.ClientId}/${userDetail.Id}`;
      getAllListData(endPointDirectSupervisor).then(async (response: any) => {
        setClassificationCodeAndJob((pre: any) => {
          return { ...pre, directSuperVisorList: response?.data };
        });
      });
      const endPointCompany = `${MASTER_ENDPOINT.GetCompany}/${userDetail.ClientId}/${userDetail.Id}`;
      getAllListData(endPointCompany).then(async (response: any) => {
        setClassificationCodeAndJob((pre: any) => {
          return { ...pre, companyList: response?.data };
        });
      });
      getFilterData();
    }
  }, [userDetail?.Id]);

  const [inputValue, setInputValue] = React.useState('');
  const [debouncedValue] = useDebounce(inputValue, 1000);

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    setInputValue(value);
  };

  const getFilterData = () => {
    getAllListData(`${MASTER_ENDPOINT.GetFilter}/${userDetail.Id}`).then(async (response: any) => {
      if (response?.data) {
        const jobData = (response?.data?.jobs ? response.data.jobs : []).map((item: any) => {
          return item?.jobCode;
        });
        const companyData = (response?.data?.companies ? response.data.companies : []).map(
          (item: any) => {
            return item?.companyCode;
          },
        );
        const directSuperVisorData = (
          response?.data?.directSupervisors ? response.data.directSupervisors : []
        ).map((item: any) => {
          return item?.directSupervisorEmployeeID;
        });
        // const codeData = (response?.data?.classes ? response.data.classes : []).map((item: any) => {
        //   return item?.code;
        // });
        const codeData = response.data.classes;
        setCodeListAndJob((pre: any) => {
          setFilterData({
            ...pre,
            codeList: codeData,
            jobList: jobData,
            companyList: companyData,
            directSuperVisorList: directSuperVisorData,
          });
          return {
            ...pre,
            codeList: codeData,
            jobList: jobData,
            companyList: companyData,
            directSuperVisorList: directSuperVisorData,
          };
        });
      } else {
        setCodeListAndJob((pre: any) => {
          setFilterData({
            ...pre,
            codeList: [],
            jobList: [],
            companyList: [],
            directSuperVisorList: []
          });
          return {
            ...pre,
            codeList: [],
            jobList: [],
            companyList: [],
            directSuperVisorList: [],
          };
        });
      }
    });
  };

  const resetFilter = () => {
    setLoader(true);
    deleteData(userDetail?.Id, `${MASTER_ENDPOINT.DeleteFilter}`, true)
      .then(() => {
        setFilterData({
          codeList: [],
          jobList: [],
          companyList: [],
          directSuperVisorList: []
        });
        setLoader(false);
        navigate(0);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const applyFilter = () => {
    if (
      !codeListAndJob.codeList?.length &&
      !codeListAndJob.jobList?.length &&
      !codeListAndJob.companyList?.length &&
      !codeListAndJob?.directSuperVisorList?.length
    ) {
      toast.error('Please add atleast one filter', alertOptions);
      return;
    }
    // const codeData = classList.filter((item: any) => codeListAndJob?.codeList?.includes(item.code));
    const codeData = codeListAndJob?.codeList;
    const companyData = codeListAndJob?.companyList.map((item: any) => {
      return { companyCode: item };
    });
    const jobData = codeListAndJob?.jobList.map((item: any) => {
      return { jobCode: item };
    });
    const directSuperVisorData = codeListAndJob?.directSuperVisorList.map((item: any) => {
      return { directSupervisorEmployeeID: item, clientId: Number(userDetail?.ClientId) };
    });
    setLoader(true);
    const postJson = {
      employeeId: userDetail?.Id,
      classes: codeData,
      jobs: jobData,
      companies: companyData,
      directSupervisors: directSuperVisorData,
      createdBy: userDetail?.Id,
      createdOn: moment(),
      updatedBy: userDetail?.Id,
      updatedOn: moment(),
    };
    setFilterData({
      codeList: codeData,
      jobList: jobData,
      companyList: companyData,
      directSuperVisorList: directSuperVisorData
    });
    addNewData(postJson, MASTER_ENDPOINT.CreateFilter, {}, true)
      .then(() => {
        setLoader(false);
        navigate(0);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const backImpersonateFunction = () => {
    setLoader(true);
    let endPoint = '';
    if (userDetail?.isFromSuperAdmin == 'False') {
     endPoint = `${MASTER_ENDPOINT.BackImpersonateUser}?userId=${userDetail?.superUserId}&isFromSuperAdmin=${userDetail?.isFromSuperAdmin}&clientId=${userDetail?.ClientId}`;
    } else {
      endPoint = `${MASTER_ENDPOINT.BackImpersonateUser}?userId=${userDetail?.superUserId}&isFromSuperAdmin=${userDetail?.isFromSuperAdmin}`;
    }
    getAllListData(endPoint)
      .then(async (response: any) => {
        if (response.token) {
          const userData: any = jwtDecode(String(response?.token));
          userData['token'] = response?.token;
          userData['userDetailsData'] = response?.data;
          userData['isAdmin'] = userDetail?.isFromSuperAdmin == 'False' ? false : true;
          userData['isClient'] = userDetail?.isFromSuperAdmin == 'False' ? true : false;
          userData['isImpersonate'] = false;
          userData['isFromSuperAdmin'] = '';
          userData['superUserId'] = '';
          userData['redirectCompanyId'] = userDetail?.isFromSuperAdmin == 'False' ? null : userDetail?.ClientId;
          const tokenData: any = {
            refreshToken: '',
            token: response?.token,
            userId: userData.id,
            isAdmin: userDetail?.isFromSuperAdmin == 'False' ? false : true,
            isClient: userDetail?.isFromSuperAdmin == 'False' ? true : false,
            isImpersonate: false,
            isFromSuperAdmin: '',
            superUserId: '',
          };
          Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
          Storage.setItem(Storage.KEYS.AUTH_TOKEN, JSON.stringify(tokenData));
          await setTimeout(
            () =>
              dispatch({
                payload: userData,
                type: USER_LOGIN_SUCCESS,
              }),
            500,
          );
          if (userDetail?.isFromSuperAdmin == 'False') {
            navigate(PrivateRoutes.DASHBOARD);
          } else {
              gotoNavigate(`${AdminPrivateRoutes.EMPLOYEES}/${userDetail?.ClientId}`);
          }
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const updatePhoto = (e: any) => {
    const imageFormatCheck = e?.target?.files[0]?.name.split('.');
    const formatCheck = ['tiff', 'jpeg', 'jpg', 'png'].includes(
      imageFormatCheck[imageFormatCheck?.length - 1].toLowerCase(),
    );
    const maxSizeInMB = 10;
    const fileSizeInMB = e?.target?.files[0].size / 1024 / 1024;
    if (fileSizeInMB > maxSizeInMB) {
      toast.error('File size should not exceed 10MB.');
    } else if (formatCheck) {
      setLoader(true);
      setUploadImage(e?.target?.files[0]);
      setLocalImage(URL.createObjectURL(e?.target?.files[0]));
      if (e?.target?.files[0]) {
        const formData2 = new FormData();
        if (uploadImage) {
          formData2.append('files', e?.target?.files[0]);
        }
        formData2.append('UserId', userDetail.UserId);
        if (userDetail.ClientId) {
          formData2.append('ClientId', userDetail.ClientId);
        } else {
          formData2.append('ClientId', `${0}`);
        }

        clientList?.imageId
          ? formData2.append('ImageId', clientList?.imageId)
          : formData2.append('ImageId', clientList?.null);
        formData2.append('CreatedBy', userDetail.Id);
        formData2.append('CreatedOn', moment().format());
        formData2.append('UpdatedBy', userDetail.Id);
        formData2.append('UpdatedOn', moment().format());

        updateData('', formData2, `${MASTER_ENDPOINT.UploadPicture}`, true, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(() => {
            getProfileData();
            setLoader(false);
          })
          .finally(() => {
            setLoader(false);
          });
      }
    } else {
      toast.error('Upload valid images only PNG, JPEG, JPG and TIFF are allowed');
    }
  };

  const filteropen = Boolean(anchorEl);
  const id = filteropen ? 'simple-popover' : undefined;

  const [filteredData, setFilteredData] = React.useState<{
    type: string;
    optionList: any;
  }>({
    type: '',
    optionList: [],
  });
  const [anchorElSelectFilter, setAnchorElSelectFilter] = React.useState<null | HTMLElement>(null);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>, type: string, data: any) => {
    setFilteredData((pre: any) => {
      return {
        ...pre,
        optionList: data,
        type,
      };
    });
    setAnchorElSelectFilter(event.currentTarget);
  };
  const selectFilterOpen = Boolean(anchorElSelectFilter);

  return (
    <FilterDataContextProvider>
    <Box component='div' sx={{ display: 'flex', minHeight: '100vh' }}>
      {userDetail?.isImpersonate && (
        <Grid container sx={{ position: 'fixed', background: 'red', zIndex: 10000 }}>
          <Grid
            item
            md={12}
            sm={12}
            xs={12}
            sx={{ display: 'flex', padding: '4px' }}
            textAlign={'center'}
            justifyContent={'center'}
            alignContent={'center'}
            alignItems={'center'}>
            <Typography component={'div'} className='impersonate-name'>
              Impersonation is in progress
            </Typography>
            <Typography component={'div'} className='stop-divider' />
            <Typography component={'div'} onClick={backImpersonateFunction} className='stop-btn'>
              <Typography className='stop-name'>STOP</Typography>
            </Typography>
          </Grid>
        </Grid>
      )}
      <PageLoader loading={loader} />
      <CssBaseline />
      {props.header && (
        <AppBar
          position='fixed'
          open={open}
          className='header-border'
          sx={{ top: userDetail?.isImpersonate ? '33px' : '0px' }}>
          <Toolbar className='custom-toolbar'>
            <Typography component={'div'} className='d-flex-a'>
              <Typography
                className='hr extra'
                sx={{ ...(open && { display: 'none' }) }}
                component={'p'}>
                <img src={Halflogo} alt='HR' />
              </Typography>
              {props.title && (
                <Typography component={'div'} className='page-title'>
                  {props.title === 'DashBoard' ? 'Dashboard' : props.title}
                </Typography>
              )}
            </Typography>
            {props.path === EMPLOYEEENGAGE || props.path === EMPLOYEEENGAGELIST ? (
              <Typography component={'div'} className='d-flex-a employee-legent'>
                <Typography component={'div'} className='d-flex-a mr-16'>
                  <Typography className='opt-box rs' />
                  <Typography className='opt-text'>On the Move</Typography>
                </Typography>
                <Typography component={'div'} className='d-flex-a mr-16'>
                  <Typography className='opt-box gs' />
                  <Typography className='opt-text'>Good Standing</Typography>
                </Typography>
                <Typography component={'div'} className='d-flex-a'>
                  <Typography className='opt-box na' />
                  <Typography className='opt-text'>Needs Attention</Typography>
                </Typography>
              </Typography>
            ) : (
              ''
            )}
            {props.path === ACCESSMANAGEMENT.EMPLOYEES ||
            props.path === ACCESSMANAGEMENT.ROLESPERMISSIONS ? (
              <Typography component={'div'} className='d-flex-a'>
                <Typography
                  onClick={() => gotoNavigate(ACCESSMANAGEMENT.EMPLOYEES)}
                  className={
                    props.path === ACCESSMANAGEMENT.EMPLOYEES
                      ? 'header-tab active mr-24'
                      : 'header-tab mr-24'
                  }>
                  Employees
                </Typography>
                <Typography
                  onClick={() => gotoNavigate(ACCESSMANAGEMENT.ROLESPERMISSIONS)}
                  className={
                    props.path === ACCESSMANAGEMENT.ROLESPERMISSIONS
                      ? 'header-tab active'
                      : 'header-tab'
                  }>
                  Roles & Permissions
                </Typography>
              </Typography>
            ) : (
              ''
            )}
            <IconButton
              className={'collapse-btn'}
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              sx={{
                position: 'fixed',
                bottom: '16vh',
                left: '50px',
                ...(open && { display: 'none' }),
              }}>
              <Avatar sx={drawerStyle.iconMenu} variant='square' alt='Zasti' src={MenuLogo} />
            </IconButton>
            <Typography component={'div'} className='d-flex-a'>
              {(props.path === EMPLOYEEENGAGE || props.path === EMPLOYEEENGAGELIST) && props.path === EMPLOYEEENGAGE ? (
                <Typography component={'div'} className='d-flex-a emp-search-option-tab'>
                  <EmployeeSearchTextBox />
                </Typography>
                  ) : (
                    ''
                  )}                 

              {props.path === EMPLOYEEENGAGE || props.path === EMPLOYEEENGAGELIST ? (
                <Typography component={'div'} className='d-flex-a view-option-tab'>
                  <Typography
                    className={props.path === EMPLOYEEENGAGELIST ? 'tab-btn active' : 'tab-btn'}
                    onClick={() => gotoNavigate(EMPLOYEEENGAGELIST)}>
                    List
                  </Typography>
                  <Typography
                    onClick={() => gotoNavigate(EMPLOYEEENGAGE)}
                    className={props.path === EMPLOYEEENGAGE ? 'tab-btn active' : 'tab-btn'}>
                    Org Chart
                  </Typography>
                </Typography>
              ) : (
                ''
              )}
              {/* <Typography className='search d-flex-a'>
                <img src={SearchIcon} alt='search' />
              </Typography> */}
              <Typography>
                <Badge
                  badgeContent={Number(
                    Number(codeListAndJob.codeList.length) +
                      Number(codeListAndJob.jobList.length) +
                      Number(codeListAndJob.companyList.length) +
                      Number(codeListAndJob.directSuperVisorList.length),
                  )}
                  color='error'>
                  <Button
                    sx={{ padding: ' 0px', minWidth: 'unset' }}
                    aria-describedby={id}
                    onClick={handleClick}>
                    <img src={FilterIcon} alt='Filter' />
                  </Button>
                </Badge>
                <Popover
                  className='filter-popup'
                  id={id}
                  open={filteropen}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}>
                  <Typography className='arrow-top' />
                  <Typography component={'div'} className='d-flex-a flex-sb mb-7 pr-16'>
                    <Typography className='filter-text'>
                      Filter
                      <Typography className='filter-text-grey'>
                        Note: Filter applies to all pages
                      </Typography>
                    </Typography>
                    <Typography className='reset-text' onClick={() => resetFilter()}>
                      Reset
                    </Typography>
                  </Typography>
                  <Typography component={'div'} className='scroll-box'>
                    <form className='private-form'>
                      <Typography component={'div'} className='custom-field'>
                        <Button
                          className='select-button-filter'
                          id='lock-button'
                          aria-haspopup='listbox'
                          aria-controls='lock-menu'
                          onClick={(e) =>
                            handleClickListItem(e, 'company', classificationCodeAndJob.companyList)
                          }
                          aria-expanded={selectFilterOpen ? 'true' : undefined}>
                          <Typography component={'div'} className='filter-select-title'>
                            Company
                          </Typography>
                          <Typography component={'div'} className='filter-select-count'>
                            {codeListAndJob.companyList.length ? (
                              <Typography component={'div'} className='filter-select-count-text'>
                                ({codeListAndJob.companyList.length})
                              </Typography>
                            ) : (
                              <Typography />
                            )}
                            <KeyboardArrowDownIcon
                              className='select-arrow'
                              {...props}
                              sx={{ marginRight: '10px' }}
                            />
                          </Typography>
                        </Button>
                      </Typography>
                      <Typography component={'div'} className='custom-field'>
                        <Button
                          className='select-button-filter'
                          id='lock-button'
                          aria-haspopup='listbox'
                          aria-controls='lock-menu'
                          onClick={(e) =>
                            handleClickListItem(
                              e,
                              'directSupervisor',
                              classificationCodeAndJob.directSuperVisorList,
                            )
                          }
                          aria-expanded={selectFilterOpen ? 'true' : undefined}>
                          <Typography component={'div'} className='filter-select-title'>
                            Direct Supervisor
                          </Typography>
                          <Typography component={'div'} className='filter-select-count'>
                            {codeListAndJob.directSuperVisorList.length ? (
                              <Typography component={'div'} className='filter-select-count-text'>
                                ({codeListAndJob.directSuperVisorList.length})
                              </Typography>
                            ) : (
                              <Typography />
                            )}
                            <KeyboardArrowDownIcon
                              className='select-arrow'
                              {...props}
                              sx={{ marginRight: '10px' }}
                            />
                          </Typography>
                        </Button>
                      </Typography>
                      <Typography component={'div'} className='custom-field'>
                        <Button
                          className='select-button-filter'
                          id='lock-button'
                          aria-haspopup='listbox'
                          aria-controls='lock-menu'
                          onClick={(e) =>
                            handleClickListItem(e, 'job', classificationCodeAndJob.jobList)
                          }
                          aria-expanded={selectFilterOpen ? 'true' : undefined}>
                          <Typography component={'div'} className='filter-select-title'>
                            Job
                          </Typography>
                          <Typography component={'div'} className='filter-select-count'>
                            {codeListAndJob.jobList.length ? (
                              <Typography component={'div'} className='filter-select-count-text'>
                                ({codeListAndJob.jobList.length})
                              </Typography>
                            ) : (
                              <Typography />
                            )}
                            <KeyboardArrowDownIcon
                              className='select-arrow'
                              {...props}
                              sx={{ marginRight: '10px' }}
                            />
                          </Typography>
                        </Button>
                      </Typography>
                      {(classificationCodeAndJob?.codeList
                        ? classificationCodeAndJob.codeList
                        : []
                      ).map((item: any, index: number) => {
                        const optionList = classList.filter(
                          (row: any) => row.class === item.classificationCode,
                        );
                        const valuesLength = codeListAndJob.codeList.filter(
                          (itemData: any) => itemData.class === item.classificationCode,
                        );
                        return (
                          <Typography key={index} component={'div'} className='custom-field'>
                            <Button
                              className='select-button-filter'
                              id='lock-button'
                              aria-haspopup='listbox'
                              aria-controls='lock-menu'
                              onClick={(e) => handleClickListItem(e, 'class', optionList)}
                              aria-expanded={selectFilterOpen ? 'true' : undefined}>
                              <Typography component={'div'} className='filter-select-title'>
                                {item?.mapping}
                              </Typography>
                              <Typography component={'div'} className='filter-select-count'>
                                {valuesLength.length ? (
                                  <Typography
                                    component={'div'}
                                    className='filter-select-count-text'>
                                    ({valuesLength.length})
                                  </Typography>
                                ) : (
                                  <Typography />
                                )}
                                <KeyboardArrowDownIcon
                                  className='select-arrow'
                                  {...props}
                                  sx={{ marginRight: '10px' }}
                                />
                              </Typography>
                            </Button>
                          </Typography>
                        );
                      })}
                      <Button className='submit-btn' onClick={() => applyFilter()}>
                        APPLY
                      </Button>
                    </form>
                  </Typography>
                </Popover>
              </Typography>
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      <Drawer variant='permanent' open={open} className={open ? 'normalside' : 'smallside'}>
        <DrawerHeader
          className='draw-header'
          sx={{ marginTop: userDetail?.isImpersonate ? '13px' : '0px' }}>
          <Typography className='headerlogo-text d-flex-a'>
            <img src={Fulllogo} alt='SimplifiedHR' />
          </Typography>
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              bottom: '16vh',
              position: 'fixed',
              left: '224px',
              ...(open ? { display: 'block' } : { display: 'none' }),
            }}
            className='collapse-btn'>
            {theme.direction === 'ltr' ? (
              <Avatar sx={drawerStyle.iconMenu} variant='square' alt='SHR' src={MenuLogo} />
            ) : (
              <></>
            )}
          </IconButton>
        </DrawerHeader>
        {open ? (
          <Typography component={'div'} className='headerlogo-text d-flex-a portal-name'>
            Client Portal
          </Typography>
        ) : (
          ''
        )}
        <Divider sx={{ background: ' rgba(177,180,190,0.22)' }} />
        <List>
          <ListItem className='profile-item' sx={{ cursor: 'pointer' }} onClick={handleProfileOpen}>
            <ListItemAvatar sx={{ textTransform: 'uppercase' }}>
              {clientList?.imageId ? (
                <Avatar
                  src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${clientList?.imageId}`}
                />
              ) : (
                <Avatar>
                  {clientList?.firstName
                    ? `${clientList?.firstName?.charAt(0)}${clientList?.lastName?.charAt(0)}`
                    : ''}
                </Avatar>
              )}
            </ListItemAvatar>
            <ListItemText
              sx={{ textTransform: 'capitalize' }}
              primary={userDetail?.isImpersonate ? 'Impersonating' : 'Welcome'}
              secondary={
                <>
                  <Typography>
                    {clientList?.employeeName ? clientList?.employeeName : ''}
                  </Typography>
                  <Typography className='company-name'>{clientList?.clientName}</Typography>
                </>
              }
            />
          </ListItem>
          {R.findIndex(R.propEq(1, 'screenId'))(screenList ? screenList : []) !== -1 && (
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Link to={DASHBOARD} className={'sidebarLink'}>
                <ListItemButton
                  className={props.path === DASHBOARD ? 'sideBarActive' : ''}
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='Dashboard'
                      src={props.path === DASHBOARD ? DashboardActive : DashboardNormal}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Dashboard'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          )}
          {R.findIndex((t: any) => t.screenId === 2 && t.isAccessible === true)(
            screenList ? screenList : [],
          ) !== -1 && (
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Link to={ORGANIZATION} className={'sidebarLink'}>
                <ListItemButton
                  className={props.path === ORGANIZATION ? 'sideBarActive' : ''}
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='My Organization'
                      src={
                        props.path === ORGANIZATION ? NavNotificationActive : NavNotificationNormal
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Organizational Overview'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          )}
          {R.findIndex((t: any) => t.screenId === 3 && t.isAccessible === true)(
            screenList ? screenList : [],
          ) !== -1 && (
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Link to={EMPLOYEEENGAGE} className={'sidebarLink'}>
                <ListItemButton
                  className={
                    props.path === EMPLOYEEENGAGE || props.path === EMPLOYEEENGAGELIST
                      ? 'sideBarActive'
                      : ''
                  }
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='Facilities'
                      src={
                        props.path === EMPLOYEEENGAGE || props.path === EMPLOYEEENGAGELIST
                          ? NavTodoActive
                          : NavTodoNormal
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Employee Engagement'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          )}
          {R.findIndex((t: any) => t.screenId === 4 && t.isAccessible === true)(
            screenList ? screenList : [],
          ) !== -1 && (
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Link to={RECRUITING} className={'sidebarLink'}>
                <ListItemButton
                  className={props.path === RECRUITING ? 'sideBarActive' : ''}
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='Standards'
                      src={
                        props.path === RECRUITING
                          ? NavServiceRequestActive
                          : NavServiceRequestNormal
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Recruiting'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          )}
          {R.findIndex((t: any) => t.screenId === 5 && t.isAccessible === true)(
            screenList ? screenList : [],
          ) !== -1 && (
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Link to={PERFORMANCE} className={'sidebarLink'}>
                <ListItemButton
                  className={props.path === PERFORMANCE ? 'sideBarActive' : ''}
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='Scenarios'
                      src={props.path === PERFORMANCE ? NavPerformanceActive : NavPerformanceNormal}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Performance & Comp'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          )}
          {R.findIndex((t: any) => t.screenId === 6 && t.isAccessible === true)(
            screenList ? screenList : [],
          ) !== -1 && (
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Link to={DEI} className={'sidebarLink'}>
                <ListItemButton
                  className={props.path === DEI ? 'sideBarActive' : ''}
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='Scenarios'
                      src={props.path === DEI ? NavDEIActive : NavDEINormal}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'DE&I'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          )}
          {R.findIndex((t: any) => t.screenId === 7 && t.isAccessible === true)(
            screenList ? screenList : [],
          ) !== -1 && (
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Link to={LEARNDEVLOPMENT} className={'sidebarLink'}>
                <ListItemButton
                  className={props.path === LEARNDEVLOPMENT ? 'sideBarActive' : ''}
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='LEARNDEVLOPMENT'
                      src={
                        props.path === LEARNDEVLOPMENT
                          ? NavLearningDevelopmentActive
                          : NavLearningDevelopmentNormal
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Learning & Development'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          )}
          {R.findIndex((t: any) => t.moduleId === 2 && t.isAccessible === true)(
            screenList ? screenList : [],
          ) !== -1 && (
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Link to={REPORTS} className={'sidebarLink'}>
                <ListItemButton
                  className={props.path === REPORTS ? 'sideBarActive' : ''}
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='REPORTS'
                      src={props.path === REPORTS ? NavReportsActive : NavReportsNormal}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Reports'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          )}
          {R.findIndex((t: any) => t.screenId === 8 && t.isAccessible === true)(
            screenList ? screenList : [],
          ) !== -1 && (
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Link to={ACCESSMANAGEMENT.EMPLOYEES} className={'sidebarLink'}>
                <ListItemButton
                  className={
                    props.path === ACCESSMANAGEMENT.EMPLOYEES ||
                    props.path === ACCESSMANAGEMENT.ROLESPERMISSIONS
                      ? 'sideBarActive'
                      : ''
                  }
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='Scenarios'
                      src={
                        props.path === ACCESSMANAGEMENT.EMPLOYEES ||
                        props.path === ACCESSMANAGEMENT.ROLESPERMISSIONS
                          ? NavAccessManagementActive
                          : NavAccessManagementNormal
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Access Management'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          )}
          <Typography className='set-diveder' />
          <ListItem disablePadding sx={{ display: 'block' }}>
            <Link to={SETTINGS} className={'sidebarLink'}>
              <ListItemButton
                className={props.path === SETTINGS ? 'sideBarActive' : ''}
                sx={{
                  ...drawerStyle.listButton,
                  justifyContent: open ? 'initial' : 'center',
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}>
                  <Avatar
                    sx={drawerStyle.icon}
                    variant='square'
                    alt='SETTINGS'
                    src={props.path === SETTINGS ? NavSettingsActive : NavSettingsNormal}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={'Settings'}
                  sx={{
                    opacity: open ? 1 : 0,
                    '& .MuiTypography-root': drawerStyle.text,
                  }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
        {userDetail?.isImpersonate ? (
          <Typography />
        ) : (
          <Box component='div' className='sidebar-footer'>
            <List>
              <Typography className='set-diveder' />
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  onClick={() => setLogoutOpen(true)}
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? 'initial' : 'center',
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Avatar
                      sx={drawerStyle.icon}
                      variant='square'
                      alt='Scenarios'
                      src={NavLogout}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Logout'}
                    sx={{
                      opacity: open ? 1 : 0,
                      '& .MuiTypography-root': drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        )}
      </Drawer>
      <Box
        component='main'
        sx={{ flexGrow: 1, marginTop: userDetail?.isImpersonate ? '30px' : '0px' }}
        className={open ? 'contant-bgcolor piec' : 'contant-bgcolor pieo'}>
        <DrawerHeader />
        {props.children}
      </Box>
      <Modal
        className='profile-modal'
        open={profileopen}
        onClose={handleProfileClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box component='div' sx={style}>
          <Typography component={'div'} className='d-flex-a flex-sb'>
            <Typography className='p-title'>Profile Information</Typography>
            <Typography className='close-text' onClick={handleProfileClose}>
              Close
            </Typography>
          </Typography>
          <Typography
            component={'div'}
            className='mt-20'
            sx={{ display: 'flex', marginBottom: '14px' }}>
            <Typography component={'div'}>
              {localImage ? (
                <img className='profile-default d-flex-ja' src={localImage} />
              ) : clientList?.imageId ? (
                <img
                  className='profile-default d-flex-ja'
                  src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${clientList?.imageId}`}
                />
              ) : (
                <Typography
                  className='profile-default d-flex-ja'
                  sx={{ textTransform: 'uppercase' }}>{`${clientList?.firstName?.charAt(
                  0,
                )}${clientList?.lastName?.charAt(0)}`}</Typography>
              )}
              <Typography className='d-flex-ja'>
                <Button component='label' className='add-photo text-center'>
                  <input
                    hidden
                    type='file'
                    accept='image/*'
                    onChange={(e) => updatePhoto(e)}
                    onClick={(event: any) => {
                      event.target.value;
                    }}
                  />
                  + Photo
                </Button>
                <span className='add-photo' style={{ marginTop: '10px !important' }}>
                  <Tooltip
                    title={
                      <Typography className='tooltip-text'>
                        Maximum file size should be 10 MB
                      </Typography>
                    }
                    arrow
                    placement='bottom-start'>
                    <InfoIcon fontSize='small' />
                  </Tooltip>
                </span>
              </Typography>
            </Typography>
            <Typography component={'div'} className='pl-32 flex-1'>
              <Grid container>
                <Grid item md={3} sm={12} xs={12}>
                  <Typography className='labels'>Name</Typography>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <Typography className='details'>{clientList?.employeeName}</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={3} sm={12} xs={12}>
                  <Typography className='labels'>Email</Typography>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <Typography className='details'>{clientList?.workEmail}</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={3} sm={12} xs={12}>
                  <Typography className='labels'>Role</Typography>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <Typography className='details'>{clientList?.roleName}</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={3} sm={12} xs={12}>
                  <Typography className='labels'>Department</Typography>
                </Grid>
                <Grid item md={9} sm={12} xs={12}>
                  <Typography className='details'>{clientList?.jobTitle}</Typography>
                </Grid>
              </Grid>
            </Typography>
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={logoutOpen}
        onClose={() => setLogoutOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box component='div' sx={logoutStyle}>
          <Typography className='d-flex-a flex-sb'>
            <Typography className='modal-title'>Logout</Typography>
          </Typography>
          <Typography className='modal-dis'>Are you sure you want to logout?</Typography>
          <Typography component={'div'} className='d-flex-a pt-15' columnGap='5px'>
            <Button className='modal-no-btn' onClick={() => setLogoutOpen(false)}>
              NO, CANCEL
            </Button>
            <Button className={'modal-yes-btn'} onClick={logoutFunction}>
              YES, Logout
            </Button>
          </Typography>
        </Box>
      </Modal>
      <Menu
        id='lock-menu'
        anchorEl={anchorElSelectFilter}
        open={selectFilterOpen}
        sx={{
          '& .MuiPaper-root': { width: '290px !important', marginTop: '5px' },
          '& ul': {
            maxHeight: '300px',
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setAnchorElSelectFilter(null);
          setInputValue('');
        }}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}>
        {filteredData.optionList && filteredData.optionList.length ? (
          <>
            <Typography component={'div'} sx={{ margin: '10px', marginTop: '0px' }}>
              <TextField
                type={'search'}
                fullWidth
                variant='standard'
                placeholder={'Search'}
                autoFocus={true}
                onKeyDown={(e: any) => e.stopPropagation()}
                className='input-field'
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Typography>
            {filteredData.optionList.map((item: any, index: number) => {
              let findArray = -1;
              if (filteredData.type === 'job') {
                findArray = codeListAndJob.jobList.findIndex((row: any) => row === item.jobCode);
              } else if (filteredData.type === 'company') {
                findArray = codeListAndJob.companyList.findIndex(
                  (row: any) => row === item.company,
                );
              } else if (filteredData.type === 'directSupervisor') {
                findArray = codeListAndJob.directSuperVisorList.findIndex(
                  (row: any) => row === item.directSupervisorEmployeeId,
                );
              } else {
                findArray = codeListAndJob.codeList.findIndex(
                  (row: any) => row.id === item.id && row.class === item.class,
                );
              }
              let show: boolean = true;
              if (debouncedValue) {
                show = (
                  filteredData.type === 'job'
                    ? item?.jobTitle
                      ? item.jobTitle.toLowerCase()
                      : ''
                    : filteredData.type === 'company'
                      ? item?.company
                        ? item.company.toLowerCase()
                        : ''
                      : filteredData.type === 'directSupervisor'
                        ? item?.directSupervisor
                          ? item.directSupervisor.toLowerCase()
                          : ''
                        : item?.description
                          ? item.description.toLowerCase()
                          : ''
                ).includes(debouncedValue.toLowerCase());
              }
              if (show) {
                return (
                  <MenuItem
                    key={index}
                    selected={findArray !== -1}
                    sx={{
                      marginTop: '1px',
                      backgroundColor: findArray !== -1 ? '#E0ECFF !important' : 'inherit',
                    }}
                    onClick={() => {
                      const data =
                        filteredData.type === 'job'
                          ? codeListAndJob.jobList
                          : filteredData.type === 'company'
                            ? codeListAndJob.companyList
                            : filteredData.type === 'directSupervisor'
                              ? codeListAndJob.directSuperVisorList
                              : codeListAndJob.codeList;
                      if (findArray === -1) {
                        data.push(
                          filteredData.type === 'job'
                            ? item?.jobCode
                            : filteredData.type === 'company'
                              ? item?.company
                              : filteredData.type === 'directSupervisor'
                                ? item?.directSupervisorEmployeeId
                                : item,
                        );
                      } else {
                        data.splice(findArray, 1);
                      }
                      if (filteredData.type === 'job') {
                        setCodeListAndJob({
                          ...codeListAndJob,
                          jobList: [...data],
                        });
                      } else if (filteredData.type === 'company') {
                        setCodeListAndJob({
                          ...codeListAndJob,
                          companyList: [...data],
                        });
                      } else if (filteredData.type === 'directSupervisor') {
                        setCodeListAndJob({
                          ...codeListAndJob,
                          directSuperVisorList: [...data],
                        });
                      } else {
                        setCodeListAndJob({
                          ...codeListAndJob,
                          codeList: [...data],
                        });
                      }
                    }}>
                    {filteredData.type === 'job'
                      ? item?.jobTitle
                      : filteredData.type === 'company'
                        ? item?.companyName
                        : filteredData.type === 'directSupervisor'
                          ? item?.directSupervisor
                          : item?.description}
                    {findArray !== -1 && (
                      <Typography component={'div'} sx={{ position: 'absolute', right: 8 }}>
                        <CheckIconClassCode sx={{ color: '#0061ff', fontSize: 18 }} />
                      </Typography>
                    )}
                  </MenuItem>
                );
              }
              return <Typography key={index} />;
            })}
          </>
        ) : (
          <MenuItem disabled>No Records Found</MenuItem>
        )}
      </Menu>
    </Box>
    </FilterDataContextProvider>
  );
};

export default PrivateLayout;
