import {
  Box,
  Drawer,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Typography,
  Tooltip as TooltipMUI,
} from '@mui/material';
// import PageLoader from 'components/PageLoader';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RepeatOnIcon from '@mui/icons-material/RepeatOn';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import React, { useEffect, useRef } from 'react';
import { getAllListData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { COLORS, DASHBOARD, ORGANIZTION_DASHBOARD } from 'utils/Constant';
import moment from 'moment';
import * as R from 'ramda';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ScreenLoader from 'components/ScreenLoader';
import domtoimage from 'dom-to-image';
import fileDownload from 'js-file-download';
import jsPDF from 'jspdf';
import Logo from 'assets/png/LogoDarkPng.png';
import ViewIcon from 'assets/png/View.svg';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  LabelList,
  Legend,
  LineChart,
  Line,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import InfoIcon from 'assets/png/info.png';
import { DataSet } from 'vis-data';
import { Network } from 'vis-network';
import { To, useNavigate } from 'react-router-dom';
import { PrivateRoutes as PrivateRoutesDirectives } from 'routes/routeConstans';
import { EyeToolTipTextOverAll, LastYearData } from 'utils/Config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  height: 400,
  minWidth: 650,
  borderRadius: '10px',
  boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
};

const DashBoard = () => {
  const { ORGANIZATION, EMPLOYEEENGAGE, RECRUITING } = PrivateRoutesDirectives;
  const navigate: any = useNavigate();
  const chartRef: any = useRef<HTMLDivElement>(null);
  const downloadRef0: any = useRef(null);
  const downloadRef1: any = useRef(null);
  const downloadRef2: any = useRef(null);
  const downloadRef3: any = useRef(null);
  const {
    userInfo: userDetail,
    profileInfo,
    menuList: screenList,
  }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });
  // const [loader, setLoader] = React.useState<boolean>(true);
  const [headCount, setHeadCount] = React.useState<any>({});
  const [eventList, setEventList] = React.useState<any>([]);
  const [hiresTermCount, setHiresTermCount] = React.useState<any>({});
  const [engagementScore, setEngagementScore] = React.useState<any>({});
  const [openRequisitionList, setOpenRequisitionList] = React.useState<{
    loading: boolean;
    data: any;
  }>({
    loading: true,
    data: [],
  });
  const [empEngageeMent, setEmpEngageeMent] = React.useState<{
    loading: boolean;
    nodes: any;
    edges: any;
  }>({
    loading: true,
    nodes: [],
    edges: [],
  });
  const [turnOverList, setTurnOverList] = React.useState<{
    loading: boolean;
    data: any;
  }>({
    loading: true,
    data: [],
  });

  const [showAllEvents, setShowAllEvents] = React.useState<any>([]);
  const [getEngagementCount, setGetEngagementCount] = React.useState<any>([]);
  const initialEventsToShow = 2;

  const toggleShowAllEvents = (index: number) => {
    setShowAllEvents((prevState: any) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const getApi = async () => {
    getAllListData(`${DASHBOARD.HeadCount}/${userDetail.ClientId}/${userDetail?.Id}`).then(
      (res) => {
        setHeadCount(res?.data);
      },
    );
    getAllListData(`${DASHBOARD.HiresTerm}/${userDetail.ClientId}/${userDetail?.Id}`).then(
      (res) => {
        setHiresTermCount(res?.data);
      },
    );
    getAllListData(`${DASHBOARD.EngagementScore}/${userDetail.ClientId}/${userDetail?.Id}`).then(
      (res) => {
        setEngagementScore(res?.data);
      },
    );
    getAllListData(`${DASHBOARD.Calendar}/${userDetail.ClientId}/${userDetail?.Id}`).then((res) => {
      setEventList(res?.data);
    });
    // setLoader(false);
    getAllListData(`${DASHBOARD.GetTurnover}/${userDetail.ClientId}/${userDetail?.Id}`)
      .then((res: any) => {
        const filterData = (res?.data?.currentYear ? res.data.currentYear : []).map(
          (item: any, index: number) => {
            const row: any = {};
            row['yearMonth'] = item.yearMonth;
            row['yearMonthName'] = moment(item.yearMonth).format('MMM');
            row['Voluntary'] = 0;
            row['Involuntary'] = 0;
            row['lastYearMonth'] = res?.data?.lastYear?.[index]?.yearMonth;
            row['lastYearMonthName'] = moment(res?.data?.lastYear?.[index]?.yearMonth).format(
              'MMM',
            );
            row['lastVoluntary'] = 0;
            row['lastInvoluntary'] = 0;
            item.termTypes.map((row1: any) => {
              row[row1.termType] = row1.termTypeCount;
            });
            res?.data?.lastYear?.[index]?.termTypes.map((row1: any) => {
              row['last' + row1.termType] = row1.termTypeCount;
            });
            return row;
          },
        );
        setTurnOverList((_prev) => {
          return { ..._prev, loading: false, data: filterData };
        });
      })
      .catch(() => {
        setModalData((_prev) => {
          return { ..._prev, loading: false };
        });
      });
    getAllListData(`${DASHBOARD.OpenRequisition}/${userDetail.ClientId}/${userDetail?.Id}`)
      .then((res: any) => {
        const result = R.sortWith([R.descend(R.compose(R.prop('total')))]);
        setOpenRequisitionList((_prev) => {
          return { ..._prev, loading: false, data: result(res?.data ? res?.data : []) };
        });
      })
      .finally(() => {
        setOpenRequisitionList((_prev) => {
          return { ..._prev, loading: false };
        });
      });
  };

  useEffect(() => {
    getAllListData(`${DASHBOARD.NetWorkGraph}/${userDetail?.ClientId}/${userDetail?.Id}`)
      .then((resp: any) => {
        if (resp?.data?.networkGraph) {
          const companyData: any = [
            {
              id: 'rootNode',
              label: profileInfo?.clientName,
              widthConstraint: { minimum: 100, maximum: 100 },
              shape: 'circle',
            },
          ];
          const edgesData: any = [];
          resp?.data?.networkGraph.map((item: any, index: number) => {
            const colorCode =
              item?.modelStatus === 'Good Standing'
                ? '#344A5C'
                : item?.modelStatus === 'Needs Attention'
                  ? '#C60C68'
                  : item?.modelStatus === 'On the Move'
                    ? '#C3D34F'
                    : '#CCCDD2';
            companyData.push({
              id: index.toString(),
              widthConstraint: { minimum: 100, maximum: 150 },
              color: {
                background: colorCode,
                border: colorCode,
                highlight: {
                  border: colorCode,
                  background: colorCode,
                },
                hover: {
                  border: colorCode,
                  background: colorCode,
                },
              },
              // heightConstraint: { minimum: 20, valign: 'bottom' },
              shape: 'box',
              label: item.classCode1Description,
              ...item,
            });
            edgesData.push({
              id: index,
              from: 'rootNode',
              to: index,
            });
          });
          setEmpEngageeMent((_prev) => {
            return {
              ..._prev,
              loading: false,
              nodes: new DataSet(companyData),
              edges: new DataSet(edgesData),
            };
          });
        } else {
          setEmpEngageeMent((_prev) => {
            return { ..._prev, loading: false };
          });
        }
      })
      .catch(() => {
        setEmpEngageeMent((_prev) => {
          return { ..._prev, loading: false };
        });
      });
  }, [profileInfo]);

  useEffect(() => {
    getApi();
    getMagic();
  }, [userDetail.ClientId]);

  const getMagic = () => {
    getAllListData(`${DASHBOARD.EngagementCount}/${userDetail.ClientId}/${userDetail?.Id}`).then(
      (resp) => {
        setGetEngagementCount(resp?.data);
      },
    );
  };
  useEffect(() => {
    if (empEngageeMent?.nodes?.length && empEngageeMent?.edges?.length) {
      const data = {
        nodes: empEngageeMent?.nodes,
        edges: empEngageeMent?.edges,
      };
      const options = {
        interaction: {
          // dragNodes: false,
          // dragView: false,
          // keyboard: false,
          zoomView: false,
        },
        nodes: {
          shadow: true,
          borderWidth: 5,
          color: {
            border: '#CCCDD2',
            background: '#CCCDD2',
            // highlight: { border: '#3441d2', background: '#3441d2' },
            // hover: { border: '#3441d2', background: '#3441d2' },
          },
          font: {
            size: 15,
            color: '#fff',
            // strokeWidth: 3,
          },
        },
        edges: {
          shadow: true,
          color: '#171A33',
          arrows: {
            to: {
              enabled: true,
              type: 'circle',
              scaleFactor: 0.3,
            },
          },
        },
      };
      const network = new Network(chartRef.current, data, options);
      network.on('click', (event: any) => {
        const [clickedNode] = event.nodes;
        if (clickedNode) {
          const nodeItem = empEngageeMent?.nodes.get(clickedNode);
          if (nodeItem.id !== 'rootNode') {
            getAllListData(
              `${DASHBOARD.EngagementCount}/${userDetail.ClientId}/${userDetail?.Id}/${nodeItem.classCodeId}`,
            ).then((resp) => {
              setGetEngagementCount(resp?.data);
            });
          }
        }
      });
    }
  }, [empEngageeMent.edges, empEngageeMent.nodes]);

  const [drawer, setDrawer] = React.useState<boolean>(false);
  const handleToggle = () => {
    setDrawer(!drawer);
  };
  const currentDate = moment().format('MM-DD');
  const currentDateEvent =
    eventList.find((item: any) => item.value === currentDate)?.events?.length || 0;

  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const [hideIcon, setHideIcon] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dwnldOption, setDwnldOption] = React.useState<{
    name: string;
    idx: number;
  }>({
    name: '',
    idx: 0,
  });
  const open = Boolean(anchorEl);

  const handleDownLoadClose = () => {
    setAnchorEl(null);
    setDwnldOption({ name: '', idx: 0 });
  };
  const handleDownload = (type: string, name: string, idx: number) => {
    setHideIcon(true);
    const refOption =
      idx === 0
        ? downloadRef0.current
        : idx === 1
          ? downloadRef1.current
          : idx === 2
            ? downloadRef2.current
            : idx === 3
              ? downloadRef3.current
              : '';
    if (type === 'img') {
      domtoimage.toBlob(refOption).then(function (blob) {
        fileDownload(
          blob,
          `${name}-${profileInfo?.clientName}-${moment().format('MM/DD/YYYY')}.png`,
        );
        setHideIcon(false);
      });
    } else {
      domtoimage.toBlob(refOption).then(function (blob) {
        const newImg = new Image();
        newImg.onload = function () {
          const height = newImg.height;
          const width = newImg.width;
          const LogoSrc: any = newImg.src;
          const doc = new jsPDF();
          doc.setFontSize(10);
          doc.text(`Downloaded Date: ${moment().format('MM-DD-YYYY')}`, 10, 10);
          doc.text(`Company Name: ${profileInfo?.clientName}`, 10, 18);
          doc.addImage(
            LogoSrc,
            'png',
            30,
            30,
            width / 4 > 150 ? 150 : width / 4,
            height / 4 > 287 ? 250 : height / 4,
          );
          const img = new Image();
          const src = Logo;
          img.src = src;
          doc.addImage(img, 'png', 100, 287, 40, 5);
          doc.setFontSize(10);
          doc.text('Powered by ', 80, 290);
          doc.save(`${name}-${profileInfo?.clientName}-${moment().format('MM/DD/YYYY')}.pdf`);
          setHideIcon(false);
        };
        const imgSrc = URL.createObjectURL(blob);
        newImg.src = imgSrc;
      });
    }
    handleDownLoadClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, name: string, idx: number) => {
    setAnchorEl(event.currentTarget);
    setDwnldOption({ name, idx });
  };

  const [modalData, setModalData] = React.useState<{
    open: boolean;
    data: any;
    from: string;
    loading: boolean;
  }>({
    open: false,
    data: [],
    from: '',
    loading: false,
  });
  const handleOpen = (from: string) => {
    setModalData((_prev) => {
      return { ..._prev, open: true, loading: true, from };
    });
    if (from === 'hireTerms') {
      getAllListData(
        `${ORGANIZTION_DASHBOARD.HiresAndTerms}/${userDetail.ClientId}/${moment().format(
          'MM-DD-YYYY',
        )}/${userDetail?.Id}`,
      )
        .then((resp: any) => {
          const data = R.map((row: any) => {
            row['yearMonthName'] = moment(row.yearMonth).format('MMM');
            row['totalTermination'] = Math.abs(row.totalTermination);
            return { ...row };
          }, resp.data);
          setModalData((_prev) => {
            return {
              ..._prev,
              data,
              loading: false,
            };
          });
        })
        .catch(() => {
          setModalData((_prev) => {
            return { ..._prev, loading: false };
          });
        });
    } else if (from === 'headCount') {
      getAllListData(`${DASHBOARD.HeadCountMonthWise}/${userDetail.ClientId}/${userDetail?.Id}`)
        .then((resp: any) => {
          const data = R.map(
            (row: any) => {
              row['yearMonthName'] = moment(row.yearMonth).format('MMM');
              return { ...row };
            },
            resp?.data,
          );
          setModalData((_prev) => {
            return {
              ..._prev,
              data,
              loading: false,
            };
          });
        })
        .catch(() => {
          setModalData((_prev) => {
            return { ..._prev, loading: false };
          });
        });
    } else if (from === 'engagementScore') {
      getAllListData(`${DASHBOARD.EngagementMonth}/${userDetail.ClientId}/${userDetail?.Id}`)
        .then((resp) => {
          const data = R.map(
            (row: any) => {
              row['yearMonthName'] = moment(row.date).format('MMM');
              return { ...row };
            },
            resp?.data,
          );
          setModalData((_prev) => {
            return {
              ..._prev,
              data,
              loading: false,
            };
          });
        })
        .catch(() => {
          setModalData((_prev) => {
            return { ..._prev, loading: false };
          });
        });
    }
  };
  const handleClose = () =>
    setModalData({
      open: false,
      data: [],
      from: '',
      loading: false,
    });

  const candidateScoreLegend = () => {
    return (
      <Typography className='legend-ul'>
        {/* <Typography className='leg-info'></Typography> */}
        {openRequisitionList.data.map((entry: any, index: any) => (
          <li key={`item-${index}`}>
            <Typography component={'div'} className='d-flex flex-sb'>
              <Typography className=' d-flex'>
                <Typography component={'div'}>
                  <Typography
                    component={'div'}
                    sx={{ backgroundColor: `${COLORS[index]}` }}
                    className='legend-dot'
                  />
                </Typography>
                <Typography className='leg-info' sx={{ marginTop: '-5px' }}>
                  {entry?.department}
                </Typography>
              </Typography>
              <Typography className='num-info'>{entry?.total}</Typography>
            </Typography>
          </li>
        ))}
      </Typography>
    );
  };
  const CustomOpenRequisitionTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'} sx={{ color: `${COLORS[payload[0].name]} !important` }}>
            {payload[0].payload.department}: {Math.round(payload[0].payload.total)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  const CustomTurnOverTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'} className='valountry-blue'>
            {`Current Year Voluntary ${moment(payload[0].payload.yearMonth).format('MMM YYYY')}`}:{' '}
            {Math.round(payload[0].payload.Voluntary)}
          </Typography>
          <Typography component={'p'} className='last-valountry-blue'>
            {`Current Year Involuntary ${moment(payload[0].payload.yearMonth).format('MMM YYYY')}`}:{' '}
            {Math.round(payload[0].payload.Involuntary)}
          </Typography>
          <Typography component={'p'} className='in-valountry-green'>
            {`Previous Year Voluntary ${moment(payload[0].payload.lastYearMonth).format(
              'MMM YYYY',
            )}`}
            : {Math.round(payload[0].payload.lastVoluntary)}
          </Typography>
          <Typography component={'p'} className='last-in-valountry-green'>
            {`Previous Year Involuntary ${moment(payload[0].payload.lastYearMonth).format(
              'MMM YYYY',
            )}`}
            : {Math.round(payload[0].payload.lastInvoluntary)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  const CustomLabel = ({ viewBox }: any) => {
    let averageTotal = 0;
    if (openRequisitionList?.data?.length) {
      const getPrice: any = R.prop('total');
      const sumPrices: any = R.reduce(R.add, 0);
      const averagePrice = sumPrices(R.map(getPrice, openRequisitionList?.data));
      averageTotal = Math.round(averagePrice);
    }

    return (
      <text
        x={viewBox.cx}
        y={viewBox.cy - 10}
        fill='#3d405c'
        style={{ outline: 'none !important' }}
        className='recharts-text recharts-label'
        textAnchor='middle'
        dominantBaseline='central'>
        <tspan
          style={{
            outline: 'none !important',
          }}
          alignmentBaseline='middle'
          className={
            String(averageTotal).length > 5 ? 'total-pie total-pie-extra-text' : 'total-pie'
          }>
          {averageTotal}
        </tspan>
        <tspan
          style={{ outline: 'none !important' }}
          x={viewBox.cx}
          y={viewBox.cy + 20}
          className='ave-pie'>
          Total
        </tspan>
      </text>
    );
  };

  const CustomHireAndTermTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'}>{`${moment(payload[0].payload.yearMonth).format(
            'MMMM YYYY',
          )}`}</Typography>
          <Typography component={'p'} className='qns-blue'>
            Hires: {Math.round(payload[0].payload.totalHired)}
          </Typography>
          <Typography component={'p'} className='qns-green'>
            Terminations: {Math.round(payload[0].payload.totalTermination)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };
  const CustomEngagemetTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'}>{`${moment(payload[0].payload.date).format(
            'MMMM YYYY',
          )}`}</Typography>
          <Typography component={'p'} className='view-green'>
            Score: {Math.round(payload[0].payload.engagementScore)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  const CustomHeadCountTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Typography component={'div'} className='custom-tooltip'>
          <Typography component={'p'}>{`${moment(payload[0].payload.yearMonth).format(
            'MMMM YYYY',
          )}`}</Typography>
          <Typography component={'p'} className='qns-blue'>
            Headcount: {Math.round(payload[0].payload.headcount)}
          </Typography>
        </Typography>
      );
    }
    return null;
  };

  const HRmonthLegend = () => {
    return (
      <Typography component={'div'} className='d-flex-a'>
        <Typography component={'div'} className='d-flex-a hr-text mr-10'>
          <Typography className='s-dot' sx={{ backgroundColor: '#3441D2' }} />
          Hires
        </Typography>
        <Typography component={'div'} className='d-flex-a hr-text'>
          <Typography className='s-dot' sx={{ backgroundColor: '#C3D34F' }} />
          Terminations
        </Typography>
      </Typography>
    );
  };

  const HRmonthLegendTurnOver = () => {
    return (
      <Typography component={'div'}>
        <Typography component={'div'} className='d-flex-a' sx={{ paddingRight: '5px' }}>
          <Typography component={'div'} className='d-flex-a hr-text mr-10'>
            <Typography className='s-dot' sx={{ backgroundColor: '#5251B7' }} />
            Current Year Voluntary
          </Typography>
          <Typography component={'div'} className='d-flex-a hr-text mr-10'>
            <Typography className='s-dot' sx={{ backgroundColor: '#C3D34F ' }} />
            Previous Year Voluntary
          </Typography>
        </Typography>
        <Typography component={'div'} className='d-flex-a'>
          <Typography component={'div'} className='d-flex-a hr-text'>
            <Typography className='s-dot' sx={{ backgroundColor: '#172087' }} />
            Current Year Involuntary&nbsp;
          </Typography>
          <Typography component={'div'} className='d-flex-a hr-text'>
            <Typography className='s-dot' sx={{ backgroundColor: '#8F9F18' }} />
            Previous Year Involuntary
          </Typography>
        </Typography>
      </Typography>
    );
  };

  const renderCustomizedLabel = ({ x, y, width, value }: any) => {
    if (value) {
      return (
        <g>
          <text
            x={x + width / 2}
            y={y + 12}
            fill='#fff'
            textAnchor='middle'
            fontSize={'12px'}
            dominantBaseline='middle'>
            {value}
          </text>
        </g>
      );
    }
    return <Typography />;
  };

  const renderCustomizedLabel1 = ({ x, y, width, value }: any) => {
    if (value) {
      return (
        <g>
          <text
            x={x + width / 2}
            y={y + 8}
            fill='#fff'
            textAnchor='middle'
            fontSize={'8px'}
            dominantBaseline='middle'>
            {value}
          </text>
        </g>
      );
    }
    return <Typography />;
  };
  return (
    <Grid container>
      {/* <PageLoader loading={loader} /> */}
      <Grid item md={12} sm={12} xs={12} className='d-flex-j'>
        <Typography component={'div'} className='ld-box' sx={{ width: '95%' }}>
          <Grid container columnSpacing={2.3} rowSpacing={2.3}>
            <Grid item md={3} sm={12} xs={12}>
              <Typography
                component={'div'}
                className='ld-count-box'
                sx={{ cursor: 'pointer' }}
                onClick={() => handleOpen('engagementScore')}>
                <Typography component={'div'} className='d-flex-a mb-18'>
                  <Typography component={'div'}>
                    <Typography className='black-box d-flex-ja'>
                      <RepeatOnIcon className='clock' />
                    </Typography>
                  </Typography>
                  <Typography className='ld-c-title'>Engagement Score</Typography>
                </Typography>
                <Typography className='ld-count'>
                  {engagementScore?.engagementScore ? engagementScore?.engagementScore : '0'}
                </Typography>
                <Typography
                  className={`${
                    Number(engagementScore?.thisMonthVariation) >= 0
                      ? 'ld-green d-flex-a'
                      : 'ld-red d-flex-a'
                  }`}>
                  {(engagementScore?.thisMonthVariation ||
                    engagementScore?.thisMonthVariation == 0) &&
                    (Number(engagementScore?.thisMonthVariation) >= 0 ? (
                      <ArrowUpwardIcon className='up-per' />
                    ) : (
                      <ArrowDownwardIcon className='down-per' />
                    ))}{' '}
                  {engagementScore?.thisMonthCount} this month
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <Typography
                component={'div'}
                className='ld-count-box'
                sx={{ cursor: 'pointer' }}
                onClick={() => handleOpen('headCount')}>
                <Typography component={'div'} className='d-flex-a mb-18'>
                  <Typography component={'div'}>
                    <Typography className='black-box d-flex-ja'>
                      <AccountCircleIcon className='clock' />
                    </Typography>
                  </Typography>
                  <Typography className='ld-c-title'>Headcount</Typography>
                </Typography>
                <Typography className='ld-count'>
                  {headCount?.headCount ? headCount?.headCount : 0}
                </Typography>
                <Typography
                  className={`${
                    Number(headCount?.thisMonthVariation) >= 0
                      ? 'ld-green d-flex-a'
                      : 'ld-red d-flex-a'
                  }`}>
                  {(headCount?.thisMonthVariation || headCount?.thisMonthVariation == 0) &&
                    (Number(headCount?.thisMonthVariation) >= 0 ? (
                      <ArrowUpwardIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    ))}
                  {headCount?.thisMonthCount ? headCount?.thisMonthCount : 0} this month
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <Typography
                component={'div'}
                className='ld-count-box'
                sx={{ cursor: 'pointer' }}
                onClick={() => handleOpen('hireTerms')}>
                <Typography component={'div'} className='d-flex-a mb-18'>
                  <Typography component={'div'}>
                    <Typography className='black-box d-flex-ja'>
                      <AssignmentTurnedInIcon className='clock' />
                    </Typography>
                  </Typography>
                  <Typography className='ld-c-title'>Hires vs Terms</Typography>
                </Typography>
                <Typography className='ld-count'>
                  {hiresTermCount?.hireCount ? hiresTermCount?.hireCount : 0}/
                  {hiresTermCount?.termCount ? hiresTermCount?.termCount : 0}
                </Typography>
                <Typography
                  className={`${
                    Number(hiresTermCount?.variation) >= 0 ? 'ld-green d-flex-a' : 'ld-red d-flex-a'
                  }`}>
                  {(hiresTermCount?.variation || hiresTermCount?.variation == 0) &&
                    (Number(hiresTermCount?.variation) >= 0 ? (
                      <ArrowUpwardIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    ))}
                  {hiresTermCount?.thisMonth ? hiresTermCount?.thisMonth : 0} this month
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <Typography
                component={'div'}
                className='ld-count-box'
                sx={{ cursor: 'pointer' }}
                onClick={handleToggle}>
                <Typography component={'div'} className='d-flex-a mb-18'>
                  <Typography component={'div'}>
                    <Typography className='black-box d-flex-ja'>
                      <CalendarMonthIcon className='clock' />
                    </Typography>
                  </Typography>
                  <Typography className='ld-c-title'>Calendar</Typography>
                </Typography>
                <Typography className='ld-count'>
                  {currentDateEvent} {''}Today
                </Typography>
                <Typography className='d-flex-a total-titles'>
                  {' '}
                  {eventList?.length} in 2 weeks
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
      <Grid item md={12} sm={12} xs={12} className='dashboard-box'>
        <Grid container columnSpacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <Typography
              ref={downloadRef3}
              component={'div'}
              className='pie-box'
              sx={{ minHeight: '620px !important' }}>
              {!hideIcon &&
                R.findIndex((t: any) => t.screenId === 3 && t.isAccessible === true)(
                  screenList ? screenList : [],
                ) !== -1 && (
                  <Typography className='down-box d-flex-ja' sx={{ right: '70px !important' }}>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={() => gotoNavigate(EMPLOYEEENGAGE)}>
                      <img src={ViewIcon} />
                    </IconButton>
                  </Typography>
                )}
              {!hideIcon && empEngageeMent?.nodes?.length ? (
                <Typography className='down-box d-flex-ja'>
                  <IconButton
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(e: any) => handleClick(e, 'Employee Engagement', 3)}>
                    <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                  </IconButton>
                </Typography>
              ) : (
                <Typography />
              )}
              <Typography className='c-title d-flex-a mb-15'>
                Employee Engagement&nbsp;&nbsp;
                {!hideIcon && (
                  <TooltipMUI
                    title={
                      <Typography className='tooltip-text'>
                        {EyeToolTipTextOverAll.dashboard.employeeEngagement}
                      </Typography>
                    }>
                    <img className='info-im' src={InfoIcon} alt='info' />
                  </TooltipMUI>
                )}
              </Typography>
              {empEngageeMent?.loading ? (
                <Typography
                  component={'div'}
                  textAlign={'center'}
                  sx={{
                    paddingTop: '100px',
                  }}>
                  <ScreenLoader loading={empEngageeMent?.loading} />
                </Typography>
              ) : !empEngageeMent?.nodes?.length ? (
                <Typography
                  component={'div'}
                  sx={{ height: '250px', display: 'flex' }}
                  alignItems={'center'}
                  justifyContent={'center'}>
                  No Records Found
                </Typography>
              ) : (
                <Typography
                  component={'div'}
                  style={{ height: 550, width: '100%' }}
                  ref={chartRef}
                />
              )}
              <Grid container columnSpacing={2.3} rowSpacing={2.3} justifyContent={'center'}>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography
                    component={'div'}
                    className='dash-engament-box'
                    sx={{ cursor: 'pointer' }}>
                    <Typography component={'div'} className='d-flex-a mb-18 employee-legent'>
                      <Typography className='opt-box rs' />
                      <Typography className='opt-text2'> On the Move</Typography>
                    </Typography>
                    <Typography
                      component={'div'}
                      style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography className='dash-count'>
                        {getEngagementCount?.onTheMoveCount
                          ? getEngagementCount?.onTheMoveCount
                          : 0}
                      </Typography>
                      <Typography className='d-flex-a' component={'div'}>
                        <ArrowDownwardIcon
                          className={
                            getEngagementCount?.todaysOnTheMoveVariation >= 0
                              ? 'down-per2'
                              : 'up-per2'
                          }
                        />
                        <Typography className='per-txt2'>
                          {getEngagementCount?.todaysOnTheMoveVariation
                            ? getEngagementCount?.todaysOnTheMoveVariation
                            : 0}
                          {/* % */}
                        </Typography>
                      </Typography>
                    </Typography>
                    {/* <Typography></Typography> */}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography
                    component={'div'}
                    className='dash-engament-box'
                    sx={{ cursor: 'pointer' }}>
                    <Typography component={'div'} className='d-flex-a mb-18 employee-legent'>
                      <Typography className='opt-box gs' />
                      <Typography className='opt-text2'> Good Standing</Typography>
                    </Typography>
                    <Typography
                      component={'div'}
                      className='d-felx-je'
                      style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography className='dash-count'>
                        {getEngagementCount?.goodStandingCount
                          ? getEngagementCount?.goodStandingCount
                          : 0}
                      </Typography>
                      <Typography className='d-flex-a' component={'div'}>
                        <ArrowDownwardIcon
                          className={
                            getEngagementCount?.todaysGoodStandingVariation >= 0
                              ? 'down-per2'
                              : 'up-per2'
                          }
                        />
                        <Typography className='per-txt2'>
                          {' '}
                          {getEngagementCount?.todaysGoodStandingVariation
                            ? getEngagementCount?.todaysGoodStandingVariation
                            : 0}
                          {/* % */}
                        </Typography>
                      </Typography>
                    </Typography>
                    {/* <Typography></Typography> */}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography
                    component={'div'}
                    className='dash-engament-box'
                    sx={{ cursor: 'pointer' }}>
                    <Typography component={'div'} className='d-flex-a mb-18 employee-legent'>
                      <Typography className='opt-box na' />
                      <Typography className='opt-text2'> Needs Attention</Typography>
                    </Typography>
                    <Typography
                      component={'div'}
                      className='d-felx-je'
                      style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      <Typography className='dash-count'>
                        {getEngagementCount?.needsAttentionCount
                          ? getEngagementCount?.needsAttentionCount
                          : 0}
                      </Typography>
                      <Typography className='d-flex-a' component={'div'}>
                        <ArrowDownwardIcon
                          className={
                            getEngagementCount?.todaysNeedsAttentionVariation >= 0
                              ? 'down-per2'
                              : 'up-per2'
                          }
                        />
                        <Typography className='per-txt2'>
                          {' '}
                          {getEngagementCount?.todaysNeedsAttentionVariation
                            ? getEngagementCount?.todaysNeedsAttentionVariation
                            : 0}
                          {/* % */}
                        </Typography>
                      </Typography>
                    </Typography>
                    {/* <Typography></Typography> */}
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Typography component={'div'} className='pie-box' ref={downloadRef1}>
              {!hideIcon &&
                R.findIndex((t: any) => t.screenId === 2 && t.isAccessible === true)(
                  screenList ? screenList : [],
                ) !== -1 && (
                  <Typography
                    className='down-box d-flex-ja'
                    sx={{ right: '70px !important', marginRight: '-15px !important' }}>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={() => gotoNavigate(ORGANIZATION)}>
                      <img src={ViewIcon} />
                    </IconButton>
                  </Typography>
                )}
              {!hideIcon && turnOverList?.data?.length ? (
                <Typography className='down-box d-flex-ja' sx={{ right: '6px !important' }}>
                  <IconButton
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(e: any) => handleClick(e, 'Turnover', 1)}>
                    <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                  </IconButton>
                </Typography>
              ) : (
                <Typography />
              )}
              <Typography className='c-title d-flex-a mb-15'>
                Turnover &nbsp;
                {!hideIcon && (
                  <TooltipMUI
                    title={
                      <Typography className='tooltip-text'>
                        {EyeToolTipTextOverAll.dashboard.turnover}
                      </Typography>
                    }>
                    <img className='info-im' src={InfoIcon} alt='info' />
                  </TooltipMUI>
                )}
              </Typography>
              {turnOverList?.loading ? (
                <Typography
                  component={'div'}
                  textAlign={'center'}
                  sx={{
                    paddingTop: '100px',
                  }}>
                  <ScreenLoader loading={turnOverList?.loading} />
                </Typography>
              ) : !turnOverList?.data?.length ? (
                <Typography
                  component={'div'}
                  sx={{ height: '250px', display: 'flex' }}
                  alignItems={'center'}
                  justifyContent={'center'}>
                  No Records Found
                </Typography>
              ) : (
                <Typography component={'div'} className='turnoverChart d-flex'>
                  <ResponsiveContainer width={'100%'} height={320}>
                    <BarChart
                      className='hrmonth-chart'
                      data={turnOverList?.data}
                      stackOffset='sign'
                      margin={{
                        top: 20,
                        right: 0,
                        left: 0,
                        bottom: 0,
                      }}>
                      <CartesianGrid stroke='white' />

                      <XAxis
                        label={{ value: LastYearData, dx: 10, dy: 15 }}
                        fontSize={12}
                        interval={0}
                        dataKey='yearMonthName'
                        height={50}
                        strokeDasharray='0 10'
                      />
                      <YAxis strokeDasharray='0 10' />

                      <Tooltip content={<CustomTurnOverTooltip />} />
                      <Legend verticalAlign='top' align='right' content={HRmonthLegendTurnOver} />
                      <Bar dataKey='Voluntary' stackId='Voluntary' fill='#5251B7'>
                        {hideIcon && (
                          <LabelList dataKey='Voluntary' content={renderCustomizedLabel1} />
                        )}
                      </Bar>
                      <Bar dataKey='Involuntary' stackId='Voluntary' fill='#172087'>
                        {hideIcon && (
                          <LabelList dataKey='Involuntary' content={renderCustomizedLabel1} />
                        )}
                      </Bar>
                      <Bar dataKey='lastVoluntary' stackId='Involuntary' fill='#C3D34F '>
                        {hideIcon && (
                          <LabelList dataKey='lastVoluntary' content={renderCustomizedLabel1} />
                        )}
                      </Bar>

                      <Bar dataKey='lastInvoluntary' stackId='Involuntary' fill='#8F9F18'>
                        {hideIcon && (
                          <LabelList dataKey='lastInvoluntary' content={renderCustomizedLabel1} />
                        )}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Typography>
              )}
            </Typography>
            <Typography component={'div'} className='pie-box' ref={downloadRef2}>
              {!hideIcon &&
                R.findIndex((t: any) => t.screenId === 4 && t.isAccessible === true)(
                  screenList ? screenList : [],
                ) !== -1 && (
                  <Typography className='down-box d-flex-ja' sx={{ right: '70px !important' }}>
                    <IconButton
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={() => gotoNavigate(RECRUITING)}>
                      <img src={ViewIcon} />
                    </IconButton>
                  </Typography>
                )}
              {!hideIcon && openRequisitionList?.data?.length ? (
                <Typography className='down-box d-flex-ja'>
                  <IconButton
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(e: any) => handleClick(e, 'Open Requisitions ', 2)}>
                    <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                  </IconButton>
                </Typography>
              ) : (
                <Typography />
              )}
              <Typography className='c-title d-flex-a mb-15'>
                Open Requisitions &nbsp;
                {!hideIcon && (
                  <TooltipMUI
                    title={
                      <Typography className='tooltip-text'>
                        {EyeToolTipTextOverAll.dashboard.openRequisitions}
                      </Typography>
                    }>
                    <img className='info-im' src={InfoIcon} alt='info' />
                  </TooltipMUI>
                )}
              </Typography>
              {openRequisitionList?.loading ? (
                <Typography
                  component={'div'}
                  textAlign={'center'}
                  sx={{
                    paddingTop: '100px',
                  }}>
                  <ScreenLoader loading={openRequisitionList?.loading} />
                </Typography>
              ) : !openRequisitionList?.data?.length ? (
                <Typography
                  component={'div'}
                  sx={{ height: '250px', display: 'flex' }}
                  alignItems={'center'}
                  justifyContent={'center'}>
                  No Records Found
                </Typography>
              ) : (
                <Typography component={'div'} className='d-flex'>
                  <Typography component={'div'} sx={{ width: '175px', height: 250 }}>
                    <ResponsiveContainer width={'100%'} height={'100%'}>
                      <PieChart>
                        <Tooltip content={<CustomOpenRequisitionTooltip />} />
                        <Pie
                          data={openRequisitionList?.data}
                          cx='50%'
                          cy='50%'
                          innerRadius={60}
                          outerRadius={80}
                          fill='#8884d8'
                          paddingAngle={1}
                          dataKey='total'>
                          {openRequisitionList?.data.map((entry: any, index: number) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                          <Label
                            width={30}
                            position='center'
                            content={<CustomLabel y={130} />}></Label>
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </Typography>
                  <Typography component={'div'} className='flex-1'>
                    {candidateScoreLegend()}
                  </Typography>
                </Typography>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={modalData.open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box className='dash-modal' sx={style}>
          <Typography
            component={'div'}
            sx={{
              background: '#fff',
              width: '100%',
              height: '100%',
              borderRadius: '10px',
              boxShadow: '0 5px 14px 0 rgba(0,0,0,0.22)',
              padding: '10px 25px 15px',
            }}
            ref={downloadRef0}>
            <Grid container>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                justifyContent={'space-between'}
                sx={{ display: 'flex' }}>
                <Typography className='c-title d-flex-a mb-15'>
                  {modalData.from === 'headCount'
                    ? 'Total Headcount'
                    : modalData.from === 'engagementScore'
                      ? 'Engagement Score'
                      : 'Hires vs Terms '}
                </Typography>
                {!hideIcon && modalData?.data?.length ? (
                  <Typography className='down-box d-flex-ja'>
                    <IconButton
                      className='dwdBtn'
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e: any) =>
                        handleClick(
                          e,
                          modalData.from === 'headCount'
                            ? 'HeadCount'
                            : modalData.from === 'hireTerms'
                              ? 'Hires vs Terms'
                              : 'Engagement Score',
                          0,
                        )
                      }>
                      <SaveAltIcon sx={{ fill: '#b1b4be' }} />
                    </IconButton>
                  </Typography>
                ) : (
                  <Typography />
                )}
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                {modalData?.loading ? (
                  <Typography
                    component={'div'}
                    textAlign={'center'}
                    sx={{
                      paddingTop: '100px',
                    }}>
                    <ScreenLoader loading={modalData?.loading} />
                  </Typography>
                ) : !modalData?.data?.length ? (
                  <Typography
                    component={'div'}
                    sx={{ height: '250px', display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    No Records Found
                  </Typography>
                ) : modalData.from === 'headCount' ? (
                  <ResponsiveContainer width={'100%'} height={320}>
                    <BarChart
                      className='hrmonth-chart'
                      data={modalData?.data}
                      stackOffset='sign'
                      margin={{
                        top: 20,
                        right: 0,
                        left: 0,
                        bottom: 0,
                      }}>
                      <CartesianGrid stroke='white' />
                      <XAxis
                        dataKey='yearMonthName'
                        height={60}
                        strokeDasharray='0 10'
                        label={{ value: LastYearData, dx: 10, dy: 15 }}
                        fontSize={12}
                        interval={0}
                      />
                      <YAxis strokeDasharray='0 10' strokeWidth={0.8} />
                      <Tooltip content={<CustomHeadCountTooltip />} />
                      <Bar dataKey='headcount' fill='#C3D34F'>
                        {hideIcon && (
                          <LabelList dataKey='headcount' content={renderCustomizedLabel} />
                        )}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                ) : modalData.from === 'hireTerms' ? (
                  <ResponsiveContainer width={'100%'} height={320}>
                    <BarChart
                      className='hrmonth-chart'
                      data={modalData?.data}
                      stackOffset='sign'
                      margin={{
                        top: 20,
                        right: 0,
                        left: 0,
                        bottom: 0,
                      }}>
                      <CartesianGrid stroke='white' />
                      <XAxis
                        dataKey='yearMonthName'
                        height={60}
                        strokeDasharray='0 10'
                        label={{ value: LastYearData, dx: 10, dy: 15 }}
                        fontSize={12}
                        interval={0}
                      />
                      <YAxis strokeDasharray='0 10' />
                      <Tooltip content={<CustomHireAndTermTooltip />} />
                      <Legend verticalAlign='top' align='right' content={HRmonthLegend} />
                      <Bar dataKey='totalHired' fill='#3441D2'>
                        {hideIcon && (
                          <LabelList dataKey='totalHired' content={renderCustomizedLabel} />
                        )}
                      </Bar>
                      <Bar dataKey='totalTermination' fill='#C3D34F'>
                        {hideIcon && (
                          <LabelList dataKey='totalTermination' content={renderCustomizedLabel} />
                        )}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <ResponsiveContainer width={'100%'} height={300}>
                    <LineChart
                      className='hrmonth-chart'
                      data={modalData?.data}
                      stackOffset='sign'
                      margin={{
                        top: 20,
                        right: 0,
                        left: 0,
                        bottom: 0,
                      }}>
                      <CartesianGrid stroke='white' />
                      <XAxis
                        dataKey='yearMonthName'
                        height={60}
                        strokeDasharray='0 10'
                        padding={{ left: 30, right: 30 }}
                        label={{ value: LastYearData, dx: 10, dy: 15 }}
                        fontSize={12}
                        interval={0}
                      />
                      <YAxis strokeDasharray='0 10' />
                      <Tooltip content={<CustomEngagemetTooltip />} />
                      {/* <Legend verticalAlign='top' align='right' content={HRmonthLegend} /> */}
                      <Line dataKey='engagementScore' stroke='#C3D34F' strokeWidth={3} />
                    </LineChart>
                  </ResponsiveContainer>
                )}
              </Grid>
            </Grid>
          </Typography>
        </Box>
      </Modal>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleDownLoadClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={() => handleDownload('img', dwnldOption.name, dwnldOption.idx)}>
          Image
        </MenuItem>
        <MenuItem onClick={() => handleDownload('pdf', dwnldOption.name, dwnldOption.idx)}>
          PDF
        </MenuItem>
      </Menu>
      <Drawer className='dash-drawer' anchor='right' open={drawer} onClose={handleToggle}>
        <Typography component={'div'} sx={{ marginTop: '25px' }}>
          <Typography component={'div'} className='d-flex-a flex-sb mb-12'>
            <Typography className='dtitle'>Upcoming Events</Typography>
            <Typography className='dclose' onClick={handleToggle}>
              Close
            </Typography>
          </Typography>
          <Typography component={'div'} className='cal-paent'>
            {eventList && eventList?.length ? (
              eventList?.map((row: any, index: any) => (
                <Typography component={'div'} className='citem' key={index}>
                  <Typography className='mb-15'>
                    <Typography className='fnum' component={'span'}>
                      {moment(row.value).format('MM/DD')}
                    </Typography>
                    <Typography className='snum' component={'span'}>
                      ({row?.events?.length})
                    </Typography>
                  </Typography>
                  {row?.events
                    ?.slice(0, showAllEvents[index] ? undefined : initialEventsToShow)
                    .map((data: any, i: any) => (
                      <Typography key={i}>
                        <Typography className='det-info mb-8'>
                          {data?.employeeName} ({data?.event})
                        </Typography>
                      </Typography>
                    ))}

                  <Typography component={'div'} className='d-flex-a flex-end'>
                    {row.events.length > 2 ? (
                      <Typography
                        component={'span'}
                        className='vmore'
                        onClick={() => toggleShowAllEvents(index)}>
                        {showAllEvents[index] ? ' Show Less' : 'Show More'}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Typography>
                </Typography>
              ))
            ) : (
              <Typography
                component={'div'}
                sx={{ height: '400px', display: 'flex' }}
                alignItems={'center'}
                justifyContent={'center'}>
                No Events Found
              </Typography>
            )}
          </Typography>
        </Typography>
      </Drawer>
    </Grid>
  );
};

export default DashBoard;
