import React, { useEffect } from 'react';
import BackImg from 'assets/png/loginbg.jpg';
import Logo from 'assets/png/LogoDark.svg';
import { Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { PublicRoutes } from 'routes/routeConstans';
import PageLoader from 'components/PageLoader';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { addNewData } from 'services/CommonServices';

const ForgotPasswordSuccess = ({ from }: any) => {
  const navigate: any = useNavigate();
  const location: any = useLocation();
  const [email, setEmail] = React.useState<any>('');
  const [client, setClient] = React.useState<any>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const goBackOption = () => {
    if (from === 'client') {
      navigate(PublicRoutes.CLIENTLOGIN);
    } else {
      navigate(PublicRoutes.ADMINLOGIN);
    }
  };

  useEffect(() => {
    if (location?.state?.email) {
      setEmail(location?.state?.email);
      setClient(location?.state?.clientId);
    } else {
      goBackOption();
    }
  }, [location.state]);

  const resetSendLink = () => {
    setLoading(true);
    addNewData(
      {},
      `${MASTER_ENDPOINT.ResetPassword}?toEmailAddress=${email}&isFromSuperAdmin=${
        from === 'admin' ? true : false
      }&clientId=${client ? client : 0}`,
      {},
      true,
    )
      .then(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid container>
      <PageLoader loading={loading} />
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          component={'div'}
          sx={{ backgroundImage: `url(${BackImg})` }}
          className='login-banner'>
          <Typography
            component={'div'}
            className='login-box'
            sx={{ minHeight: '300px !important' }}>
            <Typography className='logo-text d-flex-a'>
              <img src={Logo} alt='simplifiedHR' />
            </Typography>
            <form>
              <Typography component={'p'} className='forgot-pass-heading'>
                Reset Password Email Sent
              </Typography>
              <Typography component={'p'} className='forgot-pass-description'>
                Check your email for the Password Reset link. If you do not receive it in less than
                5 minutes,{' '}
                <Typography
                  component={'span'}
                  sx={{ cursor: 'pointer', textDecoration: 'underLine', color: '#0061FF' }}
                  onClick={resetSendLink}>
                  Resend Link
                </Typography>
              </Typography>
              <Typography className='border-box' />
            </form>
            <Typography component={'p'} textAlign={'center'}>
              <Typography
                component={'span'}
                className='back-to-login'
                onClick={() => goBackOption()}>
                Cancel, Back to Login
              </Typography>
            </Typography>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordSuccess;
