import { Button, Grid, Popover, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import Loader from 'components/Loader';
import moment from 'moment';
import EmployeeHandleOption from './employeeHandleOption';
import { useAppSelector } from 'store/hooks';
import { jwtDecode } from 'jwt-decode';
import { USER_LOGIN_SUCCESS } from 'store/types/UserTypes';
import { useDispatch } from 'react-redux';
import Storage from 'utils/Storage';
import { useNavigate } from 'react-router-dom';
import PageLoader from 'components/PageLoader';
import { PrivateRoutes } from 'routes/routeConstans';
import _ from 'lodash';

const statickParam: any = {
  sortBy: '',
  order: '',
};

type EmployeeProps = {
  clientId: string;
  from?: string;
};

const Employee = (props: EmployeeProps) => {
  const dispatch: any = useDispatch();
  const navigate: any = useNavigate();
  const { clientId, from } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [handleEmployeeOpen, setHandleEmployeeOpen] = React.useState({
    visible: false,
    status: '',
  });
  const [param, setParam] = React.useState<{
    sortBy: string;
    order: 'asc' | 'desc';
  }>(statickParam);
  const [loader, setLoader] = React.useState<boolean>(false);
  const [pageLoader, setPageLoader] = React.useState<boolean>(false);
  const [list, setList] = React.useState<any>([]);
  const [roleList, setRoleList] = React.useState<any>([]);
  const [isRoleDefault, setIsRoleDefault] = React.useState<string>('');
  const [editList, setEditList] = React.useState<any>({});
  const { tableReload, userInfo } = useAppSelector((store: any) => {
    return store.userLogin;
  });

  const handleOption = (sts: string) => {
    setHandleEmployeeOpen({
      visible: true,
      status: sts,
    });
  };

  const hanleEmployeeClose = () => {
    setHandleEmployeeOpen({
      visible: false,
      status: '',
    });
    handleClose();
  };

  const impersonateFunction = () => {
    setPageLoader(true);
    const endPoint = `${MASTER_ENDPOINT.ImpersonateUser}?clientId=${editList.clientID}&userId=${
      editList.workEmail
    }&superUserId=${userInfo.UserId}&isFromSuperAdmin=${from === 'admin' ? true : false}`;
    getAllListData(endPoint)
      .then(async (response: any) => {
        if (response.token) {
          const userData: any = jwtDecode(String(response?.token));
          userData['token'] = response?.token;
          userData['userDetailsData'] = response?.data;
          userData['isAdmin'] = false;
          userData['isClient'] = true;
          userData['isImpersonate'] = true;
          const tokenData: any = {
            refreshToken: '',
            token: response?.token,
            userId: userData.id,
            isAdmin: false,
            isClient: true,
            isImpersonate: true,
            isFromSuperAdmin: userData?.isFromSuperAdmin,
            superUserId: userData?.superUserId,
          };
          Storage.removeItem(Storage.KEYS.AUTH_TOKEN);
          Storage.setItem(Storage.KEYS.AUTH_TOKEN, JSON.stringify(tokenData));
          setTimeout(() => {
            dispatch({
              payload: userData,
              type: USER_LOGIN_SUCCESS,
            });
            navigate(PrivateRoutes.DASHBOARD);
          }, 500);
        }
      })
      .finally(() => {
        setPageLoader(false);
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, editObject: any) => {
    setAnchorEl(event.currentTarget);
    if (from === 'client') {
      if (!editObject?.roleId) {
        // editObject.roleId = isRoleDefault;
        editObject.roleIdNew = isRoleDefault;
      }
    }
    setEditList(editObject);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setEditList({});
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const employeList = () => {
    setLoader(true);
    const endPoint = `${MASTER_ENDPOINT.employeList}/${clientId}/${userInfo?.Id}?order=${param.order}&sortby=${param.sortBy}`;
    getAllListData(endPoint)
      .then((res) => {
        setList(res?.data);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getRoles = () => {
    getAllListData(`${MASTER_ENDPOINT.ClientRole}/${clientId}`).then((res) => {
      setRoleList(res.data);
      if (from === 'client') {
        const fIndex = res.data.findIndex((item: any) => item.isDefault);
        setIsRoleDefault(fIndex !== -1 ? res.data[fIndex].id : '');
      }
    });
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    employeList();
  }, [clientId, tableReload]);

  const changeParam = (keyName: string) => {
    setLoader(true);
    setParam((pre: any) => {
      return {
        ...pre,
        sortBy: keyName,
        order: param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      };
    });
    setList([
      ..._.orderBy(
        list,
        [(item: any) => item[keyName]?.toLowerCase()],
        param.sortBy === keyName && param.order === 'asc' ? 'desc' : 'asc',
      ),
    ]);
    setTimeout(() => setLoader(false), 500);
  };

  const tableData = React.useMemo(() => {
    if (list?.length) {
      return list.map((row: any) => (
        <TableRow
          key={row.id}
          sx={{
            '&:last-child td, &:last-child th': {
              border: 0,
            },
          }}
          className={
            row?.status === 'Deactivated'
              ? 'deactive-row'
              : row?.status === 'Active'
                ? 'active-row'
                : row?.status === 'Invited'
                  ? 'inited-row'
                  : ''
          }>
          <TableCell>
            <Typography component={'div'} className='d-flex-a'>
              <Typography component={'div'}>
                {row?.imageId ? (
                  <img
                    className='d-avtaor d-flex-ja'
                    src={`${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.GetImage}?getUserImageId=${row?.imageId}`}
                  />
                ) : (
                  <Typography className='d-avtaor d-flex-ja' sx={{ textTransform: 'capitalize' }}>
                    {row?.firstName?.[0]}
                  </Typography>
                )}
              </Typography>
              <Typography
                component={'div'}
                className='company-name'>{`${row?.employeeName}`}</Typography>
            </Typography>
          </TableCell>
          <TableCell>{row?.jobTitle ? row?.jobTitle : '-'}</TableCell>
          <TableCell>{row?.workEmail ? row?.workEmail : '-'}</TableCell>
          <TableCell>{row?.roleName ? row?.roleName : '-'}</TableCell>
          <TableCell>
            <Typography
              className={
                row?.status === 'Active'
                  ? 'active-badge'
                  : row?.status === 'Deactivated'
                    ? 'decative-badge'
                    : row?.status === 'Invited'
                      ? 'ivited-badge'
                      : ''
              }>
              {row?.status === 'Active'
                ? 'active'
                : row?.status === 'Invite'
                  ? ''
                  : row?.status === 'Deactivated'
                    ? 'InActive'
                    : row?.status === 'Invited'
                      ? 'invited'
                      : ''}
            </Typography>
          </TableCell>
          <TableCell>{row?.lastActive && moment(row?.lastActive).format('MM/DD/YYYY')}</TableCell>
          <TableCell align='right'>
            <Button
              aria-describedby={id}
              onClick={(e) => handleClick(e, row)}
              sx={{ minWidth: '23px' }}>
              <MoreVertIcon />
            </Button>
          </TableCell>
        </TableRow>
      ));
    }
    return (
      <TableRow>
        <TableCell colSpan={7} align='center'>
          <Typography component={'div'} className='d-flex-ja norecord'>
            No Records Found
          </Typography>
        </TableCell>
      </TableRow>
    );
  }, [list]);

  return (
    <Grid container>
      <PageLoader loading={pageLoader} />
      <Grid item md={12} sm={12} xs={12}>
        <TableContainer component={Paper} className='companie-table'>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell sx={{ paddingLeft: '62px !important', width: '20%' }}>
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='th-title'>Name</Typography>
                    <Typography className='arrow-box' onClick={() => changeParam('employeeName')}>
                      <ArrowDropDownIcon
                        className='down'
                        style={{
                          fill:
                            param.sortBy === 'employeeName' && param.order === 'desc'
                              ? 'black'
                              : '',
                        }}
                      />
                      <ArrowDropUpIcon
                        className='up'
                        style={{
                          fill:
                            param.sortBy === 'employeeName' && param.order === 'asc' ? 'black' : '',
                        }}
                      />
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: '15%' }}>
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='th-title'>Job Title</Typography>
                    <Typography className='arrow-box' onClick={() => changeParam('jobTitle')}>
                      <ArrowDropDownIcon
                        className='down'
                        style={{
                          fill:
                            param.sortBy === 'jobTitle' && param.order === 'desc' ? 'black' : '',
                        }}
                      />
                      <ArrowDropUpIcon
                        className='up'
                        style={{
                          fill: param.sortBy === 'jobTitle' && param.order === 'asc' ? 'black' : '',
                        }}
                      />
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: '25%' }}>
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='th-title'>Email</Typography>
                    <Typography className='arrow-box' onClick={() => changeParam('workEmail')}>
                      <ArrowDropDownIcon
                        className='down'
                        style={{
                          fill:
                            param.sortBy === 'workEmail' && param.order === 'desc' ? 'black' : '',
                        }}
                      />
                      <ArrowDropUpIcon
                        className='up'
                        style={{
                          fill:
                            param.sortBy === 'workEmail' && param.order === 'asc' ? 'black' : '',
                        }}
                      />
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='th-title'>Role</Typography>
                    <Typography className='arrow-box' onClick={() => changeParam('roleName')}>
                      <ArrowDropDownIcon
                        className='down'
                        style={{
                          fill:
                            param.sortBy === 'roleName' && param.order === 'desc' ? 'black' : '',
                        }}
                      />
                      <ArrowDropUpIcon
                        className='up'
                        style={{
                          fill: param.sortBy === 'roleName' && param.order === 'asc' ? 'black' : '',
                        }}
                      />
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='th-title'>Status</Typography>
                    <Typography className='arrow-box' onClick={() => changeParam('status')}>
                      <ArrowDropDownIcon
                        className='down'
                        style={{
                          fill: param.sortBy === 'status' && param.order === 'desc' ? 'black' : '',
                        }}
                      />
                      <ArrowDropUpIcon
                        className='up'
                        style={{
                          fill: param.sortBy === 'status' && param.order === 'asc' ? 'black' : '',
                        }}
                      />
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: '10%' }}>
                  <Typography component={'div'} className='d-flex-a'>
                    <Typography className='th-title'>Last Active</Typography>
                    <Typography className='arrow-box' onClick={() => changeParam('lastActiveDate')}>
                      <ArrowDropDownIcon
                        className='down'
                        style={{
                          fill:
                            param.sortBy === 'lastActiveDate' && param.order === 'desc'
                              ? 'black'
                              : '',
                        }}
                      />
                      <ArrowDropUpIcon
                        className='up'
                        style={{
                          fill:
                            param.sortBy === 'lastActiveDate' && param.order === 'asc'
                              ? 'black'
                              : '',
                        }}
                      />
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: '5%' }} />
              </TableRow>
            </TableHead>
            <TableBody>{loader ? <Loader loading={loader} colsSpan={7} /> : tableData}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Popover
        className='edit-popup'
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Typography className='arrow-top' />
        {editList.status && (
          <Typography className='pop-item mb-6' onClick={() => handleOption('edit')}>
            Edit
          </Typography>
        )}
        {editList.status === 'Active' &&
          !userInfo?.isImpersonate &&
          Number(userInfo?.Id) !== editList?.id && (
            <Typography className='pop-item mb-6' onClick={() => impersonateFunction()}>
              Impersonate
            </Typography>
          )}
        {(editList.status === 'Invite' || editList.status === 'Deactivated') && (
          <Typography className='pop-item mb-6' onClick={() => handleOption('invite')}>
            Invite Client Employee
          </Typography>
        )}
        {(editList.status === 'Active' || editList.status === 'Invited') && (
          <Typography className='pop-item mb-6' onClick={() => handleOption('reinvite')}>
            Reinvite
          </Typography>
        )}
        {(editList.status === 'Active' || editList.status === 'Invited') && (
          <Typography className='pop-item mb-6' onClick={() => handleOption('deactive')}>
            Deactivate
          </Typography>
        )}
      </Popover>
      <EmployeeHandleOption
        visible={handleEmployeeOpen.visible}
        handleClose={hanleEmployeeClose}
        roleList={roleList}
        editObject={editList}
        status={handleEmployeeOpen.status}
        from={from}
      />
    </Grid>
  );
};

export default Employee;
