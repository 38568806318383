import React, { useEffect } from 'react';
import BackImg from 'assets/png/loginbg.jpg';
import Logo from 'assets/png/LogoDark.svg';
import { Grid, TextField, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { PublicRoutes } from 'routes/routeConstans';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { OtpForm } from 'utils/ValidationForm';
import { OtpSchema } from 'utils/ValidatorSchema';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { jwtDecode } from 'jwt-decode';
import { USER_LOGIN_SUCCESS } from 'store/types/UserTypes';
import { useDispatch } from 'react-redux';
import Storage from 'utils/Storage';

const VerifyOTP = ({ from }: any) => {
  const navigate: any = useNavigate();
  const location: any = useLocation();
  const dispatch: any = useDispatch();
  const [ip, setIP] = React.useState('');
  const [email, setEmail] = React.useState<any>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const { handleSubmit, control, formState, trigger } = useForm<OtpForm>({
    defaultValues: {
      otp: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(OtpSchema),
  });

  const goBackOption = () => {
    if (from === 'client') {
      navigate(PublicRoutes.CLIENTLOGIN);
    } else {
      navigate(PublicRoutes.ADMINLOGIN);
    }
  };

  const getUserIp = async () => {
    const res = await axios.get('https://api.ipify.org?format=json');
    setIP(res?.data?.ip);
  };

  useEffect(() => {
    if (location?.state?.email) {
      setEmail(location?.state?.email);
      getUserIp();
    } else {
      goBackOption();
    }
  }, [location.state]);

  const submitForm = (data: OtpForm) => {
    setLoading(true);
    let url = `${
      from === 'client' ? MASTER_ENDPOINT.VerifyClientOtp : MASTER_ENDPOINT.VerifyAdminOtp
    }?OTP=${data['otp']}&userId=${email}&userIp=${ip ? ip : null}`;
    if (from === 'client') {
      url += '&clientId=' + location?.state?.clientId;
    }
    getAllListData(url, true)
      .then((response: any) => {
        const userData: any = jwtDecode(String(response?.token));
        userData['token'] = response?.token;
        userData['userDetailsData'] = response?.data;
        userData['isAdmin'] = from === 'client' ? false : true;
        userData['isClient'] = from === 'client' ? true : false;
        userData['isImpersonate'] = false;
        userData['isFromSuperAdmin'] = '';
        userData['superUserId'] = '';
        const tokenData: any = {
          refreshToken: '',
          token: response?.token,
          userId: userData.id,
          isAdmin: from === 'client' ? false : true,
          isClient: from === 'client' ? true : false,
          isImpersonate: false,
          isFromSuperAdmin: '',
          superUserId: '',
        };
        Storage.setItem(Storage.KEYS.AUTH_TOKEN, JSON.stringify(tokenData));
        dispatch({
          payload: userData,
          type: USER_LOGIN_SUCCESS,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <Typography
          component={'div'}
          sx={{ backgroundImage: `url(${BackImg})` }}
          className='login-banner'>
          <Typography component={'div'} className='login-box'>
            <Typography className='logo-text d-flex-a'>
              <img src={Logo} alt='simplifiedHR' />
            </Typography>
            <form id='otp-form' onSubmit={handleSubmit(submitForm)}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>OTP*</Typography>
                <Controller
                  control={control}
                  defaultValue=''
                  name='otp'
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <TextField
                        className='custom-input'
                        id='standard-basic'
                        placeholder='Enter OTP'
                        variant='standard'
                        onChange={(e) => {
                          const vals = e.target.value
                            .replace(/\s/g, '')
                            .replace(/[^0-9 ]/g, '')
                            .trimStart();
                          onChange(vals);
                          trigger('otp');
                        }}
                        // type='number'
                        autoFocus={true}
                        inputProps={{ maxLength: 6 }}
                        value={value}
                        name={name}
                        error={formState.errors.otp?.message ? true : false}
                        helperText={formState.errors.otp?.message}
                      />
                    );
                  }}
                />
              </Typography>
              <LoadingButton
                className='submit-btn'
                type='submit'
                form='otp-form'
                loading={loading}
                loadingPosition='center'>
                Continue
              </LoadingButton>
              <Typography component={'div'} textAlign={'center'} sx={{ marginTop: '20px' }}>
                <Typography className='otp-text'>
                  As an extra security measure, we emailed you a one-time password (OTP). Please
                  check your email (including the spam/junk folders) for the OTP and enter it here{' '}
                </Typography>
                <Typography className='link-text'>
                  Please note that it may take a couple of minutes to receive the email
                </Typography>
              </Typography>
              <Typography className='border-box' />
            </form>
            <Typography component={'p'} textAlign={'center'}>
              <Typography
                component={'span'}
                className='back-to-login'
                onClick={() => goBackOption()}>
                Cancel, Back to Login
              </Typography>
            </Typography>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default VerifyOTP;
