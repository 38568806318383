import {
  Box,
  Grid,
  Modal,
  TextField,
  Typography,
  IconButton,
  Checkbox,
  styled,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ChangePasswordForm } from 'utils/ValidationForm';
import LoadingButton from '@mui/lab/LoadingButton';
import { ChangePasswordSchema } from 'utils/ValidatorSchema';
import { MASTER_ENDPOINT } from 'utils/Constant';
import { useAppSelector } from 'store/hooks';
import CryptoJS from 'crypto-js';
import { addNewData } from 'services/CommonServices';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  minHeight: '407px',
  width: '414px',
  borderRadius: ' 8px',
  boxShadow: ' 0 5px 14px 0 rgba(0,0,0,0.22)',
  padding: '28px 30px',
};

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 5,
  width: 16,
  height: 16,
  backgroundColor: theme.palette.mode === 'dark' ? '#EFEFEF' : '#EFEFEF',
  '.Mui-focusVisible &': {
    outline: '1px auto #D9D9D9',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#EFEFEF' : '#EFEFEF',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#C3D34F',
  // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#C3D34F',
  },
});

const Settings = () => {
  const [passwordModal, setPasswordModal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const { userInfo: userDetail }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });

  const ChangeModalOpen = () => {
    setShowPassword1(false);
    setShowPassword2(false);
    setShowPassword(false);
    setPasswordModal(true);
  };
  const ChangeModalClose = () => {
    setPasswordModal(false);
    reset();
  };

  const {
    handleSubmit,
    control,
    formState,
    // setValue,
    reset,
    watch,
    trigger,
  } = useForm<ChangePasswordForm>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(ChangePasswordSchema),
  });

  const submitForm = (data: any) => {
    setLoading(true);
    const secertKey = CryptoJS.enc.Utf8.parse('6ENY03$PR@CT*CE$');
    const iv = CryptoJS.enc.Utf8.parse('6ENY03$PR@CT*CE$');
    const encryptedPassword = CryptoJS.AES.encrypt(`${data.newPassword}`, secertKey, {
      keySize: 128 / 8,
      mode: CryptoJS.mode.CBC,
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
    const encryptedPassword2 = CryptoJS.AES.encrypt(`${data.oldPassword}`, secertKey, {
      keySize: 128 / 8,
      mode: CryptoJS.mode.CBC,
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();

    addNewData(
      {},
      `${MASTER_ENDPOINT.ChangePassword}?toEmailAddress=${userDetail?.UserId}&clientId=${userDetail?.ClientId}&oldPassword=${encryptedPassword2}&newPassword=${encryptedPassword}`,
      {},
      true,
    )
      .then(() => {
        setLoading(false);
        ChangeModalClose();
      })
      .finally(() => {
        setLoading(false);
        ChangeModalClose();
      });
  };
  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='set-parent d-flex-ja'>
          <Typography component={'div'} className='set-child'>
            <Typography className='p-title sett-box d-flex-a px-24'>Manage Settings</Typography>
            <Typography component={'div'} className='px-24'>
              {userDetail?.isImpersonate ? (
                <Typography />
              ) : (
                <Typography
                  component={'div'}
                  sx={{ cursor: 'pointer' }}
                  className='sett-box d-flex-a flex-sb'
                  onClick={ChangeModalOpen}>
                  <Typography>Change Password</Typography>
                  <ArrowForwardIosIcon />
                </Typography>
              )}
              <Typography component={'div'} className='sett-box d-flex-a flex-sb'>
                <Typography>Terms & Conditions</Typography>
                <ArrowForwardIosIcon />
              </Typography>
              <Typography component={'div'} className='sett-box d-flex-a flex-sb'>
                <Typography>Privacy Policy</Typography>
                <ArrowForwardIosIcon />
              </Typography>
            </Typography>
          </Typography>
        </Typography>
      </Grid>
      <Modal
        open={passwordModal}
        // onClose={ChangeModalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box component='div' sx={style}>
          <Typography className='d-flex-a flex-sb'>
            <Typography className='modal-title'> Change Pasworrd</Typography>
            <CloseIcon className='closesvg' onClick={ChangeModalClose} />
          </Typography>
          <Typography className='modal-dis'>
            If you want to change your password, enter your current password then input a new
            password.
          </Typography>
          <form id='private-form' className='private-form' onSubmit={handleSubmit(submitForm)}>
            <Typography component={'div'} className='custom-field'>
              <Typography component={'p'}>Current Password*</Typography>
              <Controller
                control={control}
                defaultValue=''
                name='oldPassword'
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <TextField
                      className='custom-input'
                      id='standard-basic'
                      placeholder='Current Password'
                      variant='standard'
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onChange={
                        // onChange
                        (e) => {
                          onChange(e.target.value.replace(/\s/g, ''));
                        }
                      }
                      sx={{
                        '& button': {
                          marginRight: '5px',
                        },
                      }}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                            edge='end'>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        ),
                      }}
                      value={value}
                      name={name}
                      error={formState.errors.oldPassword?.message ? true : false}
                      helperText={formState.errors.oldPassword?.message}
                    />
                  );
                }}
              />
            </Typography>
            <Typography component={'div'} className='custom-field'>
              <Typography component={'p'}> Enter New Password*</Typography>
              <Controller
                control={control}
                defaultValue=''
                name='newPassword'
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <TextField
                      className='custom-input'
                      id='standard-basic'
                      placeholder='Enter New Password'
                      variant='standard'
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onChange={
                        // onChange
                        (e) => {
                          onChange(e.target.value.replace(/\s/g, ''));
                          trigger('newPassword');
                        }
                      }
                      sx={{
                        '& button': {
                          marginRight: '5px',
                        },
                      }}
                      type={showPassword1 ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword1}
                            // onMouseDown={handleMouseDownPassword}
                            edge='end'>
                            {showPassword1 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        ),
                      }}
                      value={value}
                      name={name}
                      error={formState.errors.newPassword?.message ? true : false}
                      helperText={formState.errors.newPassword?.message}
                    />
                  );
                }}
              />
            </Typography>
            <Typography component={'div'} className='custom-field'>
              <Typography component={'p'}> Confirm New Password*</Typography>
              <Controller
                control={control}
                defaultValue=''
                name='confirmPassword'
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <TextField
                      className='custom-input'
                      id='standard-basic'
                      placeholder='Confirm New Password'
                      variant='standard'
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onChange={
                        // onChange
                        (e) => {
                          onChange(e.target.value.replace(/\s/g, ''));
                          trigger('confirmPassword');
                        }
                      }
                      sx={{
                        '& button': {
                          marginRight: '5px',
                        },
                      }}
                      type={showPassword2 ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword2}
                            // onMouseDown={handleMouseDownPassword}
                            edge='end'>
                            {showPassword2 ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        ),
                      }}
                      value={value}
                      name={name}
                      error={formState.errors.confirmPassword?.message ? true : false}
                      helperText={formState.errors.confirmPassword?.message}
                    />
                  );
                }}
              />
            </Typography>
            <Typography sx={{ marginTop: '10px' }}>
              <Typography
                component={'div'}
                className='custom-field d-flex-a'
                sx={{ marginBottom: '0px !important' }}>
                <Checkbox
                  className='cinput'
                  // disabled
                  checked={watch('newPassword').length >= 8}
                  checkedIcon={<CheckedIcon />}
                  icon={<CheckIcon />}
                />
                <Typography>Length should be minimum 8 characters</Typography>
              </Typography>
              <Typography
                component={'div'}
                className='custom-field d-flex-a'
                sx={{ marginBottom: '0px !important' }}>
                <Checkbox
                  className='cinput'
                  // disabled
                  checked={/[A-Z]+/.test(watch('newPassword'))}
                  checkedIcon={<CheckedIcon />}
                  icon={<CheckIcon />}
                />
                <Typography>Use minimum one upper case letter</Typography>
              </Typography>
              <Typography
                component={'div'}
                className='custom-field d-flex-a'
                sx={{ marginBottom: '0px !important' }}>
                <Checkbox
                  className='cinput'
                  // disabled
                  checked={/[*@!$#%&()^~|{}]+/.test(watch('newPassword'))}
                  checkedIcon={<CheckedIcon />}
                  icon={<CheckIcon />}
                />
                <Typography>Use minimum one special character</Typography>
              </Typography>
              <Typography
                component={'div'}
                className='custom-field d-flex-a'
                sx={{ marginBottom: '0px !important' }}>
                <Checkbox
                  className='cinput'
                  // disabled
                  checked={/[0-9]+/.test(watch('newPassword'))}
                  checkedIcon={<CheckedIcon />}
                  icon={<CheckIcon />}
                />
                <Typography className='cinput'>Use minimum one number (0-9)</Typography>
              </Typography>
            </Typography>
            <LoadingButton
              className='submit-btn'
              type='submit'
              form='private-form'
              loading={loading}
              loadingPosition='center'>
              Save New Password
            </LoadingButton>
          </form>
        </Box>
      </Modal>
    </Grid>
  );
};

export default Settings;
