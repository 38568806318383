import React from 'react';
import Employee from 'pages/Common/Employee';
import { useAppSelector } from 'store/hooks';

const AccesstManagement = () => {
  const userDetail: any = useAppSelector((store: any) => {
    return store.userLogin.userInfo;
  });
  return <Employee clientId={userDetail.ClientId} from={'client'} />;
};

export default AccesstManagement;
